<div >
    <div class="section-body mt-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body " >
                                <div class="tabview-contents  center mt-25">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class= "form-label ">Select Country</label>
                                            <select class="form-control custom-select" (change)="onChangeCountry($event.target.value)" [(ngModel)]="selectedCountry"
                                            [ngClass]="{'is-invalid':selectedCountry == 'undefined' || selectedCountry == '' || selectedCountry == null}">
                                                <option value="">Select</option> 
                                                <option *ngFor="let country of distinctCountries" [value]="country">{{country}}</option>
                                              </select>
                                        </div>
                                        <div class="col-md-4">
                                            <label class= "form-label ">Select Group</label>
                                            <select class="form-control custom-select" (change)="onChangeGroup($event.target.value)" [(ngModel)]="group"
                                            [ngClass]="{'is-invalid':group == 'undefined' || group == '' || group == null}">
                                                <option value="">Select</option>
                                                <option *ngFor="let group of distinctGroups" [value]="group">{{group}}</option>
                                              </select>
                                        </div>
                                        <div class="col-md-4">
                                            <label class= "form-label">Select Company</label>
                                            <select class="form-control custom-select" (change)="companySelection($event.target.value)">
                                                <option value="">Select</option>
                                                <option *ngFor="let company of distinctCompanies" [value]="company">{{company}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="row mt-25">
                                        <div class="col-md-12">
                                            <div class="float-right">
                                                <button _ngcontent-wxs-c616="" type="button" tooltip="Button" (click)="redictionTo()"
                                                [disabled]="selectedCountry == 'undefined' || selectedCountry == '' || selectedCountry == null ||
                                                group == 'undefined' || group == '' || group == null"
                                                [ngClass]="{'disabled-btn-bg-green':selectedCountry == 'undefined' || selectedCountry == '' || selectedCountry == null ||group == 'undefined' || group == '' || group == null,'btn-bg-green':selectedCountry != 'undefined' && selectedCountry != '' && selectedCountry != null ||group != 'undefined' && group != '' && group != null}"
                                                class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2"
                                                tabindex="0" ng-reflect-tooltip="Button"  
                                                ><b
                                                    _ngcontent-wxs-c616=""><i class="fa fa-arrow-right" aria-hidden="true"></i></b> Go </button>
                                          
                                            </div> 
                                        </div>
                                    </div>
                                </div>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>