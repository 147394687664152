import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

public apiUrl = environment.baseUrl8091;
public apiUrl1 = environment.baseUrl8083;
public apiUrl2 = environment.baseUrl8088;
public apiUrl3 = environment.baseUrl8082;
public apiUrl4 = environment.baseUrl8084;
public apiUrl8081 = environment.baseUrl8081;
  // authservice: any;

constructor(private http : HttpClient ,private authService: AuthService) { }

getEmpMasterDetails(id) {
  let token = this.authService.getJwtToken()
  const headers = new HttpHeaders()
   .set('X-Authorization', token);
  return this.http.get<any>(this.apiUrl3 + 'employee-fin-details/' + id ,{ headers: headers }  );
  }
// .................................Standard que ans api.....................................................................

public getAll()
{
  return this.http.get<any>(this.apiUrl + 'QuestionAnswer' );
}
public addQuery(data)
{
  return this.http.post<any>(this.apiUrl  +'QuestionAnswer' ,data);
}
public updateQuery(data)
{
 return this.http.put<any>(this.apiUrl + 'QuestionAnswer' ,data);
}
public getModuleName()//not in use
{
  return this.http.get<any>(this.apiUrl1 + 'application-module/');
}
public getStandardKeywords()
{
  return this.http.get<any>(this.apiUrl + 'StandardKeyword/Global');
}
public getKeywordsById(id)
{
  return this.http.get<any>(this.apiUrl + 'QuestionAnswer/getById/' + id);
}
public getKeywordsByTwoId(queAnsMasterId,empId)//not in use
{
  return this.http.get<any>(this.apiUrl + 'QuestionAnswer/getTemplateDataById/'+queAnsMasterId+'/'+empId);
}
public getModuleNameForAdmin()
{
  return this.http.get<any>(this.apiUrl1 + 'ModuleMaster/GetAllGlobal');
}
public getModuleNameTenent(){
  return this.http.get<any>(this.apiUrl1 + 'ModuleMaster/GetAllTenant');
}
public UniqueKeywordTempletgetAll(){

  return this.http.get<any>(this.apiUrl4 + 'UniqueKeywordTemplet/getAll');
}
// .................................Query Type master api.....................................................................
public getAllSummaryData()
{
  return this.http.get<any>(this.apiUrl + 'QueryMaster');
}
public addQueryType(data)
{
  return this.http.post<any>(this.apiUrl  +'QueryMaster/AddnewData' ,data);
}
public updateQueryType(data)
{
 return this.http.put<any>(this.apiUrl + 'QueryMaster' ,data);
}
public getAllWorkflowMasters()
{
  return this.http.get<any>(this.apiUrl2 + 'workflowmaster-header/getAllWorkflowMasters');
}
public getAlldataById(id)
{
  return this.http.get<any>(this.apiUrl +'QueryMaster/getById/' + id);
}
public updateTenantQueryType(data)
{
  return this.http.put<any>(this.apiUrl + 'QueryMaster/UpdateDataTenant',data)
}

getTenantDataQueryMasterId(id):Observable<any>{
  return this.http.get<any>(this.apiUrl + 'QueryMaster/getByIdTenant/'+id)
}
// ............................ Not use Yet ..................................................................
// public getSubqueryPresent(id)
// {
//   return this.http.get<any>(this.apiUrl + 'getSubQueryPresent/getTenant/' +id)
// }
// public getAllQueryTypeMasterTenant()
// {
//   return this.http.get<any>(this.apiUrl + 'QueryMaster/getAllQueryTypeMasterTenant')
// }
// public getQueAns(id)
// {
//   return this.http.get<any>(this.apiUrl + 'QueryMaster/getQueAns/' +id)
// }
// public getSubQueAns(id)
// {
//   return this.http.get<any>(this.apiUrl + 'QueryMaster/getSubQueAns/' +id)
// }
// .........................admin-query-generation api..........................................................

public getAllQueryList()
{
  return this.http.get<any>(this.apiUrl + 'QueryGeneration');
}
public querySubQueryTypeQA(id)
{
  return this.http.get<any>(this.apiUrl + 'QueryGeneration/QuerySubQueryTypeQA/' +id);
}
public getById(id)
{
  return this.http.get<any>(this.apiUrl + 'QueryGeneration/' +id);
}
public addQueryGeneration(data)
{
  return this.http.post<any>(this.apiUrl + 'QueryGeneration',data);
}
public updateQueryGeneration(data)
{
  return this.http.put<any>(this.apiUrl + 'QueryGeneration',data);
}
public updateQueryGenerationNew(data) //old used the put
{
  return this.http.post<any>(this.apiUrl + 'QueryIteration',data)
}
public getDeleteById(id)
{
  return this.http.delete<any>(this.apiUrl + 'QueryGeneration/' +id);
}
// public getEmpMasterDetails(id)
// {
//   return this.http.get<any>(this.apiUrl3 + 'employee-fin-details/' +id );
// }
public addQuerywithDocs(data)
{
  return this.http.post<any>(this.apiUrl + 'QueryGeneration',data);
}
public updateQuerywithDoc(data)
{
  return this.http.put<any>(this.apiUrl + 'QueryGeneration',data);
}
// .........................query-Communication api..........................................................
public addQueryIteration(data)
{
  return this.http.post<any>(this.apiUrl + 'QueryIteration',data);
}
public updateQueryIteration(data)
{
  return this.http.put<any>(this.apiUrl + 'QueryIteration',data);
}
public getIterationdetailsbyQueryID(id) // right now not used
{
  return this.http.get<any>(this.apiUrl + 'QueryIteration/' +id);
}
public getIterationDetailsData() // replcement of this api 'getIterationdetailsbyQueryID' use the above
{
  return this.http.get<any>(this.apiUrl + 'QueryGeneration/replyUser')
}
  // public documentInfoById(id) //not used
  // {
  // return this.http.get<any>(this.apiUrl + 'DocumentInfo/' +id);
  // }
  public getQueAnstemplistById(id)
  {
  return this.http.get<any>(this.apiUrl + 'QueryIteration/QA/' +id);
  }
  public getRootCasuelist()
  {
  return this.http.get<any>(this.apiUrl + 'QueryIteration/RootCause');
  }
  public getReplayDataById(id)
  {
  return this.http.get<any>(this.apiUrl + 'QueryIteration/reply/' +id);
  }
  public addressedTodropdown(data)
  {
  return this.http.post<any>(this.apiUrl2 + 'workflowMaster-report/approverDetails' , data);
  }
  public queryClosed(data)
  {
  return this.http.post<any>(this.apiUrl + 'QueryGeneration/closeQuery', data);
  }
   // ........................employee query List api.......................................
  public getAllEmployeeQuery()
  {
  return this.http.get<any>(this.apiUrl + 'QueryGeneration/getAllEmployeeQuery')
  }

  /*** Get All employee query list using pagination - 22/02/24 Rucha *****/
  public getAllEmployeeQueryPagination(data)
  {
  return this.http.post<any>(this.apiUrl + 'QueryGeneration/paging', data)
  }
  /*** Get Total employee query list for pagination - 22/02/24 Rucha *****/
  getTotalCountForEmployeeQuery(){
    return this.http.get<any>(this.apiUrl + 'QueryGeneration/totalCount') 
  }

  /** Get Status For Employee Query - 28/02/24 Aakansha*/
  getstatusForEmployeeQuery(){
    return this.http.get<any>(this.apiUrl + 'QueryGeneration/employeeStatusCount') 
  }
  public addNewEmpQuery(data)
  {
  return this.http.post<any>(this.apiUrl + 'QueryGeneration/employeeAdd' ,data)
  }
  public updateNewEmpQuery(data)
  {
  return this.http.put<any>(this.apiUrl + 'QueryGeneration/employeeUpdate' ,data)
  }
  public postApproverDetails(data)
  {
  return this.http.post<any>(this.apiUrl2 + 'workflowMaster-report/approverDetails' , data);
  }
  public getByIterarionId(iterationId)
  {
    return this.http.get<any>(this.apiUrl + 'QueryIteration/Iteration/' + iterationId)
  }
  public getTenantSummary(){
    return this.http.get<any>(this.apiUrl + 'QueryMaster/getTenantSummary')
  }
  public getModuleNameAsPerQueryType(){
    return this.http.get<any>(this.apiUrl + 'QueryMaster/getModuleName')
  }
  // .........api for sending mail of query raised........................
  sendMailForRaisedQuery(data){
    return this.http.post<any>(this.apiUrl8081 + 'emailBodyAndEvent/QuerySubmission' , data)
  }
  sendMailForRepliedQuery(data){
    return this.http.post<any>(this.apiUrl8081 + 'emailBodyAndEvent/QueryReplay' , data)
  }


  /**************************** Admin Dashboard New Api ************************************* */
   /*** Get All Admin query list using pagination - 28/02/24 Aakansha *****/
   public getAllAdminQueryPagination(data)
   {
   return this.http.post<any>(this.apiUrl + 'QueryGeneration/adminepaging', data)
   }
   /*** Get Total Admin query list for pagination - 28/02/24 Aakansha *****/
   getTotalCountForAdminQuery(){
     return this.http.get<any>(this.apiUrl + 'QueryGeneration/adminTotalCount') 
   }

    /*** Get All Admin Status listt for pagination - 29/02/24 Aakansha *****/
   getstatusForAdminQuery()
   {
    return this.http.get<any>(this.apiUrl + 'QueryGeneration/adminStatusCount') 
   }
  //  .....................query dashboard API....................................... 
   getAllCompanyQuery(data){
    return this.http.post<any>(this.apiUrl + 'QueryGeneration/adminepagingpay', data) 
   }
   getTotalCountForAdminQueryAllCompany(){
    return this.http.get<any>(this.apiUrl + 'QueryGeneration/adminTotalCountPay') 
  }  
   getstatusForAdminQueryAllCompany()
   {
    return this.http.get<any>(this.apiUrl + 'QueryGeneration/adminStatusCountPay') 
   }
}


