import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { delay, map } from 'rxjs/operators';
import { ExcelserviceService } from 'src/app/core/services/excelservice.service';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { LmsFormInputService } from 'src/app/modules/lms/lms-forms-input/lms-form-input.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
export interface User1 {
  empcode,
  empname,
  payrollarea,
  lmsarea,
  companyname,
  emptype,
  establishment,
  department,
  grade,
  designation,
}
@Component({
  selector: 'app-lms-forms-input',
  templateUrl: './lms-forms-input.component.html',
  styleUrls: ['./lms-forms-input.component.scss']
})
export class LmsFormsInputComponent implements OnInit {
  users1: User1[];
  public modalRef: BsModalRef;
  allEmpList: Array<any> = [];
 
  selectedEmpList: Array<any> = [];
  getData:any;
  excelData: any[];
  header: any[];
isLoader:boolean=false;

  displayedColumns: any[] = [];
  employeeList: any[] = [];
  selectedEmpLength: any;
  curreEmpDet: any=[];
  slectedLmsAreaList: Array<any> = [];
  lmsInputForm:FormGroup;
  lsmAreaCode:string ='';
  constructor(private modalService: BsModalService,private lmsInputFormService:LmsFormInputService,private authService: AuthService,private excelservice: ExcelserviceService, private alertService: AlertServiceService,
private formBulider:FormBuilder
  ) {
    sessionStorage.removeItem('lmsEmp')
    this.lmsInputForm = this.formBulider.group({
      lmsInputFormArray:new FormArray([])
    })
   }


  ngOnInit(): void {
    this.getData = JSON.parse(this.authService.deValue(sessionStorage.getItem('globalCompanyList')))
    
    this.getAllEmployeeList();
    
    this.users1 = [
      { empcode: '3203', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3204', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3205', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3206', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3207', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3208', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3209', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3210', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3211', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3212', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
    ];
  }
  employeeListCopyPaste(event, pasteEmployee: TemplateRef<any>) {
    if (event.checked) {
      this.modalRef = this.modalService.show(
        pasteEmployee,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    }
  }

  getAllEmployeeList() {   
    let data
    data = this.authService.getprivileges();
    let id = data.UserDetails.employeeMasterId;  
    this.isLoader=true
    const obj = {
      "globalCompanyMasterIdList":this.getData
        } 
      this.lmsInputFormService.newMainSummary(obj).pipe(delay(50)).subscribe((res: any) => {
      this.allEmpList = res.data.results[0];
      this.slectedLmsAreaList = res.data.results[0];
      this.allEmpList.forEach(el=>{
        el['isChecked'] = false;
      })
      this.isLoader=false
      this.showLMSInputForm();
    })
  
  }
//   selectSingleEmp(check,id,i){

// let ind1 =this.allEmpList.findIndex(x=>x.employeeMasterId == id)
// if(ind1 != -1){
//     if(check == true){
//       this.allEmpList[ind1].isChecked = true
//     this.selectedEmpList.push({'empId' : id,'empCode':this.allEmpList[ind1].employeeCode,'empName':this.allEmpList[ind1].fullName,'lhgId':this.allEmpList[ind1].lmsLeaveSchemaId})
//     sessionStorage.setItem('lmsEmp',this.authService.enValue(JSON.stringify(this.selectedEmpList)))
//     }else{
//       this.allEmpList[ind1].isChecked = false
//       let ind = this.selectedEmpList.findIndex(e=>e.empId == id)
//       if(ind != -1){
//         this.selectedEmpList.splice(ind,1)
//         // sessionStorage.setItem('lmsEmp',JSON.stringify(this.selectedEmpList))
//         sessionStorage.setItem('lmsEmp',this.authService.enValue(JSON.stringify(this.selectedEmpList)))
//       }
//     }
//   }
//   }


   getManualAppEmp(){
  }

  getArManualAppEmp(){
    // this.lmsInputFormService.arManualAppEmpList.next(this.selectedEmpList)
  }
  getLeaveAdjustment(){
    // this.lmsInputFormService.setEmp(this.selectedEmpList)
    // this.lmsInputFormService.leaveAdjustmentList.next(this.selectedEmpList)
  }
  getEncashment(){
    // this.lmsInputFormService.selectedEmpEncashmentList.next(this.selectedEmpList)
  }
  getRoaster(){
    this.lmsInputFormService.selectedRoasterList.next(this.selectedEmpList)
  }
  exportToExcel(){
    this.excelData = [];
    this.header = []
    this.header =["Emp. Code", "Emp. Name","Payroll Area","Company Name","Emp. Type", "Establishment", "Department","Grade","Designation"];
    this.excelData=[];
    let rowIndex=1;
    if(this.allEmpList.length>0){
      this.allEmpList.forEach(element => {
      let obj = {
        // "Sr. No.":rowIndex++,
        // "Application No.":element?.applicationNo,
        // "Application Date":this.datepipe.transform(element.applicationDate,'dd-MMM-yyyy'),
        "Emp. Code":element.employeeCode,
        "Emp. Name":element.fullName,
        "Payroll Area":element.payrollAreaCode,
      //  "LMS Area":element.,
       "Company Name":element.companyName,
       "Emp. Type":element.employeeType,
       "Establishment":element.establishment,
        "Department":element.department,
        "Grade":element.grade,
        "Designation":element.designation1,
      }
      this.excelData.push(obj)
    });
  }
    this.excelservice.exportAsExcelFile(this.excelData, 'Input Form','Input Form',this.header);
  }


  employeeListPasteData(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let row_data = pastedText.split('\n');
    this.displayedColumns = ["EmpCode"]
    this.employeeList = [];
    for (let i = 0; i < row_data.length; i++) {
      let data = row_data[i].replace('\r', '');
      this.employeeList.push(data)
    }
    let lastIndex = this.employeeList.length - 1
    this.employeeList.splice(lastIndex, 1)
    this.selectedEmpList = []
    this.allEmpList.forEach(user => {
      this.employeeList.forEach(element => {
        if (user.employeeCode == element) {
          user.isChecked = true;
          this.selectedEmpList.push({'empId' : user.employeeMasterId,
          'empCode':user.employeeCode,
          'empName':user.fullName,
          'lhgId':user.lmsLeaveSchemaId})
          this.selectedEmpLength = this.selectedEmpLength + 1
          console.log(this.selectedEmpList)
          // sessionStorage.setItem('lmsEmp', JSON.stringify(this.selectedEmpList))
          sessionStorage.setItem('lmsEmp',this.authService.enValue(JSON.stringify(this.selectedEmpList)))
        }
      });
    })
    if(this.selectedEmpList.length > 0){
    this.selectedEmpList.forEach(ele=>{
    let ind = this.lmsInputArray.value.findIndex(x=>x.employeeMasterId == ele.empId)
    if(ind !== -1){
      this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(true)
    }
  })
}
  }

  get f(){
    return this.lmsInputForm.controls;
  }
  get lmsInputArray(){
    return this.f.lmsInputFormArray as FormArray
  }

  showLMSInputForm(){
    this.allEmpList.forEach(ele=>{
      let data =  this.lmsInputArray.value.find(x=>x.employeeMasterId == ele.employeeMasterId)
      if(!data){
    this.lmsInputArray.insert(this.lmsInputArray.length,this.formBulider.group({
      isChecked:[ele.isChecked],
      employeeMasterId:[ele.employeeMasterId],
      employeeCode:[ele.employeeCode],
      fullName:[ele.fullName],
      payrollAreaCode:[ele.payrollAreaCode],
      lmsAreaCode:[ele.lmsAreaCode],
      companyName:[ele.companyName],
      employeeType:[ele.employeeType],
      establishment:[ele.establishment],
      department:[ele.department],
      grade:[ele.grade],
      designation1:[ele.designation1]
    }))
  }
  })
  }

  // selectSingleEmp(check,id, i, lmsAreaCode,data) {
  //   const obj = {
  //     'empId': id, 
  //     'empCode': data.value.employeeCode, 
  //     'empName': data.value.fullName, 
  //     'lhgId': data.value.lmsLeaveSchemaId
  //   }
  //   // let ind = this.lmsInputArray.value.findIndex(x=>x.employeeMasterId == id)
  //   let ind = this.lmsInputArray.value.findIndex(x=>x.employeeCode == data.value.employeeCode)
  //   if(this.selectedEmpList.length === 0){
  //     this.lsmAreaCode = lmsAreaCode;
  //     let data1 = this.selectedEmpList.find(x=>x.employeeCode == data.value.employeeCode)
  //     if(!data1){
  //     this.selectedEmpList.push(obj);
  //     }
      
  //     if(ind != -1){
  //     this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(true)
  //     }
  //     sessionStorage.setItem('lmsEmp', this.authService.enValue(JSON.stringify(this.selectedEmpList)))
  //   }
  //   if(this.selectedEmpList.length !== 0 && lmsAreaCode === this.lsmAreaCode){
      
  //     // const index = this.selectedEmpList.findIndex(ele=>ele.employeeMasterId === id);
  //     const index = this.selectedEmpList.findIndex(ele=>ele.employeeCode == data.value.employeeCode);
  //     if(index !== -1){
  //       // this.lmsInputForm.get('lmsInputFormArray')['controls'][i]?.get('isChecked').setValue(false)
  //       if(ind != -1){
  //       this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(false)
  //       }
  //       this.selectedEmpList.splice(index,1);
  //     }else{
  //       // let data1 = this.selectedEmpList.find(x=>x.empId == id)
  //       let data1 = this.selectedEmpList.find(x=>x.employeeCode == data.value.employeeCode)
  //       if(!data1){
  //       this.selectedEmpList.push(obj);
  //       }
  //       // this.lmsInputForm.get('lmsInputFormArray')['controls'][i]?.get('isChecked').setValue(true)
  //       if(ind != -1){
  //         this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(true)
  //         }
  //       sessionStorage.setItem('lmsEmp', this.authService.enValue(JSON.stringify(this.selectedEmpList)))
  //     }
  //   }else{
  //     // this.lmsInputForm.get('lmsInputFormArray')['controls'][i]?.get('isChecked').setValue(false)
  //     if(ind != -1){
  //       this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(false)
  //       }
  //     return this.alertService.sweetalertWarning("LMS Area is different");
  //   }
  // }

  selectSingleEmp(check,id, i, lmsAreaCode,data) {
  
    let ind = this.lmsInputArray.value.findIndex(x=>x.employeeCode == data.value.employeeCode)
    if(this.selectedEmpList.length === 0 && check == true && ind != -1){
      this.lsmAreaCode = lmsAreaCode;
      let data1 = this.selectedEmpList.find(x=>x.empId == id)
      if(!data1){
        const obj = {
          'empId': id, 
          'empCode': data.value.employeeCode, 
          'empName': data.value.fullName, 
          'lhgId': data.value.lmsLeaveSchemaId
        }
      this.selectedEmpList.push(obj);
      }
      
      // if(ind != -1){
      // this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(true)
      // }
      sessionStorage.setItem('lmsEmp', this.authService.enValue(JSON.stringify(this.selectedEmpList)))
    }
    if(this.selectedEmpList.length !== 0 && lmsAreaCode === this.lsmAreaCode){
      
      const index = this.selectedEmpList.findIndex(ele=>ele.empId == id);
      if(index !== -1 && check == false){
        if(ind != -1){
        this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(false)
        }
        this.selectedEmpList.splice(index,1);
      }else{
        let data1 = this.selectedEmpList.find(x=>x.empId == id)
        if(!data1){
          const obj = {
            'empId': id, 
            'empCode': data.value.employeeCode, 
            'empName': data.value.fullName, 
            'lhgId': data.value.lmsLeaveSchemaId
          }
        this.selectedEmpList.push(obj);
        }
        if(ind != -1){
          // this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(true)
          }
        sessionStorage.setItem('lmsEmp', this.authService.enValue(JSON.stringify(this.selectedEmpList)))
      }
    }else{
      if(ind != -1){
        this.lmsInputForm.get('lmsInputFormArray')['controls'][ind]?.get('isChecked').setValue(false)
        }
      return this.alertService.sweetalertWarning("LMS Area is different");
    }
  }
}
