import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SearchEmployeeService {

  constructor(private httpClient:HttpClient) { }

  getEmp(value:Object):Observable<any>{
    let formData=new FormData();
    if(value['employeeMasterId']!=='' && value['employeeMasterId']!==null && value['employeeMasterId']!==undefined){
      formData.append('requestDTO',`{"employeeMasterId":${value['employeeMasterId']}}`);
    }else{
      formData.append('requestDTO',JSON.stringify(value));
    }
    return this.httpClient.post<any>(`${environment.baseUrl8082}EmployeeSearch/searchEmployees/`,formData);
  }

  
  getPopupDD(id:number):Observable<any>{
    return this.httpClient.get<any>(`${environment.baseUrl8083}job-master-mapping/company/${id}`);
  }

  getEstDD():Observable<any>{
    return this.httpClient.get<any>(`${environment.baseUrl8083}establishment-master/details/`);
  }

  getEmpmasterid(value:any):Observable<any>{
    let formData=new FormData();
    formData.append('requestDTO','{ "employeeMasterId": '+value+'}');
    return this.httpClient.post<any>(`${environment.baseUrl8082}EmployeeSearch/searchEmployees/`,formData);
  }
}
