import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class KeyParaService {

  constructor(private httpClient:HttpClient) { }

  public UniqueKeywordTempletgetAll():Observable<any>{
    return this.httpClient.get<any>(`${environment.baseUrl8084}UniqueKeywordTemplet/getAll`);
  }

  public KeyParaMasterDropDownList():Observable<any>{
    return this.httpClient.get<any>(`${environment.baseUrl8084}KeyParaMaster/KeyParaMasterDropDown`);
  }

  public getAllModuleSubModule():Observable<any>{
    // return this.httpClient.get<any>(`${environment.baseUrl8084}SubModuleTransactionDetails/getTableData') // old
    return this.httpClient.get<any>(`${environment.baseUrl8084}SubModuleTransactionGroupDetails/getSubmoduleTableDataGroup`);

  }
}
