<div id="page_top" class="section-body top_dark">
    <div class="container-fluid">
        <div class="page-header padding-3">
            <div class="left">
                <div class="dropdown top-6">
                    <a href="javascript:void(0)" (click)="toggleMenu()" class="nav-link icon menu_toggle color-black"><i
                            class="fa-solid fa-bars font-20 "></i></a>
                </div>
                <div class="dropdown top-3" *ngIf="delziahrLogoShow">
                    <a class="nav-link user_btn"><img class="avatar img-css" src="../assets/images/Pslogo.png" alt=""
                            data-toggle="tooltip" data-placement="right" title="Company Info"></a>
                </div>
            </div>
            <div class="text-center text-purple font-11"><span
                    *ngIf="(this.compName =='' || this.compName == null || this.compName == undefined) && (this.groupName != '' && this.groupName != null && this.groupName != undefined)">Group
                    - {{this.groupName}}</span> <span
                    *ngIf="this.compName !='' && this.compName != null && this.compName != undefined">Company -
                    {{this.compName}}</span><br>
                <span *ngIf="this.roleShow !='' && this.roleShow != null">Role - {{this.roleShow}}</span>
            </div>
            <div class="right">
                <button id="sessionPopUp" type="button" class="btn btn-primary display-none"
                    (click)="sessioTimeOutPopUp(template2)">session PopUp</button>
                <button id="passwordExpiryPopUp" type="button" class="btn btn-primary display-none"
                    (click)="passwordExpiryPopUp(template3)">pass PopUp</button>
                <div *ngIf="roleType == 'Global' && distinctGroups?.length>0" class="dropdown d-flex top-2" dropdown>
                    <a class="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1 color-black box-shadow-none"
                        href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true"
                        aria-expanded="false" id="button-basic" dropdownToggle aria-controls="dropdown-basic">
                        <a class="nav-link dropdown-toggle group-company-css ml-1 font-12" data-toggle="dropdown"
                            role="button" aria-haspopup="true" aria-expanded="false">Group</a>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow dropdown-scroll width-auto"
                        id="dropdown-basic" *dropdownMenu role="menu" aria-labelledby="button-basic">
                        <ul class="right_chat list-unstyled p-0" (click)="dropDown($event)">
                            <div class="p-0 sticky-search">
                                <span class="p-input-icon-left p-ml-auto">
                                    <i class="pi pi-search dropdown-search"></i>
                                    <input type="text" pInputText class="ht-25" placeholder="Search"
                                        (keyup)="searchGroup($event.target.value)" />
                                </span>
                            </div>
                        </ul>
                        <ul class="right_chat list-unstyled p-0">
                            <li *ngFor="let data of distinctGroups" (click)="switchGroup(data)" class="text-left">
                                <a href="javascript:void(0);">
                                    {{data}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="distinctCompany?.length>0 && roleCheck!='Employee'" class="dropdown d-flex top-2" dropdown>
                    <a class="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1 color-black box-shadow-none"
                        href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true"
                        aria-expanded="false" id="button-basic" dropdownToggle aria-controls="dropdown-basic">
                        <a class="nav-link dropdown-toggle ml-1 font-12 group-company-css" data-toggle="dropdown"
                            role="button" aria-haspopup="true" aria-expanded="false">Company</a>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow dropdown-scroll width-auto"
                        id="dropdown-basic" *dropdownMenu role="menu" aria-labelledby="button-basic">
                        <!-- <div class="p-0 sticky-search">
                            <span class="p-input-icon-left p-ml-auto">
                                <i class="pi pi-search dropdown-search"></i>
                                <input type="text" pInputText class="ht-25" placeholder="Search" />
                            </span>
                        </div> -->
                        <ul class="right_chat list-unstyled p-0" (click)="dropDown($event)">
                            <div class="p-0 sticky-search">
                                <span class="p-input-icon-left p-ml-auto">
                                    <i class="pi pi-search dropdown-search"></i>
                                    <input type="text" pInputText class="ht-25" placeholder="Search"
                                        (keyup)="searchCompany($event.target.value)" />
                                </span>
                            </div>
                        </ul>
                        <ul class="right_chat list-unstyled p-0">
                            <li *ngFor="let data of distinctCompany" (click)="switchCompany(data)" class="text-left">
                                <a href="javascript:void(0);">
                                    {{data}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="distinctRoles?.length>0" class="dropdown d-flex top-2" dropdown>
                    <a class="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1 color-black box-shadow-none"
                        href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true"
                        aria-expanded="false" id="button-basic" dropdownToggle aria-controls="dropdown-basic">
                        <a class="nav-link dropdown-toggle ml-1 font-12 group-company-css" data-toggle="dropdown"
                            role="button" aria-haspopup="true" aria-expanded="false">Role</a>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow dropdown-scroll width-auto"
                        id="dropdown-basic" *dropdownMenu role="menu" aria-labelledby="button-basic">
                        <!-- <div class="p-0 sticky-search">
                            <span class="p-input-icon-left p-ml-auto">
                                <i class="pi pi-search dropdown-search"></i>
                                <input type="text" pInputText class="ht-25" placeholder="Search" />
                            </span>
                        </div> -->
                        <!-- <ul class="right_chat list-unstyled p-0" (click)="dropDown($event)">
                            <div class="p-0 sticky-search">
                                <span class="p-input-icon-left p-ml-auto">
                                    <i class="pi pi-search dropdown-search"></i>
                                    <input type="text" pInputText class="ht-25" placeholder="Search" />
                                </span>
                            </div>
                           </ul> -->
                        <ul class="right_chat list-unstyled p-0">
                            <li *ngFor="let data of distinctRoles" (click)="changeUser(data)" class="text-left">
                                <a href="javascript:void(0);">
                                    {{data.roleName}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-center text-purple font-12 mt-15"
                *ngIf="isEmpLoginByAdminYes == true && isSSOYes == true" (click)="backToParentLogin()">
                <div class="float-right">
                    <label class="form-label text-link">
                        Back To {{parentRoleName}}</label>
                </div>
                </div>
                <div class="dropdown d-flex" dropdown disable>
                    <a class="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1 color-black box-shadow-none"
                        href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true"
                        aria-expanded="false" id="button-basic" dropdownToggle aria-controls="dropdown-basic">
                        <a class="dropdown-toggle-new font-12 user-name-css" data-toggle="dropdown" role="button"
                            aria-haspopup="true" aria-expanded="false"> <img src="../assets/images/userdefault.png"
                                class="user-icon-css mr-1">{{userName}}</a>
                    </a>
                    <div class="dropdown-menu left-inherit right0" id="dropdown-basic" *dropdownMenu role="menu"
                        aria-labelledby="button-basic">
                        <div class="card">
                            <div class="card-body puple">
                                <div class="round roundImage" *ngIf="!imageUrl">
                                    <img src="../assets/images/userdefault.png">
                                </div>
                                <div *ngIf="imageUrl" class="round roundImage">
                                    <img alt="" [src]="imageUrl">
                                </div>
                                <div class="row mt-10">
                                    <div class="col-md-12">
                                        <h6 class="center font1">{{employeeInfo.employeeCode }}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h5 class="center font-2">{{employeeInfo.fullName }}</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h6 class="center font">{{employeeInfo.designation1 }}</h6>
                                    </div>
                                </div>
                                <div class="col-md-12 text-center  mt-90">
                                    <div class="row mt-60 color-black">
                                        <div class="col-md-6 mt-30"><span class="font-bold font-11 float-left">Group
                                                Name :</span></div>
                                        <div class="col-md-6 mt-30 font-11 float-left"><span class="float-left font-9"
                                                style="text-align: left;">
                                                {{userGroup}}</span>
                                        </div>
                                    </div>
                                    <div class="row color-black">
                                        <div class="col-md-6 mt-1 "><span class="font-bold float-left font-11">Company
                                                Name:</span>
                                        </div>
                                        <div class="col-md-6 mt-1"><span class="float-left  font-9"
                                                style="text-align: left;">
                                                {{userInfo.UserDetails.companyName}}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer footer1">
                                <div class="row mt-60">
                                    <div class="col-md-12 mt-5">
                                        <button [routerLink]="['/changePassword']" *ngIf="!isSSOLogin"
                                            class="purple-btn  mr-2 custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple"
                                            tooltip="Change Password"><b>
                                                <i class="fa fa-key"></i></b> Change
                                            Password</button>
                                        <button (click)="logout()" *ngIf="isEmpLoginByAdminYes != true || isSSOYes != true"
                                            class="purple-btn float-right custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple"
                                            tooltip="Logout"><b>
                                                <i class="fa fa-power-off mr-2"></i></b> Log
                                            Out</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="dropdown d-flex" dropdown>
                    <ul class="nav nav-pills">
                        <li class="nav-item dropdown" dropdown>
                            <a class="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1 color-black box-shadow-none"
                                href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true"
                                aria-expanded="false" id="button-basic" dropdownToggle aria-controls="dropdown-basic">
                                <img src="../assets/images/userdefault.png" class="user-icon-css"></a>
                            <div class="dropdown-menu left-inherit right0" id="dropdown-basic" *dropdownMenu role="menu"
                                aria-labelledby="button-basic">
                                <div class="card">
                                    <div class="card-body puple">
                                        <div class="round roundImage" *ngIf="!imageUrl">
                                            <img src="../assets/images/userdefault.png">
                                        </div>
                                        <div *ngIf="imageUrl" class="round roundImage">
                                            <img alt="" [src]="imageUrl">
                                        </div>
                                        <div class="row mt-10">
                                            <div class="col-md-12">
                                                <h6 class="center font1">{{employeeInfo.employeeCode }}</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="center font-2">{{employeeInfo.fullName }}</h5>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h6 class="center font">{{employeeInfo.designation1 }}</h6>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-center  mt-90">
                                            <div class="row mt-60 color-black">
                                                <div class="col-md-6 mt-4 "><span
                                                        class="font-bold font-11 float-left">Group Name :</span></div>
                                                <div class="col-md-6 mt-4 font-11 float-left"><span
                                                        class="float-left font-11">
                                                        {{userInfo.UserDetails.groupName}}</span>
                                                </div>
                                            </div>
                                            <div class="row color-black">
                                                <div class="col-md-6 mt-1 "><span
                                                        class="font-bold float-left font-11">Company Name:</span></div>
                                                <div class="col-md-6 mt-1"><span class="float-left  font-11">
                                                        {{userInfo.UserDetails.companyName}}</span></div>
                                            </div>
                                            <div class="row color-black">
                                                <div class="col-md-6 mt-1 "><span
                                                        class="float-left font-bold font-11">Role :</span></div>
                                                <div class="col-md-6 mt-1 "><span
                                                        class="float-left font-11">{{employeeInfo.roleName }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer footer1">
                                        <div class="row mt-60">
                                            <div class="col-md-12 mt-5">
                                                <button [routerLink]="['/changePassword']"
                                                    class="purple-btn  mr-2 custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple"
                                                    tooltip="Change Password"><b>
                                                        <i class="fa fa-key"></i></b> Change
                                                    Password</button>
                                                <button (click)="logout()"
                                                    class="purple-btn  custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple"
                                                    tooltip="Logout"><b>
                                                        <i class="fa fa-power-off mr-2"></i></b> Log
                                                    Out</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow " id="dropdown-basic" *dropdownMenu
                        role="menu" aria-labelledby="button-basic">
                        <a class="dropdown-item" [routerLink]="['/profile']" href="javascript:void(0)"><i
                                class="dropdown-icon fe fe-user"></i>
                            Profile</a>
                        <a class="dropdown-item" [routerLink]="['/settings']" href="javascript:void(0)"><i
                                class="dropdown-icon fe fe-settings"></i> Preferences</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0)"><i
                                class="dropdown-icon fe fe-help-circle"></i>help?</a>
                        <a class="dropdown-item" [routerLink]="['/login']" href="javascript:void(0)"><i
                                class="dropdown-icon fe fe-log-out"></i> Sign
                            out</a>
                    </div>
                </div>
                <div class="right">
                    <h1 class="page-title new-pagetitle">
                        {{userName}}</h1>
                </div> -->
            </div>
            <ng-template #template3>
                <div class="modal-header foot">
                    <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16">Password
                            Expiry</span>
                    </h5>
                    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body">
                            <span class="span2">{{passExpiry.message}}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer foot">
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Change Password" (click)="modalRef.hide()"
                        [routerLink]="['/changePassword']"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                        ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-key"></i></b> Change Password
                    </button>
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Close" (click)="modalRef.hide()"
                        class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted  "
                        tabindex="0" ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-times"></i></b> Close
                    </button>
                </div>
            </ng-template>
            <ng-template #template2>
                <div class="modal-header">
                    <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16">Session Time Out
                        </span>
                    </h5>
                    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                        aria-label="Close"><span aria-hidden="true" class="font-16 color-white">×</span></button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body">
                            <span class="span2"><i class="fa fa-history fa-2x text-red"></i> &nbsp; &nbsp;Your session
                                is going to expire after 1 minute. In order to continue, please click on
                                "Stay Logged In" or click on "Log Out" in case you are done !
                            </span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button _ngcontent-wxs-c616="" type="button" tooltip="STAY LOGGED IN" (click)="modalRef.hide()"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                        ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-sign-in "></i></b> STAY LOGGED
                        IN </button>
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Log Out" (click)="logout()"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                        ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-power-off"></i></b> Log Out
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
</div>