import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Password } from 'primeng/password';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: false } }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit,OnDestroy {
  mobile: any;
  today: Date = new Date();
  natureValue: string;
  passPolicy: any;
  dateOfBirth: any;
  motherName: any;
  captchaShow: boolean = false
  natureValueFlag: boolean = true
  tab1: boolean;
  tab2: boolean;
  tab3: boolean;
  mobileOrMail: any;
  email: any;
  interval: NodeJS.Timeout;
  timeLeft: number = 600;
  captchaCount: number = 0;
  @ViewChild('password') password: Password;
  minLeft: number;
  secLeft: number;
  otpValidation: any;
  otp: any;
  validateObj: any;
  captcha: any;
  equation: any;
  result: any;
  otpData: any;
  resultGiven: any;
  tab4: boolean;
  form: FormGroup;
  modalRef: any;
  policy: any;
  regex: any;
  validate: boolean;
  confirmPassVAlidate: boolean;
  saveData: any;
  isPasswordValid: boolean = false;
  isShowNewPassword: boolean = false;
  isShowConfirmPassword: boolean = false;
  isResendOTP: boolean = false;
  user: any;
  constructor(private authService: AuthService,
    private alertService: AlertServiceService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe) {
    this.form = this.formBuilder.group({
      newPass: new FormControl('', Validators.required),
      confirmPass: new FormControl('', Validators.required),
      captchaAnswer: new FormControl(''),
      equation: new FormControl(''),
      resultTotal: new FormControl('', Validators.required),

    });
  }
  ngOnInit(): void {
    this.tabValidate('tab1');
    this.natureValue = "Formula";
    // this.natureValueFlag = true;
    this.getNewCaptch();
  }

  ngOnDestroy():void{
     this.authService.logout();
  }
  getPasswordPolicy(): any {
    if (this.natureValueFlag == true) {
      this.mobileOrMail = { mobile: this.mobile }
    } else {
      this.mobileOrMail = { email: this.email }
    }
    this.authService.getPassPolicyWithout(this.mobileOrMail).subscribe(res => {
      this.policy = res.data.results[0];
      this.regex = JSON.stringify(this.policy.regex)
      if (this.regex.charAt(0) === '"' && this.regex.charAt(this.regex.length - 1) === '"')
        this.regex = this.regex.substr(1, this.regex.length - 2)
    })
  }
  submitMobileOrMail() {
    // this.captchaCount =0
    this.timeLeft = 600;
    this.isResendOTP = false;
    if (this.natureValueFlag == true) {
      if (this.mobile == undefined) {
        this.alertService.sweetalertError('Please Enter Mobile Number')
        return
      } else {
        this.mobileOrMail = { mobile: this.mobile }
      }
    } else {
      if (this.email == undefined) {
        this.alertService.sweetalertError('Please Enter Email')
        return
      } else {
        this.mobileOrMail = { email: this.email }
        localStorage.setItem('mobileOrMail', this.mobileOrMail)
      }
    }
    this.getPasswordPolicy()
    // setTimeout(() => {


    this.authService.postSendOptOnMobile(this.mobileOrMail).subscribe(res => {
      this.tabValidate('tab2');
      this.alertService.sweetalertMasterSuccess(res.status.message, '');

    }, err => {
      alert(err.error.status.message);
    });

    // }, 1000);
    setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.minLeft = Math.floor(this.timeLeft / 60);
        this.secLeft = Math.floor(this.timeLeft % 60);
      } else {
        this.isResendOTP = true;
      }
    }, 1000);
  }
  submitCaptchaOne() {
    // this.captchaCount =0
    if (this.captchaCount >= 4) {
      this.captchaCount = 0
    } else {
      this.captchaCount += 1;
      if (this.natureValueFlag == true) {
        this.mobileOrMail = {
          mobileNumber: this.mobile,
          mobileOtp: this.otp
        }
      } else {
        this.mobileOrMail = {
          email: this.email,
          emailOtp: this.otp
        }
      }
      if (this.captchaShow == true) {
        if (this.natureValueFlag == true) {
          this.otpData = { mobile: this.mobile }
        } else {
          this.otpData = { email: this.email }

        }

        if (this.result == this.resultGiven) {
          this.resultGiven = '';
          this.tabValidate('tab1');
          this.captchaShow = false;
        } else {
          this.alertService.sweetalertError('Please Enter Valid Answer')
        }
      } else {
        this.authService.validateOtpForgotpassword(this.mobileOrMail).subscribe(res => {
          this.alertService.sweetalertMasterSuccess(res.status.message, '');
          this.getNewCaptchChange();
          this.authService.doLoginUser(res.data.results[0].token);
          this.user =this.authService.getprivileges();
          sessionStorage.setItem('%qr',this.authService.enObject(this.user.UserDetails))
          if (this.policy.isTwoFactAuth == false) {
            this.tabValidate('tab4');
          } else {
            this.tabValidate('tab3');
          }
        }, err => {
          this.otp = ''
          this.alertService.sweetalertError(err.error.status.message);
        })
      }
      if (this.captchaCount > 2 && this.captchaCount <= 3) {
        this.captchaShow = true;
      }
    }
  }
  getNewCaptch(): any {
    this.authService.getCaptcha().subscribe(res => {
      this.captcha = res.data.results[0];
      this.equation = this.captcha.equation;
      this.result = this.captcha.result
    })
  }
  getNewCaptchChange(): any {
    this.authService.getCaptcha().subscribe(res => {
      this.captcha = res.data.results[0];
      this.form.get('equation').setValue(this.captcha.equation);
      this.result = this.captcha.result
    })
  }
  tabValidate(tab) {
    if (tab == 'tab1') {
      this.tab1 = true;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
    } else if (tab == 'tab2') {
      this.tab1 = false;
      this.tab2 = true;
      this.tab3 = false;
      this.tab4 = false;
    } else if (tab == 'tab3') {
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = true;
      this.tab4 = false;
    } else if (tab == 'tab4') {
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = true;
    }
  }
  sentLinkToMobile() {
    let data = { 'mobile': this.mobile }
    this.authService.sendNewPassword(data).subscribe(res => {
      alert(res.status.message);
    }, error => {
      alert(error
        .error.status.message);
    });
  }
  getSelectedNature(event) {
    if (event) {
      if (this.natureValue == "Formula") {
        this.natureValueFlag = true;
        this.email = ''
      }
    } else {
      this.natureValue == "Master"
      this.natureValueFlag = false;
      this.mobile = ''
    }
  }
  clearinput(value) {

  }
  resendOTP() {

    if (this.natureValueFlag == true) {
      this.mobileOrMail = { mobile: this.mobile }
    } else {
      this.mobileOrMail = { email: this.email }
    }
    this.authService.postSendOptOnMobile(this.mobileOrMail).subscribe(res => {
      this.timeLeft = 60
      this.isResendOTP = false
      this.alertService.sweetalertMasterSuccess(res.status.message, '');
    }, err => { })
    setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.minLeft = Math.floor(this.timeLeft / 60);
        this.secLeft = Math.floor(this.timeLeft % 60);
      }
    }, 1000);
  }
  validateMNameAndDOB() {
    if (this.natureValueFlag == true) {
      this.validateObj = {
        mobileNumber: this.mobile,
        dateOfBirth: this.datePipe.transform(this.dateOfBirth, "yyyy-MM-dd"),
        motherName: this.motherName
      }
    } else {
      this.validateObj = {
        email: this.email,
        dateOfBirth: this.datePipe.transform(this.dateOfBirth, "yyyy-MM-dd"),
        motherName: this.motherName
      }
    }
    this.authService.validateMotherNameAndDOB(this.validateObj).subscribe(res => {
      let result = res.data.results;
      this.tabValidate('tab4');
      this.getNewCaptch();
    }, error => {
      this.alertService.sweetalertError(error.error.status.message);
    })
  }
  pwpolicy(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
    // this.getPasswordPolicy()
  }
  getPasswordPolicyWithUserName() {
    if (this.natureValueFlag == true) {
      this.mobileOrMail = { mobile: this.mobile }
    } else {
      this.mobileOrMail = { email: this.email }
    }
    this.authService.getPassPolicyWithUserName(this.mobileOrMail).subscribe(res => {
      this.passPolicy = res.data.results[0];
    }, error => {
      this.alertService.sweetalertError(error.status.message);
    })
  }
  changePass(evt) {
    if (evt == "Strong") {
      this.validate = true;
    } else {
      this.confirmPassVAlidate = false;
    }
  }
  changeClass(evt) {
    if (evt.target.className == 'ng-tns-c422-2 ng-star-inserted pi pi-eye-slash') {
    }
  }
  changeConfirmPass(evt) {
    if (evt == "Strong" && (this.form.get('newPass').value == this.form.get('confirmPass').value)) {
      this.confirmPassVAlidate = true;
    } else {
      this.confirmPassVAlidate = false;
    }
  }
  saveChangePass() {
    if (this.form.get('resultTotal').value != this.captcha.result) {
      this.form.get('equation').reset();
      this.form.get('resultTotal').reset();
      this.alertService.sweetalertError('Please Enter Correct Answer')
      this.getNewCaptchChange();
      return
    } else {
      if (this.natureValueFlag == true) {
        this.saveData = { mobile: this.mobile, newPassword: this.form.get('confirmPass').value,capcha:this.captcha.equation,
        solution:this.result }
      } else {
        this.saveData = { email: this.email, newPassword: this.form.get('confirmPass').value,capcha:this.captcha.equation,
        solution:this.result}
      }
      this.authService.changePasswordForgot(this.saveData).subscribe(res => {
        this.alertService.sweetalertMasterSuccess('Password Updated Successfully', '')
        this.router.navigate(['/login']);
        this.form.reset();
        this.getNewCaptch();
      })
    }
    // let data={'mobile':this.mobile}
    // if(data.mobile!=undefined){
    //   this.authService.sendNewPassword(data).subscribe(res=>{
    //     alert(res.data.results[0]);
    //   },err=>{
    //     alert( err.error.status.message);
    //   });
    // }

  }
  reset() {
    this.form.reset();
    // this.getNewCaptch();
    this.getNewCaptchChange()
    this.password.input.nativeElement.value = '';
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if ((event.keyCode != 8 && !pattern.test(inputChar)) || (this.mobile?.length > 9)) {
      event.preventDefault();
    }
  }
  passwordValidation(val, evt) {
    debugger
    let value
    if (evt.type != 'keyup') {
      value = val.concat(evt.key)
    } else {
      value = val
    }
    if (evt.key != 'Enter') {
      if (value?.length <= this.policy.maxLength) {
        let specialCharsArr = [];
        specialCharsArr = this.policy.allowedSetOfSpecialChars.split(',')
        let i = 0
        let character: any = ''
        let numericCount = 0;
        let upperCaseCount = 0;
        let lowerCaseCount = 0;
        let specialCharCount = 0;
        while (i < value?.length) {
          character = value.charAt(i);
          if (!isNaN(character * 1)) {
            numericCount++;
            // alert('character is numeric');
          } else {
            let ind = specialCharsArr.findIndex(e => e == character)
            if (ind != -1) {
              specialCharCount++
            } else {
              if (character == character.toUpperCase()) {
                upperCaseCount++;
              }
              if (character == character.toLowerCase()) {
                lowerCaseCount++;
              }
            }
          }
          i++;
        }
        let repCount = 1
        let j = 0
        if (this.policy.repeatCharsCount > 0) {
          while (j < value?.length) {
            character = value.charAt(j);
            if (j > 0) {
              if (value.charAt(j) == value.charAt(j - 1)) {
                repCount++;
              } else {
                repCount = 1
              }
              if (repCount > this.policy.repeatCharsCount) {
                j = value.length
              }
            }
            j++;
          }
        } else {
          this.policy.repeatCharsCount = 0
        }
        if (numericCount >= this.policy.noOfNumericReq && upperCaseCount >= this.policy.noOfalphUpperCaseReq && lowerCaseCount >= this.policy.noOfalphalowerCaseReq
          && specialCharCount >= this.policy.specialCharactersRequiredCount && value?.length >= this.policy.minLength && value?.length <= this.policy.maxLength && repCount <= this.policy.repeatCharsCount) {
          this.isPasswordValid = true
        } else {
          this.isPasswordValid = false
        }
      } else {
        evt.preventDefault();
      }
    }
  }
  confirmPasswordValidation(val, evt) {
    debugger
    let value
    if (evt.type != 'keyup') {
      value = val.concat(evt.key)
    } else {
      value = val
    }
    if (evt.key != 'Enter') {
      if (value?.length <= this.policy.maxLength) {
      } else {
        evt.preventDefault();
      }
    }
  }
  onClickNewPassEye() {
    this.isShowNewPassword = !this.isShowNewPassword;
  }
  onClickConfirmPassEye() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }
  backToLogin(){
    this.authService.logout();
  }
}
