import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmpMasterLandingPageServiceService {
  sharedData: any;
  constructor(private httpClient : HttpClient) { }
  getEmployeeCountInformation(groupCompanyId){
    return this.httpClient.get(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getEmployeeDetails/'+groupCompanyId, {headers:{ 'X-TenantId': 'PaysquareDefault'}})
    .pipe(map((res: any) =>{
      return res;
    }))
  }
  /****** Get landinng page Count *************************/
  getEmployeeListByGroupIdTotalRecordCount(data): Observable<any> {
    return this.httpClient.post<any>(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getLandingPageSummary1', data);
  }

  getEmployeeSummaryInformation(body){
    return this.httpClient.post(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getLandingPageSummary',body )
    // return this.httpClient.post(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getLandingPageSummaryMultipleParameter',body )
    .pipe(map((res: any) =>{
      return res;
    }))
  }
 
  getEmployeeData(data){
    this.sharedData= data;
  }
  getSharedEmployeeData(){
  return   this.sharedData;
  }
  deleteSharedEmployeeData(){
    this.sharedData=null;
  }
createLoginAll(){
  return this.httpClient.get(environment.baseUrl8082 + 'employee-personal-info/employeeDataSendToGlobalMaster')
  .pipe(map((res: any) =>{
    return res;
  }))
}
  
createLoginSelected(data:any){
  return this.httpClient.post(environment.baseUrl8082 + 'employee-personal-info/employeeDataSendToGlobal',data)
  .pipe(map((res: any) =>{
    return res;
  }))
}
sendWelcomeMailToEmail(body:any){
    return this.httpClient.post(environment.baseUrl8081 + 'emailBodyAndEvent/sendEmails',body )
   .pipe(map((res: any) =>{
    return res;
  }))
}
}

