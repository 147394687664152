import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpDocumentService {
  isOpen: boolean = false;
  public apiUrl8083 = environment.baseUrl8083;

  constructor(private _HTTP: HttpClient,private authService:AuthService) { }

  // used to Open Help Document Template 
  openPopup() {
    this.isOpen = true;
  }

  // used to Close Help Document Template 
  closePopup() {
    this.isOpen = false;
  }

  // get help Document Data according to type 
  getHelpDocument(json) {
    let abc =this.authService.deObject(sessionStorage.getItem('%qr'));
    json.groupCompanyID=abc.globalCompanyMasterId;
    return this._HTTP.post(this.apiUrl8083 + 'userguide-details/getcategorywithScreen', json)
      .pipe(map((res: any) => {
        return res;
      }
      ));
  }
}