import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentViewComponent } from './modules/other-master/common-shared/component/document-view/document-view.component';
import { CustomePrealoadStrategiesService } from './custome-preaload-strategies.service';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((module) => module.AuthModule),
    data: { preload: false }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { preload: true }
  },

  // {
  //   path: 'profile',loadChildren:'./modules/profile/profile.module#ProfileModule'

  //   // loadChildren: () =>
  //   //   import('./modules/profile/profile.module').then(
  //   //     (m) => m.ProfileModule
  //   //   )
  // },
  {
    path: 'setting',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule), data: { preload: true },
  },
  {
    path: 'admin-approval',
    loadChildren: () =>
      import('./modules/admin-approval/admin-approval.module').then(
        (m) => m.AdminApprovalModule), data: { preload: false },
  },
  {
    path: 'uploadexcel',
    loadChildren: () =>
      import('./modules/uploadexcel/uploadexcel.module').then(
        (m) => m.UploadexcelModule
      ), data: { preload: false },
  },
  // {
  //   path: 'child',
  //   // loadChildren:'./modules/sharedlayout/sharedlayout.module#SharedlayoutModule'
  //   loadChildren: () =>
  //     import('./modules/sharedlayout/sharedlayout.module').then(
  //       (m) => m.SharedlayoutModule
  //     ), data: { preload: false },
  // },
  {
    path: 'employeelist',
    loadChildren: () =>
      import('./modules/employeemasterlistpage/employeemasterlistpage.module').then(
        (m) => m.EmployeemasterlistpageModule
      ), data: { preload: false },
  },
  {
    path: 'investment',
    loadChildren: () =>
      import('./modules/my-Investments/my-Investments.module').then(
        (m) => m.MyInvestmentsModule
      ), data: { preload: false },
  },
  {
    path: 'investment-approval',
    loadChildren: () =>
      import('./modules/investment-approval/investment-approval.module').then(
        (m) => m.InvestmentApprovalModule
      ), data: { preload: false },
  },
  {
    path: 'one-time-investments-approval',
    loadChildren: () =>
      import('./modules/onetime-investments-approval/onetime-investments-approval/onetime-investments-approval.module').then(
        (m) => m.OnetimeInvestmentsApprovalModule
      ), data: { preload: false },
  },
  {
    path: 'investment-approval-new',
    loadChildren: () =>
      import('./modules/approved-investment/approved-investment.module').then(
        (m) => m.ApprovedInvestmentModule
      ), data: { preload: false },
  },
  {
    path: 'PayrollInputs',
    loadChildren: () =>
      import('./modules/payroll-inputs/payroll-inputs.module').then(
        (m) => m.PayrollInputsModule
      ), data: { preload: true },
  },
  {
    path: 'lms',
    loadChildren: () =>
      import('./modules/lms/lms.module').then(
        (m) => m.LMSModule
      ), data: { preload: false },
  },

  // {
  //   path: 'menu', loadChildren: () =>
  //     import('./modules/lms/lms.module').then(
  //       (m) => m.LMSModule
  //     )
  // },


  // {
  //   path: 'lms', loadChildren: () =>
  //     import('./modules/lms/lms.module').then(
  //       (m) => m.LMSModule
  //     ), data: { preload: false },
  // },


  {
    path: 'workflow',
    loadChildren: () =>
      import('./modules/workflow/workflow.module').then(
        (m) => m.workflowModule), data: { preload: false },
  },
  {
    path: 'employee-master',
    loadChildren: () =>
      import('./modules/employee-master/employee-master.module').then(
        (m) => m.EmployeeMasterModule), data: { preload: false },
  },

  {
    path: 'otherMaster',
    loadChildren: () =>
      import('./modules/other-master/other-master.module').then(
        (m) => m.OtherMasterModule), data: { preload: true },
  },
  {
    path: 'companysetting',
    loadChildren: () =>
      import('./modules/companysetting/companysetting.module').then(
        (m) => m.CompanySettingModule), data: { preload: true },
  },
  {
    path: 'query',
    loadChildren: () =>
      import('./modules/query/query.module').then(
        (m) => m.QueryModule
      )
  },
  {
    path: 'loan',
    loadChildren: () =>
      import('./modules/loan/loan.module').then(
        (m) => m.LoanModule), data: { preload: false },
  },
  {
    path: 'lock',
    loadChildren: () =>
      import('./modules/lock/lock.module').then(
        (m) => m.LockModule), data: { preload: false },

  },
  {
    path: 'email-sms',
    loadChildren: () =>
      import('./modules/email-sms/email-sms.module').then(
        (m) => m.EmailSmsModule), data: { preload: false },
  },
  {
    path: 'loan-master',
    loadChildren: () =>
      import('./modules/loan-master/loan-master.module').then(
        (m) => m.LoanMasterModule), data: { preload: false },
  },
  {
    path: 'reimbursement',
    loadChildren: () =>
      import('./modules/reimbursement/reimbursement.module').then(
        (m) => m.ReimbursementModule), data: { preload: false },
  },
  {
    path: 'reimbursement-approval',
    loadChildren: () =>
      import('./modules/reimbursement-approval/reimbursement-approval.module').then(
        (m) => m.ReimbursementApprovalModule)
  },

  // {
  //   path: 'journal-voucher',loadChildren:'./modules/companysetting/journal-voucher/journal-voucher.module#JournalVoucherModule'
  //   // loadChildren: () =>
  //   //   import('./modules/companysetting/journal-voucher/journal-voucher.module').then(
  //   //     (m) => m.JournalVoucherModule), data: { preload: false },
  // },
  // {
  //   path: 'survey-questionnaire',loadChildren:'./modules/companysetting/survey-questionnaire/survey-questionnaire.module#SurveyQuestionnaireModule'
  //   // loadChildren: () => import('./modules/companysetting/survey-questionnaire/survey-questionnaire.module').then(
  //   //   (m) => m.SurveyQuestionnaireModule), data: { preload: false },

  // },
  //  {
  //   path: 'survey-master',loadChildren:'./modules/companysetting/survey-master/survey-master.module#SurveyMasterModule'
  //   // loadChildren: () => import('./modules/companysetting/survey-master/survey-master.module').then(
  //   //   (m) => m.SurveyMasterModule), data: { preload: false },

  // },
  // {
  //   path: 'expence-head-creation',loadChildren:'./modules/companysetting/expence-head-creation/expence-head-creation.module#ExpenceHeadCreationModule'
  //   // loadChildren: () => import('./modules/companysetting/expence-head-creation/expence-head-creation.module').then(
  //   //   (m) => m.ExpenceHeadCreationModule,
  //   // )
  // },
  // {
  //   path: 'expence-attribute-creation',
  //   loadChildren: () => import('./modules/companysetting/e').then(
  //     (m) => m.), data: { preload: false },
  // },
  // {
  //   path: 'expence-attribute-creation',
  //   loadChildren: () => import('./modules/companysetting/expence-attribute-group/expence-attribute-group.module#ExpenceHeadCreationModule').then(
  //     (m) => m.SurveyQuestionnaireModule), data: { preload: false },
  // },

  {
    path: 'Payroll',
    loadChildren: () =>
      import('./modules/paymenttracking/paymenttracking.module').then(
        (m) => m.PaymenttrackingModule), data: { preload: false },
  },
  {
    path: 'CRM',
    loadChildren: () =>
      import('./modules/crm/crm.module').then(
        (m) => m.CRMModule)
  },
  {
    path: 'Agreement',
    loadChildren: () =>
      import('./modules/crm/agreement/agreement.module').then(
        (m) => m.AgreementModule), data: { preload: false },
  },
  // {
  //   path: 'billing',loadChildren:'./modules/companysetting/billing/billing.module#BillingModule'
  //   // loadChildren:()=>
  //   // import('./modules/companysetting/billing/billing.module').then(
  //   //   (m)=>m.BillingModule), data: { preload: false },
  // },
  {
    path: 'security',
    loadChildren: () =>
      import('./modules/security/security.module').then(
        (m) => m.SecurityModule), data: { preload: false },
  },
  {
    path: 'flexiMaster',
    loadChildren: () =>
      import('./modules/flexi/flexi.module').then(
        (m) => m.FlexiModule), data: { preload: false },
  },
  {
    path: 'expense-reimbursement',
    loadChildren: () =>
      import('./modules/expense-reimbursement/expense-reimbursement.module').then(
        (m) => m.ExpenseReimbursementModule), data: { preload: false },
  },
  // {
  //   path: 'keypara',loadChildren:'./modules/companysetting/keypara/keypara.module#KeyparaModule'
  //   // loadChildren:()=>
  //   // import('./modules/companysetting/keypara/keypara.module').then(
  //   //   (m)=>m.KeyparaModule), data: { preload: false },
  // },
  {
    path: 'sso',
    loadChildren: () =>
      import('./modules/sso/sso.module').then(
        (m) => m.SsoModule), data: { preload: false },
  },
  {
    path: 'integration',
    loadChildren: () =>
      import('./modules/integration/integration.module').then(
        (m) => m.IntegrationModule), data: { preload: false },
  },
  // {
  //   path: 'employee-reports',loadChildren:'./modules/companysetting/report/employee-reports/employee-reports.module#EmployeeReportsModule'
  //   // loadChildren: () =>
  //   // import('./modules/companysetting/report/employee-reports/employee-reports.module').then(
  //   //   (m) => m.EmployeeReportsModule), data: { preload: false },
  // },
  {
    path: 'Asset',
    loadChildren: () =>
      import('./modules/asset-approval/asset-approval.module').then(
        (m) => m.AssetApprovalModule), data: { preload: false },
  },
  {
    path: 'document-view',
    component: DocumentViewComponent
  },
  {
    path: 'payroll-application',
    loadChildren: () =>
      import('./modules/payroll-application/payroll-application.module').then(
        (m) => m.PayrollApplicationModule), data: { preload: false },
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./modules/reports/reports.module').then(
        (m) => m.ReportsModule), data: { preload: false },
  },
   {
    path: 'approval',
    loadChildren: () =>
      import('./modules/approval/approval.module').then(
        (m) => m.ApprovalModule), data: { preload: false },
  },
  {
    path: 'ESOP-RSU-ESPP',
    loadChildren: () =>
      import('./modules/esop-employee-view/esop-employee-view.module').then(
        (m) => m.EsopEmployeeViewModule), data: { preload: false },
  }
  // { path: '**', pathMatch: 'full', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: CustomePrealoadStrategiesService }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

