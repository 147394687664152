import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { AlertServiceService } from '../../core/services/alert-service.service';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js'
import { UserRole } from './model/userRole.model';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // public apiUrl = environment.apiBaseUrl;
  private userRole:UserRole;
  public apiUrl = environment.baseUrl8080;
  private readonly JWT_TOKEN = '%qycutr';
  private readonly REFRESH_TOKEN = '';
  public loggedUser: string;
  public shareData = new Subject<any>();
  public shareDataChild = new Subject<any>();
  public childDataForBreadcrumb = new BehaviorSubject<any>([])
  public callBreadcrumb = new Subject<any>();
  public shareSingle = new Subject<any>();
  public toggelChange = new Subject<any>();
  public expandBody = new Subject<any>();
  public shareCompanies = new Subject<any>();
  public shareGroups = new Subject<any>();
  public shareRoleName = new Subject<any>();
  public shareGroupName = new Subject<any>();
  public shareCompName = new Subject<any>();
  public showHideHome = new BehaviorSubject<any>(true)
  public groupCompanyData = new Subject<any>();
  private readonly ROLE = '%qyrcut';
  authorizedRoutURL: any
  employeeInfo:any;
  user: any;
  distinctRoles: Array<any> = [];
  lastLoginTime = new Subject<any>();
  public loadHeader = new Subject<any>();
  public backToParentEmpMasterMenu = new Subject<any>();
  public showChildMenuHeader = new Subject<any>();
  public showChildMenu = new Subject<any>();
  public changeTabShowChildMenu = new Subject<any>();
  constructor(private http: HttpClient, private router: Router,private alertService: AlertServiceService) { }

  getUserRole(): UserRole {
    return this.userRole;
  }

  setUserRole(roleType:UserRole){
    this.userRole = roleType;
  }
  
  postLogin(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'users/login', data);
  }
  emitdata(x: any) {
    this.shareData.next(x);
  }
  allowedPathVariables(x: any) {
    this.allowedPathVariablesOne(x).subscribe(res => {
      this.authorizedRoutURL = res.data.results
    })
  }
  allowedPathVariablesOne(x):Observable<any> {
    let formData = new FormData();
    formData.append('globalCompanyMasterId', x.globalCompanyMasterId)
    formData.append('userRoleId', x.userRoleId)
    return this.http.post<any>(this.apiUrl + 'getParentChildRelation/getLeftmenusByPrivilegesAPIList', formData)
  }
  checkUrlAllowed(x: any) {
    return true
    // if (!this.authorizedRoutURL) {
    //   let value = JSON.parse(sessionStorage.getItem('%qr'))
    //   this.allowedPathVariables(value);
    //   setTimeout(() => {
    //     if (this.authorizedRoutURL.indexOf(x) === -1) {
    //       return false
    //     } else {
    //       return true;
    //     }
    //   }, 500);
    // } else {
    //   if (this.authorizedRoutURL.indexOf(x) === -1) {
    //     return false
    //   } else {
    //     return true;
    //   }
    // }
  }
  postOTP(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'otp/validate', data)
      // return this.http.post('http://deliziahruat.paysquare.com:8080/hrms/v1/otp/validate', data)
      .pipe(tap(res => {
        this.doLoginUser(res.data.results[0].token);
        return res;
      }));
  }
  sendNewPassword(data):Observable<any> {
    return this.http.post(this.apiUrl + 'users/forgotPassword', data);
  }
//
   getChildMenuByParent():Observable<any>{
    let data = new FormData();
    let parentMenu =sessionStorage.getItem('%qrch');
    let menusList = [];
    menusList = JSON.parse(sessionStorage.getItem('%lml'));
    let menuData = [];
    menuData.push(menusList.find(e=> e.menuName == parentMenu))
    let menuObj:any = {"menusList":menuData}
    if(parentMenu){
    let input = this.deObject(sessionStorage.getItem('%qr'))
    data.append('globalCompanyMasterId',input.globalCompanyMasterId);
    data.append('userRoleId',input.userRoleId);
    data.append('parentMenuName',parentMenu);
    data.append('isParentMenuName','true');
    data.append('menusList',JSON.stringify(menuObj));
    return this.http.post(this.apiUrl + 'getParentChildRelation/getChildMenusByParentMenuNameTest', data);
    }
  }
  getChildMenuByParentSub(menu):Observable<any>{
    let data = new FormData();
    let input = this.deObject(sessionStorage.getItem('%qr'))
    data.append('globalCompanyMasterId',input.globalCompanyMasterId);
    data.append('userRoleId',input.userRoleId);
    data.append('parentMenuName',menu);
    data.append('isParentMenuName','false')
    return this.http.post<any>(this.apiUrl + 'getParentChildRelation/getChildMenusByParentMenuName', data);
  }
  getGrandChildByChild():Observable<any>{
    let data = new FormData();
    let childMenu =sessionStorage.getItem('%qrgch');
    if(childMenu){
    let input = this.deObject(sessionStorage.getItem('%qr'))
    data.append('globalCompanyMasterId',input.globalCompanyMasterId);
    data.append('userRoleId',input.userRoleId);
    data.append('childMenuName',childMenu)
    return this.http.post<any>(this.apiUrl + 'getParentChildRelation/getMenusByChildMenuName', data);
    }
  }
  // to get New Captcha
  getCaptcha():Observable<any>{
    return this.http.get<any>(this.apiUrl + 'mathematical/captcha', { headers: { 'Content-Type': 'application/json' } });
  }
  // to get passwordPolicy
  getPassPolicy():Observable<any>{
    return this.http.get<any>(this.apiUrl + 'password-policy/', { headers: { 'Content-Type': 'application/json' } });
  }
  // get pollicy without t
  getPassPolicyWithout(data):Observable<any>{
    return this.http.post<any>(this.apiUrl + 'password-policy/getPasswordPolicy', data);
  }
  //to get userdata
  userDetail():Observable<any>{
    return this.http.get<any>(this.apiUrl + 'employeeDetails/getByEmployeeMasterId', { headers: { 'Content-Type': 'application/json' } });
  }
  //to get password expiry notification
  getPasswordExpiry():Observable<any>{
    return this.http.get<any>(this.apiUrl + 'password/expiry')
  }
  //to get session expiry notificaion
  getSessionExpiry():Observable<any>{
    return this.http.get<any>(this.apiUrl + 'users/session-expiry')
  }
  //getCaptcha without token
  getCaptchaWithoutT():Observable<any>{
    return this.http.get<any>(this.apiUrl + 'mathematical/captcha', { headers: { 'Content-Type': 'application/json' } });
  }
  //change password without Token
  changePasswordWithoutT(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'user/changePassword', data)
  }
  changePassword(data) :Observable<any>{
    return this.http.post<any>(this.apiUrl + 'user/changePassword', data)
  }
  ///
  changePasswordForgot(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'user/changeAndSavePass', data)
  }
  //forgot password mobile emaail otp
  postSendOptOnMobile(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'users/forgotPassword', data)
  }
  postResendOptOnMobile(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'users/forgot/resend', data)
  }
  dataFromleftmenu(roleId):Observable<any> {
    return this.http.get<any>(this.apiUrl + 'getParentChildRelation/getLeftmenusByPrivileges/' + roleId)
  }
  validateOtpForgotpassword(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'users/forgotPwdOtpVerification', data)
  }
  getPassPolicyWithUserName(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'password-policy/getPasswordPolicy', data)
  }
  validateMotherNameAndDOB(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'users/detailVerification', data)
  }
  resendOTPForgotPass(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'forgot/resend', data)
  }
  getleftMenuData(id, companyId):Observable<any> {
    let formData = new FormData();
    formData.append('globalCompanyMasterId', companyId);
    formData.append('userRoleId', id)
    return this.http.post<any>(this.apiUrl + 'getParentChildRelation/getLeftmenusByPrivileges/', formData);
  }
  getImage():Observable<any> {
    let formData = new FormData();
    let abc = this.deObject(sessionStorage.getItem('%qr'))

    formData.append('globalCompanyMasterId', abc.globalCompanyMasterId);
    return this.http.post<any>(this.apiUrl + 'companyMaster/getCompanyDetails', formData);
  }
  dataFromLocalFile(): Observable<any[]> {
    return this.http.get<any[]>('assets/applicationMenu.json')
  }
  dataFromLocalFileRole(): Observable<any[]> {
    return this.http.get<any[]>('assets/roleAssigned.json')
  }
  //11 April 2022
  getAssignedUserRoles(data):Observable<any> {
    // return this.http.get(this.apiUrl + 'employeeDetails/getEmployeeRole')
    //change request method on 11 May 2023
    return this.http.post<any>(this.apiUrl + 'employeeDetails/getEmployeeRole',data)
      
  }
  checkUser(role): Observable<any>  {
    let param = new FormData;
    param.append('userRoleId', role)
    return this.http.post<any>(this.apiUrl + 'companyMaster/globalCompanyMaster/', param)
  }
  public login(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'otp/validate', data)
      .pipe(
        tap((token) => this.doLoginUser(token)),
        // mapTo(true),
        catchError((error) => {
          //  alert(error);
          return of(false);
        }));
  }
  logout() {
    let isSSOLogin : boolean = JSON.parse(sessionStorage.getItem('issso'))
    let data = this.deObject(sessionStorage.getItem('%qr'))
    if(data != null && data != undefined){
    let ssoCompanyId = data.globalCompanyMasterId;
    if(isSSOLogin){
      this.getSSOSettingById(ssoCompanyId).subscribe((res:any)=>{
        document.location.href = res.data.results[0].logoutURL
      }) ;
    
    // sessionStorage.setItem('issso',JSON.stringify(false))
    }
  }
    this.doLogoutUser();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
    // window.location='http://localhost:8099/sample-idp';
    
  }
  isAuthorized() {
    return !!this.getAuthorized();
  }
  isLoggedIn() {
    return !!this.getJwtToken();
  }
  refreshToken() :Observable<any>{
    return this.http.post<any>(this.apiUrl + '/Refresh', {
      refreshToken: this.getRefreshToken(),
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.jwt);
    }));
  }
  getJwtToken() {
    // console.log("JWT_TOKEN",sessionStorage.getItem(this.JWT_TOKEN));
    return sessionStorage.getItem(this.JWT_TOKEN);
  }
  getAuthorized() {
    // console.log("JWT_TOKEN",sessionStorage.getItem(this.JWT_TOKEN));
    return sessionStorage.getItem('in');
  }
  getprivileges() {
    return jwt_decode(sessionStorage.getItem(this.JWT_TOKEN));
  }

  // private doLoginUser(tokens: Tokens) {
  //   this.storeTokens(tokens);
  // }
  //temp done the Publich
  public doLoginUser(tokens: Tokens) {
    this.storeTokens(tokens);
  }
  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }
  private getRefreshToken() {
    return sessionStorage.getItem(this.REFRESH_TOKEN);
  }
  private storeJwtToken(jwt: string) {
    sessionStorage.setItem(this.JWT_TOKEN, jwt);
  }
  private storeTokens(tokens) {
    sessionStorage.setItem(this.JWT_TOKEN, tokens);
    // localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }
  private removeTokens() {
    sessionStorage.removeItem(this.JWT_TOKEN);
    sessionStorage.removeItem(this.REFRESH_TOKEN);
    sessionStorage.clear();
  }
  // get user roll data after login
  getDataRoleWise():Observable<any> {
    return (
      this.http.get<any>(this.apiUrl + 'employeeDetails/getEmployeeRole')
        .pipe(tap(res => {
          this.setRoleInSession(res.data.results[0]);
        })
        )
    );
  }
  /** set role data into session */
  setRoleInSession(roleData) {
    sessionStorage.setItem(this.ROLE, JSON.stringify(roleData));
  }
  /** get role data from session */
  getRoleFromSession() {
    return JSON.parse(sessionStorage.getItem(this.ROLE));
  }
  /**get company details from session */
  getComapnyFromSession(){
    return this.deObject(sessionStorage.getItem('%qr'));
  }
  getLogout():Observable<any>{
    return this.http.get<any>(this.apiUrl + 'users/logout')
  }
  getLogin():Observable<any>{
    return this.http.get<any>(this.apiUrl + 'users/update')
  }
  getIPAddress():Observable<any>{
    return this.http.get<any>('https://api64.ipify.org/?format=json')
  }

  checkIPAddress():Observable<any>{
    return this.http.get<any>('https://geolocation-db.com/json/')
  }

  /**get globalCompanyList details from session */
  getGlobalCompanyListFromSession(){
    return this.deValue(sessionStorage.getItem('globalCompanyList'));
  }

  checkIPUserRole(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'users/check', data);
  }


  getSSOLoginAndRole(){
    this.user = this.getprivileges();
          let obj = {
            "ipAddress": null
          }
          this.getAssignedUserRoles(obj).subscribe(res => {
            this.distinctRoles = res.data.results[0];
            let isEmpLoginByAdmin : boolean = JSON.parse(sessionStorage.getItem('isemploginbyadmin'))
            if(isEmpLoginByAdmin){
              for(let i=0;i<this.distinctRoles.length;i++){
                let ind = this.distinctRoles.findIndex(e=> e.type == 'Global' || e.type == 'Admin')
                if(ind != -1){
                  this.distinctRoles.splice(ind,1)
                  i--;
                }
              }
            }
            if (this.distinctRoles.length == 0) {
              // this.isLoader = false
              // this.otpDiv = false
              this.alertService.sweetalertError('Access Denied !');
              this.logout();
            } else {
              if(this.distinctRoles.length > 1){
              this.getLastLoginRoleType(this.distinctRoles[0].globalUserMasterId).subscribe((resType:any)=>{
                console.log('Found chnage Role Type Name',resType.data.results[0])
                if(resType.data.results[0] == 'Employee' || resType.data.results[0] == 'Admin' || resType.data.results[0] == 'Global'){   
                this.showLastLoginRoles(resType.data.results[0])
              }else{    
                this.showAssignedUserRoles()
              }
              },(error:any)=>{
                this.showAssignedUserRoles()
               })
              }else{
              this.showAssignedUserRoles()
              }
            }
          }, (error: any) => {
            // this.isLoader = false;
          })
  }
  showAssignedUserRoles() {
    let globalRolePresent = this.distinctRoles.find(x => x.type == 'Global')
    let adminRolePresent = this.distinctRoles.find(x => x.type == 'Admin')
    sessionStorage.setItem('selectedCountry', '')
    sessionStorage.setItem('selectedGroup', '')
    sessionStorage.setItem('selectedRole', '')
    sessionStorage.setItem('selectedCompany', '')
    let empData = this.distinctRoles.find(x => x.type == 'Employee')
    if (empData != undefined) {
      sessionStorage.setItem('empComp', this.enValue(JSON.stringify(empData.xtenantId)))
    }
    if (this.distinctRoles.length == 1) {
      // sessionStorage.setItem('%qr', JSON.stringify(this.distinctRoles[0]))
      sessionStorage.setItem('%qr',this.enObject(this.distinctRoles[0]))
      // sessionStorage.setItem('%qrCopy', JSON.stringify(this.distinctRoles[0]));
      sessionStorage.setItem('%qrCopy',this.enObject(this.distinctRoles[0]))
      sessionStorage.setItem('%rid', this.enValue(JSON.stringify(this.distinctRoles[0].userRoleId)))
      localStorage.setItem('employeeMasterId', this.user.UserDetails.employeeMasterId);
      let isEmpLoginByAdminCheck : boolean = JSON.parse(sessionStorage.getItem('isemploginbyadmin'))
      let isSSOCheck : boolean = JSON.parse(sessionStorage.getItem('issso'))
      if(isEmpLoginByAdminCheck == true && isSSOCheck == true){
        this.loadHeader.next(this.distinctRoles[0].roleName)
      }
      this.shareData.next();
      this.router.navigate(['/employee-master/employee-dashboard']);
    }
    else if (globalRolePresent) {
      if (globalRolePresent.globalCompanyMasterIdList.split(',').length > 1) {
        this.shareRoleName.next(globalRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.enValue(globalRolePresent.roleName))
        // sessionStorage.setItem('%qr', JSON.stringify(globalRolePresent));
        sessionStorage.setItem('%qr',this.enObject(globalRolePresent))
        // sessionStorage.setItem('%qrCopy', JSON.stringify(globalRolePresent));
        sessionStorage.setItem('%qrCopy',this.enObject(globalRolePresent))
        sessionStorage.setItem('%rid', this.enValue(JSON.stringify(globalRolePresent.userRoleId)))
        this.router.navigate(['/userGroupSelectPage']);
      } else {
        // sessionStorage.setItem('%qr', JSON.stringify(globalRolePresent));
        sessionStorage.setItem('%qr',this.enObject(globalRolePresent))
        // sessionStorage.setItem('%qrCopy', JSON.stringify(globalRolePresent));
        sessionStorage.setItem('%qrCopy',this.enObject(globalRolePresent))
        sessionStorage.setItem('globalCompanyList', this.enValue(JSON.stringify(globalRolePresent.globalCompanyMasterIdList)));
        this.shareData.next();
        //changes for single company
        let tempRole = this.deObject(sessionStorage.getItem('%qr'));
        tempRole.groupName = globalRolePresent.xtenantId
        tempRole.globalCompanyMasterId = JSON.parse(tempRole.globalCompanyMasterIdList)
        // sessionStorage.setItem('%qr', JSON.stringify(tempRole))
        sessionStorage.setItem('%qr',this.enObject(tempRole))
        this.shareCompName.next(globalRolePresent.companyName)
        this.shareRoleName.next(globalRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.enValue(globalRolePresent.roleName))
        this.router.navigate(['Dashboard']);
      }
    } else if (adminRolePresent) {
      sessionStorage.setItem('%rid', this.enValue(JSON.stringify(adminRolePresent.userRoleId)))
      // sessionStorage.setItem('%qr', JSON.stringify(adminRolePresent));
      sessionStorage.setItem('%qr',this.enObject(adminRolePresent))
      // sessionStorage.setItem('%qrCopy', JSON.stringify(adminRolePresent));
      sessionStorage.setItem('%qrCopy',this.enObject(adminRolePresent))
      if (adminRolePresent.globalCompanyMasterIdList.split(',').length > 1) {
        this.shareRoleName.next(adminRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.enValue(adminRolePresent.roleName))
        this.router.navigate(['/userGroupSelectPage']);
      } else {
        // sessionStorage.setItem('%qr', JSON.stringify(adminRolePresent));
        sessionStorage.setItem('%qr',this.enObject(adminRolePresent))
        // sessionStorage.setItem('%qrCopy', JSON.stringify(adminRolePresent));
        sessionStorage.setItem('%qrCopy',this.enObject(adminRolePresent))
        sessionStorage.setItem('globalCompanyList', this.enValue(JSON.stringify(adminRolePresent.globalCompanyMasterIdList)));
        this.shareData.next();
        //changes for single company
        let tempRole = this.deObject(sessionStorage.getItem('%qr'));
        tempRole.groupName = adminRolePresent.xtenantId
        // sessionStorage.setItem('%qr', JSON.stringify(tempRole))
        sessionStorage.setItem('%qr',this.enObject(tempRole))
        this.shareCompName.next(adminRolePresent.companyName)
        this.shareRoleName.next(adminRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.enValue(adminRolePresent.roleName))
        this.router.navigate(['Dashboard']);
      }
    }
    // this.getDataRoleWise();

    this.alertService.sweetalertMasterSuccess('Login Successful', '');
  }

  getSSOSettingById(id):Observable<any>{
    return this.http.get<any>(this.apiUrl + 'ssoLogin/ssoSettingById/'+id)
      
  }

  getEmpToken(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'users/empLogin', data);
  }

  empLoginDetails(empId,compId){
    let obj = {
      "groupCompanyId":compId,
      "employeeMasterId":empId
    }
    this.getEmpToken(obj).subscribe((res:any)=>{
      let isSSOLogin : boolean = JSON.parse(sessionStorage.getItem('issso'))
      if(isSSOLogin == true){
        let pt = sessionStorage.getItem('%qycutr')
        let logInTypeData = this.deObject(sessionStorage.getItem('%qr'))
        //do logout
        this.doLogoutUser();
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
    //logout end
    sessionStorage.setItem('ptrrolename', JSON.stringify(logInTypeData.roleName))
    sessionStorage.setItem('isemploginbyadmin', JSON.stringify(true))
    sessionStorage.setItem('issso', JSON.stringify(true))
    sessionStorage.setItem('%pltsso', pt)
    this.doLoginUser(res.data.results[0].token);
    this.getSSOLoginAndRole()
      }else{
      let URL = window.location.origin + "/" + "emplogin" + "/" + res.data.results[0].token
      // let URL = "https://test.deliziahr.com/emplogin" + "/" + res.data.results[0].token
      window.open(URL,'_blank');
    }
    },(error)=>{
      // console.log('Someting went wrong')
      this.alertService.sweetalertError(error['error']['status']['message'])
    })
  }

  getEmpLoginPermissableForAdmin() :Observable<any>{
    let tempRole = this.deObject(sessionStorage.getItem('%qr'));
    let data = {
      'employeeMasterId':tempRole.employeeMasterId,
      'groupCompanyId':tempRole.globalCompanyMasterId,
      'roleName':tempRole.roleName,
      'loginCompany':tempRole.loginCompanyName
    }
    return this.http.post<any>(this.apiUrl + 'empLoginForAdmin/getEmpLoginPermissable', data);
  }

  getLastLogin(id):Observable<any>{
    return this.http.get<any>(this.apiUrl + 'users/lastLoginTime/'+id)
  }

  saveChangeLoginRole(type,globalUserMasterId) :Observable<any>{
    let issso : boolean = false;
    if(JSON.parse(sessionStorage.getItem('issso')) == true){
      issso = true;
    }
    let data = {
      'globalUserMasterId':globalUserMasterId,
      'type':type,
      'issso':issso,
    }
    console.log('Change Role type obj',data)
    return this.http.post<any>(this.apiUrl + 'users/changeRoleType', data);
  }

  getLastLoginRoleType(globalUserMasterId):Observable<any> {
    let issso : boolean = false;
    if(JSON.parse(sessionStorage.getItem('issso')) == true){
      issso = true;
    }
    let data = {
      'globalUserMasterId':globalUserMasterId,
      'issso':issso,
    }
    console.log('Change Role type obj',data)
    return this.http.post<any>(this.apiUrl + 'users/lastLoginRoleType', data);
  }

  showLastLoginRoles(lastLoginType){
    // this.service.getAssignedUserRoles().subscribe(res=>{
      // this.isLoader = false;
      // this.distinctRoles=res.data.results[0];
      this.user =this.getprivileges();
     let globalRolePresent =  this.distinctRoles.find(x=>x.type=='Global')
     let adminRolePresent =  this.distinctRoles.find(x=>x.type=='Admin')
     let employeeRolePresent = this.distinctRoles.find(x=>x.type=='Employee')
     sessionStorage.setItem('selectedCountry','')
     sessionStorage.setItem('selectedGroup','')
     sessionStorage.setItem('selectedRole','')
     sessionStorage.setItem('selectedCompany','')
     let empData = this.distinctRoles.find(x=>x.type=='Employee')
     if(empData != undefined){
     sessionStorage.setItem('empComp',this.enValue(JSON.stringify(empData.xtenantId)))
     }
    if(lastLoginType == 'Employee'){
    //  sessionStorage.setItem('%qr',JSON.stringify(employeeRolePresent))
     sessionStorage.setItem('%qr',this.enObject(employeeRolePresent))
     this.shareRoleName.next(employeeRolePresent.roleName)
      // sessionStorage.setItem('selectedRole',employeeRolePresent.roleName)
     this.lastLoginTime.next();
    // sessionStorage.setItem('%qrCopy',JSON.stringify(employeeRolePresent));
    sessionStorage.setItem('%qrCopy',this.enObject(employeeRolePresent))
    sessionStorage.setItem('%rid',this.enValue(JSON.stringify(employeeRolePresent.userRoleId)))
    localStorage.setItem('employeeMasterId',this.user.UserDetails.employeeMasterId);
    this.shareData.next();
    this.router.navigate(['/employee-master/employee-dashboard']);
     }
   else if(lastLoginType == 'Global'){
    if(globalRolePresent.globalCompanyMasterIdList.split(',').length>1){
      this.shareRoleName.next(globalRolePresent.roleName)
      sessionStorage.setItem('selectedRole',this.enValue(globalRolePresent.roleName))
      // sessionStorage.setItem('%qr',JSON.stringify(globalRolePresent));
      sessionStorage.setItem('%qr',this.enObject(globalRolePresent))
      this.lastLoginTime.next();
      // sessionStorage.setItem('%qrCopy',JSON.stringify(globalRolePresent));
      sessionStorage.setItem('%qrCopy',this.enObject(globalRolePresent))
      sessionStorage.setItem('%rid',this.enValue(JSON.stringify(globalRolePresent.userRoleId)))
      this.router.navigate(['/userGroupSelectPage']);
    }else{
      // sessionStorage.setItem('%qr',JSON.stringify(globalRolePresent));
      sessionStorage.setItem('%qr',this.enObject(globalRolePresent))
      this.lastLoginTime.next();
      // sessionStorage.setItem('%qrCopy',JSON.stringify(globalRolePresent));
      sessionStorage.setItem('%qrCopy',this.enObject(globalRolePresent))
      sessionStorage.setItem('globalCompanyList',this.enValue(JSON.stringify(globalRolePresent.globalCompanyMasterIdList)));
      this.shareData.next();
      //changes for single company
      let tempRole = this.deObject(sessionStorage.getItem('%qr'));
        tempRole.groupName=globalRolePresent.xtenantId
        tempRole.globalCompanyMasterId =JSON.parse(tempRole.globalCompanyMasterIdList)
        // sessionStorage.setItem('%qr',JSON.stringify(tempRole))
        sessionStorage.setItem('%qr',this.enObject(tempRole))
        this.lastLoginTime.next();
        this.shareCompName.next(globalRolePresent.companyName)
        this.shareRoleName.next(globalRolePresent.roleName)
        sessionStorage.setItem('selectedRole',this.enValue(globalRolePresent.roleName))
      this.router.navigate(['Dashboard']);
    }
    }else if(lastLoginType == 'Admin'){
      sessionStorage.setItem('%rid',this.enValue(JSON.stringify(adminRolePresent.userRoleId)))
      adminRolePresent.xtenantId = adminRolePresent.loginCompanyName
      // sessionStorage.setItem('%qr',JSON.stringify(adminRolePresent));
      sessionStorage.setItem('%qr',this.enObject(adminRolePresent))
      this.lastLoginTime.next();
        // sessionStorage.setItem('%qrCopy',JSON.stringify(adminRolePresent));
        sessionStorage.setItem('%qrCopy',this.enObject(adminRolePresent))
      if(adminRolePresent.globalCompanyMasterIdList.split(',').length>1){
        this.shareRoleName.next(adminRolePresent.roleName)
        sessionStorage.setItem('selectedRole',this.enValue(adminRolePresent.roleName))
        adminRolePresent.globalCompanyMasterId1 = adminRolePresent.globalCompanyMasterId
        this.router.navigate(['/userGroupSelectPage']);
      }else{
        adminRolePresent.globalCompanyMasterId1 = adminRolePresent.globalCompanyMasterId
        // sessionStorage.setItem('%qr',JSON.stringify(adminRolePresent));
        sessionStorage.setItem('%qr',this.enObject(adminRolePresent))
        this.lastLoginTime.next();
        // sessionStorage.setItem('%qrCopy',JSON.stringify(adminRolePresent));
        sessionStorage.setItem('%qrCopy',this.enObject(adminRolePresent))
        // sessionStorage.setItem('globalCompanyList',adminRolePresent.globalCompanyMasterIdList);
        sessionStorage.setItem('globalCompanyList',this.enValue(JSON.stringify(adminRolePresent.globalCompanyMasterId)))
        this.shareData.next();
        //changes for single company
      let tempRole = this.deObject(sessionStorage.getItem('%qr'));
      tempRole.groupName=adminRolePresent.xtenantId
      // sessionStorage.setItem('%qr',JSON.stringify(tempRole))
      sessionStorage.setItem('%qr',this.enObject(tempRole))
      this.lastLoginTime.next();
        this.shareCompName.next(adminRolePresent.companyName)
      this.shareRoleName.next(adminRolePresent.roleName)
      sessionStorage.setItem('selectedRole',this.enValue(adminRolePresent.roleName))
        this.router.navigate(['Dashboard']);
      }
    }
    // this.getDataRoleWise();   
this.alertService.sweetalertMasterSuccess('Login Successful', '');
}

public message: string = '{employeeMasterId: 3452, globalUserMasterId: 81, employeeCode: "3444", fullName: "Umesh Ugale",globalCompanyMasterId:1,globalCompanyMasterIdList:"1",globalUserMasterId:81}';
  public password: string='Pass@1234';
  public encryptedMessage: string='';
  public decryptedMessage: string='';
  // encrypt() {
  //  this.encryptedMessage = CryptoJS.AES.encrypt( this.message.trim(), this.password.trim()).toString();
  //  this.decryptedMessage = CryptoJS.AES.decrypt( this.encryptedMessage,  this.password.trim() ).toString(CryptoJS.enc.Utf8);
  //  console.log('encryptedMessage',this.encryptedMessage +' ' + 'decryptedMessage',this.decryptedMessage )
  // }

  enValue(msg) {
    if(msg == null || msg == undefined || msg == '' || msg == 'null'){
      return msg;
    }else {
    let pass :any = this.getprivileges();
    return CryptoJS.AES.encrypt(msg, pass.jti.trim()).toString();
    }
   }

   deValue(msg) {
    if(msg == null || msg == undefined || msg == '' || msg == 'null'){
      return msg;
    }else {
    let pass :any = this.getprivileges();
    return CryptoJS.AES.decrypt(msg,  pass.jti.trim() ).toString(CryptoJS.enc.Utf8);
    }
   }

   enObject(msg) {
    if(msg == null || msg == undefined || msg == '' || msg == 'null'){
      return msg;
    }else {
    let pass :any = this.getprivileges();
    return CryptoJS.AES.encrypt(JSON.stringify(msg), pass.jti.trim()).toString();
    }
   }

   deObject(msg) {
    if(msg == null || msg == undefined || msg == '' || msg == 'null'){
      return msg;
    }else {
    let pass :any = this.getprivileges();
    return JSON.parse(CryptoJS.AES.decrypt(msg,  pass.jti.trim() ).toString(CryptoJS.enc.Utf8));
    }
   }
  backToParentLogin(){
    let pt :any = sessionStorage.getItem('%pltsso')
        this.doLogoutUser();
        localStorage.clear();
        sessionStorage.clear();
        // this.router.navigate(['/login']);
    //logout end
    sessionStorage.setItem('isemploginbyadmin', JSON.stringify(false))
    sessionStorage.setItem('issso', JSON.stringify(true))
    this.doLoginUser(pt);
    this.backTOParentGetSSOLoginAndRole()
  }
  backTOParentGetSSOLoginAndRole(){
    this.user = this.getprivileges();
          let obj = {
            "ipAddress": null
          }
          this.getAssignedUserRoles(obj).subscribe(res => {
            this.distinctRoles = res.data.results[0];
            let isEmpLoginByAdmin : boolean = JSON.parse(sessionStorage.getItem('isemploginbyadmin'))
            if(isEmpLoginByAdmin){
              for(let i=0;i<this.distinctRoles.length;i++){
                let ind = this.distinctRoles.findIndex(e=> e.type == 'Global' || e.type == 'Admin')
                if(ind != -1){
                  this.distinctRoles.splice(ind,1)
                  i--;
                }
              }
            }
            if (this.distinctRoles.length == 0) {
              // this.isLoader = false
              // this.otpDiv = false
              this.alertService.sweetalertError('Access Denied !');
              this.logout();
            } else {
              this.backToParentAssignedUserRoles()
            }
          }, (error: any) => {
            // this.isLoader = false;
          })
  }
  backToParentAssignedUserRoles() {
    let globalRolePresent = this.distinctRoles.find(x => x.type == 'Global')
    let adminRolePresent = this.distinctRoles.find(x => x.type == 'Admin')
    sessionStorage.setItem('selectedCountry', '')
    sessionStorage.setItem('selectedGroup', '')
    sessionStorage.setItem('selectedRole', '')
    sessionStorage.setItem('selectedCompany', '')
    let empData = this.distinctRoles.find(x => x.type == 'Employee')
    if (empData != undefined) {
      sessionStorage.setItem('empComp', this.enValue(JSON.stringify(empData.xtenantId)))
    }
    if (this.distinctRoles.length == 1) {
      sessionStorage.setItem('%qr', this.enObject(this.distinctRoles[0]))
      sessionStorage.setItem('%qrCopy', this.enObject(this.distinctRoles[0]));
      // sessionStorage.setItem('id', this.distinctRoles[0].userRoleId)
      sessionStorage.setItem('%rid',this.enValue(JSON.stringify(this.distinctRoles[0].userRoleId)))
      localStorage.setItem('employeeMasterId', this.user.UserDetails.employeeMasterId);
      let isEmpLoginByAdminCheck : boolean = JSON.parse(sessionStorage.getItem('isemploginbyadmin'))
      let isSSOCheck : boolean = JSON.parse(sessionStorage.getItem('issso'))
      if(isEmpLoginByAdminCheck == true && isSSOCheck == true){
        this.loadHeader.next(this.distinctRoles[0].roleName)
      }
      this.shareData.next();
      this.router.navigate(['/employee-master/employee-dashboard']);
    }
    else if (globalRolePresent) {
      if (globalRolePresent.globalCompanyMasterIdList.split(',').length > 1) {
        this.shareRoleName.next(globalRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.enValue(globalRolePresent.roleName))
        sessionStorage.setItem('%qr', this.enObject(globalRolePresent));
        sessionStorage.setItem('%qrCopy', this.enObject(globalRolePresent));
        // sessionStorage.setItem('id', globalRolePresent.userRoleId)
        sessionStorage.setItem('%rid',this.enValue(JSON.stringify(globalRolePresent.userRoleId)))
        this.router.navigate(['/userGroupSelectPage']);
      } else {
        sessionStorage.setItem('%qr', this.enObject(globalRolePresent));
        sessionStorage.setItem('%qrCopy', this.enObject(globalRolePresent));
        sessionStorage.setItem('globalCompanyList', this.enValue(JSON.stringify(globalRolePresent.globalCompanyMasterIdList)));
        this.shareData.next();
        //changes for single company
        let tempRole = this.deObject(sessionStorage.getItem('%qr'));
        tempRole.groupName = globalRolePresent.xtenantId
        tempRole.globalCompanyMasterId = JSON.parse(tempRole.globalCompanyMasterIdList)
        sessionStorage.setItem('%qr', this.enObject(tempRole))
        this.shareCompName.next(globalRolePresent.companyName)
        this.shareRoleName.next(globalRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.enValue(globalRolePresent.roleName))
        this.router.navigate(['Dashboard']);
      }
    } else if (adminRolePresent) {
      // sessionStorage.setItem('id', adminRolePresent.userRoleId)
      sessionStorage.setItem('%rid',this.enValue(JSON.stringify(adminRolePresent.userRoleId)))
      sessionStorage.setItem('%qr', this.enObject(adminRolePresent));
      sessionStorage.setItem('%qrCopy', this.enObject(adminRolePresent));
      if (adminRolePresent.globalCompanyMasterIdList.split(',').length > 1) {
        this.shareRoleName.next(adminRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.enValue(adminRolePresent.roleName))
        // this.router.navigate(['/userGroupSelectPage']);
        this.shareData.next();
        this.backToParentEmpMasterMenu.next()
        this.lastLoginTime.next();
      } else {
        sessionStorage.setItem('%qr', this.enObject(adminRolePresent));
        sessionStorage.setItem('%qrCopy', this.enObject(adminRolePresent));
        sessionStorage.setItem('globalCompanyList', this.enValue(JSON.stringify(adminRolePresent.globalCompanyMasterIdList)));
        this.shareData.next();
        //changes for single company
        let tempRole = this.deObject(sessionStorage.getItem('%qr'));
        tempRole.groupName = adminRolePresent.xtenantId
        sessionStorage.setItem('%qr', this.enObject(tempRole))
        this.shareCompName.next(adminRolePresent.companyName)
        this.shareRoleName.next(adminRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.enValue(adminRolePresent.roleName))
        this.router.navigate(['Dashboard']);
        this.shareData.next();
        this.backToParentEmpMasterMenu.next()
        this.lastLoginTime.next();
      }
    }
    // this.getDataRoleWise();

    this.alertService.sweetalertMasterSuccess('Login Successful', '');
  }


/*********************************************************Punch button API */
getpunchbutton(empid):Observable<any>{
  return this.http.get<any>(`${environment.baseUrl8093}LMSPunchInOutResource/GeoMethod/${empid}`); 
}

getpunchindetails(fromDate,empid):Observable<any>{
  return this.http.get<any>(environment.baseUrl8093 +'LMSPunchInOutResource/PunchDate/'+ fromDate +'/'+ empid); 
}
savePunch(data):Observable<any>{
  return this.http.post<any>(environment.baseUrl8093 +'LMSPunchInOutResource/addAll',data)
}
addrsssdetails(lat,long,lat1,long1):Observable<any>{
 
  https://localhost:8093/hrms/v1/LMSPunchInOutResource/getPunchInPunchOutThroughWeb/18.5851377,73.8301322/18.5851377,73.8301322
  return this.http.get<any>(environment.baseUrl8093 +'LMSPunchInOutResource/getPunchInPunchOutThroughWeb/'+ lat.toString()+","+long.toString() +'/'+ lat1.toString()+","+long1.toString()); 
}

}
export class Tokens {
  public jwt: string;
  public refreshToken: string;
}
