import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LmsFormInputService {
  url = environment.baseUrl8093;
  constructor(private http:HttpClient, private authService: AuthService) { }

  // getEmployeeListByGroupId(data):Observable<any>{
  //   return this.http.get<any>(this.apiUrlEmployeeDetails + 'EmployeeMasterLandingPage/getAllDetails');
  // } EmployeeMasterLandingPage/getAllDetails
  // environment.baseUrl8082 + 'EmployeeMasterLandingPage/getAllDetails'
  getEmployeeList():Observable<any>{
    return this.http.get(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getAllDetails');
  }

  addRemark(data){
 
    return this.http.post(environment.baseUrl8093 + 'remark-details/addAll', data)
  }
  getAllLHGSettingARCListByEmpId(id) {
    return this.http.get(environment.baseUrl8093 + 'leaveApplication/getLHGSettingListByEmpId/' + id).pipe(map((res: any) => {
      return res;
    }))
  }
  // getEmpMasterDetails() {
  //   let token = this.authService.getJwtToken()
  //   const headers = new HttpHeaders()
  //    .set('X-Authorization', token);
  //   return this.http.get<any>(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getAllDetails' ,{ headers: headers }  );
  //   }

  // getEmployeeList(){
  //   return this.http.get(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getAllDetails', {headers:{ 'X-TenantId': 'PaysquareDefault'}})
  //   .pipe(map((res: any) =>{
  //     return res;
  //   }))
  // }

  getAWSuppPayrollList(businessCycleId):Observable<any>{
    return this.http.get<any>(environment.baseUrl8086+'business-cycle/getpayrollareaAndCompanyName/' + businessCycleId)
  }
   GetAllEmployeeDetails():Observable<any>{
    return this.http.get(environment.baseUrl8082 +'employee-master');
   }

     //Establishment service
  getEstaDetails() {

    return this.http.get(environment.baseUrl8083 + 'establishment-master/details/', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
    // return this.http.get(environment.baseUrl8083 +'establishment-master/details/');
  }

  getOtherMasterDetails(copmanyId):Observable<any> {
    // return this.httpClient.get(environment.baseUrl8083 + 'job-master-mapping/company/' + copmanyId, { headers: { 'X-TenantId': 'PaysquareDefault' } })
    //   .pipe(map((res: any) => {
    //     return res;
    //   }))
      return this.http.get(environment.baseUrl8083 +'job-master-mapping/company/' +copmanyId);
  }

  //Get position dropdown values API call
  getPositionDD() {

    return this.http.get(environment.baseUrl8083 + 'drop-down/details', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
// search Criteria API
  searchCriteria(data: any) {

    return this.http.post(environment.baseUrl8082 + 'EmployeeSearch/searchEmployees', data, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getEmployeeDetails(data) {
    let formData = new FormData();
    formData.append('requestDTO', JSON.stringify(data))
    return this.http.post<any>(environment.baseUrl8082 + 'EmployeeSearch/searchEmployees', formData).pipe(map((res: any) => {
      return res;
    }))
  }
  getAllEmpByApproval(empid):Observable<any> {
    return this.http.get(this.url + 'leaveApplication/getByApproverId/' + empid).pipe(map((res: any) => {
      return res;
    }))
  }

  manualAppEmpList = new BehaviorSubject([])
  manualAppEmpId = new BehaviorSubject(undefined)
  applicationId = new BehaviorSubject(undefined)
  viewApplication = new BehaviorSubject(undefined)
   armanualsummary=new Subject<any>()
  arManualAppEmpList = new BehaviorSubject([])
  arManualAppEmpId = new BehaviorSubject(undefined)
  arApplicationId = new BehaviorSubject(undefined)

  addFastEntry(docFile: Array<File>, data: any): Observable<any> {

    let formData = new FormData();
    for (let file of docFile) {
      formData.append('attachedFile', file);
    }
    formData.append('leaveApplicationRequestDTO', JSON.stringify(data));
    return this.http.post<any>(this.url + 'leaveApplication/addListofleaveApplication', formData).pipe(map((res: any) => {
      return res;
    }))
  }
  // newMainSummary(data):Observable<any>{
  //   return this.http.post(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getLandingPageSummary', data)
  // }
  newMainSummary(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 + 'leaveApplication/getLandingPageSummaryForLms', data)
  }
  headMasterGroup():Observable<any>{
    return this.http.get(environment.baseUrl8093 + 'headMaster/getAllTenant');
  }
  leaveAdjustmentList = new BehaviorSubject([])
  leaveAdjusmentEditData = new BehaviorSubject([])
  leaveAdjusmentViewData = new BehaviorSubject([])
// leavDonationSummary
  emplSelect = this.leaveAdjustmentList.asObservable();
  leaveEditData=new BehaviorSubject([])
  leaveViewData=new BehaviorSubject([])
  leaveSummarydonation=new Subject<any>()

  RoasterEditData=new BehaviorSubject([])
  RoasterSummary=new Subject<any>()
  setEmp(value:any){
    this.leaveAdjustmentList.next(value);
  }

  getEmpList(){

    return this.leaveAdjustmentList.getValue();
  }

  getPayrollArea(data):Observable<any>{
    return this.http.get(environment.baseUrl8093 + 'LMSLeaveAdjustment/GetEmployeeDetailsForLeaveAdjustment/'+data)
  }
  leaveBalanceDetails(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 + 'LMSLeaveAdjustment/getLeaveBalance',data)
  }
  payrollDetails(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 + 'LMSLeaveAdjustment/GetAllEmployeeDetailsForLeaveAdjustment',data)
  }
  // getAllLHGSettingARCListByEmpId(id) {
  //   return this.http.get(this.url + 'leaveApplication/getLHGSettingARCListByEmpId/' + id).pipe(map((res: any) => {
  //     return res;
  //   }))
  // }
  fastEntryleaveBalanceDetails(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 + 'LMSLeaveAdjustment/getLeaveBalanceForMultipleEmployeeMasterId',data)
  }
  // Leave Encashment
  saveFastEnteryLeaveEncashment(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 +'leaveApplication/addAllEncashmentApplicationForManager',data)
  }
  updateLeaveEncashmentEntery(data):Observable<any>{
    return this.http.put(environment.baseUrl8093 +'leaveApplication/updateEncashmentApplicationForManager',data)
  }
  summaryLeaveEncashment():Observable<any>{
    return this.http.get(environment.baseUrl8093 +'')
  }
  selectedEmpEncashmentList = new BehaviorSubject([])
  selectedRoasterList = new BehaviorSubject([])
  getEncashmentWorkFlowEmployee(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 + 'EmployeeSchemaDetails/GetWorkflowIdForEmployee',data)
  }

  getEncashmentWorkFlowIdAdmin(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 + 'EmployeeSchemaDetails/GetWorkflowIdForAdmin',data)
  }
  getApproverDetails(data) {
    return this.http.post(environment.baseUrl8088 + 'workflowMaster-report/approverDetails', data)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  GetHistory(data){
    return this.http.post(environment.baseUrl8093 + 'leaveApplication/getAllLmsApplicationForManagerHistory',data)
  }
  getAllHeadMaster(data){
    return this.http.post(environment.baseUrl8093 + 'leaveApplication/getAllHeadMasterForLmsApplication',data)
  }
  getLeaveHeadDetails(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 +'leaveApplication/getAllHeadMasterForLmsApplicationEmmutable',data)
  }
  getSummaryEncashment(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 +'leaveApplication/getAllApplicationsForManagerByApplicationType',data)
  }
  encashmentEditData = new BehaviorSubject([])
  encashmentViewData = new BehaviorSubject([])
  encashmentSummaryPage = new Subject<any>()
  manualLeaveSummaryPage = new Subject<any>()
  getEncashmentHistory(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 +'leaveApplication/getAllLmsApplicationForManagerHistory',data)
  }
  cancelEncashmentAppl(data):Observable<any>{
    return this.http.post(environment.baseUrl8093 +'leaveApplication/updateStatusForManager',data)
  }
  // ................punch-in-out ...............
  getEmpPunchInOutDetailsOnRefresh():Observable<any>{
    return this.http.get<any>(environment.baseUrl8093 + 'LMSPunchInOutResource')
  }
  getAllPunchINOut(data):Observable<any>{  
    return this.http.post(environment.baseUrl8093  + 'LMSPunchInOutResource/getAllPunchInOutDataByGroupCompanyId', data)
    .pipe(map((res: any) => {
      return res;
    }));
  }
  getEmpPunchInOutDetailsOnDate(formDate,toDate,empId):Observable<any>{
    return this.http.get(environment.baseUrl8093 + 'LMSPunchInOutResource/date/' + formDate + '/' + toDate + '/' + empId)
  }
  getallpunchfromDate(data):Observable<any>{  
      return this.http.post(environment.baseUrl8093  + 'LMSPunchInOutResource/getAllPunchInOutDataByGroupCompanyIdAndDate', data)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  updateRefetchBiometric(refetchDate):Observable<any>{
    return this.http.get<any>(environment.baseUrl8093 + 'LMSPunchInOutResource/PunchInOut/' + refetchDate)
  }
  getAllattendance(data):Observable<any>{  
        return this.http.post(environment.baseUrl8093  + 'employeeRoasterDetails/getAllEmployeeRoasterDetailsAdminSummary', data)
        .pipe(map((res: any) => {
          return res;
        }));
  }
  // https://test.deliziahr.com:8093/hrms/v1/LMSPunchInOutResource/getAutoShift
getautoshift():Observable<any>{

  return this.http.get<any>(environment.baseUrl8093 + 'LMSPunchInOutResource/getAutoShift' )

}

  /**********************************************Processing**** */
   /** Get All Cycle Definition Data */
   getCycleDefinitionList():Observable<any> {
    // return this.http.post(this.apiUrl + `business-cycle-definition/getCompanyWiseCycle`, data)
     return this.http.get<any>(environment.baseUrl8093  + `cycle-lock-lms-area/get-cycle-defination`)
   }
getCompany(id):Observable<any>{
  // https://test.deliziahr.com:8093/hrms/v1/LMSProcessing/getComapnyList/{businessCycleDefinitionId}
  return this.http.get<any>(environment.baseUrl8093 + 'LMSProcessing/getComapnyList/'+id)
}
getPayrollAreafromcompany(businessid,companyid):Observable<any>{
  http://localhost:8093/hrms/v1/LMSProcessing/getPayrollAreaList/1/189
  return this.http.get<any>(environment.baseUrl8093 + 'LMSProcessing/getPayrollAreaList/'+ businessid + '/' + companyid)
}
getLMSAREA(businessid,companyid,payrollid):Observable<any>{

  return this.http.get<any>(environment.baseUrl8093 + 'LMSProcessing/getLMSAreaList/'+ businessid + '/' + companyid + '/' + payrollid)
}
// getBusinesscycle(businessid,lmsAreaCode){
//   http://localhost:8093/hrms/v1/LMSProcessing/getOpenCycle/{businessCycleDefinitionId}/{lmsAreaCode}
//   return this.http.get(environment.baseUrl8093 + 'LMSProcessing/getOpenCycle/'+ businessid + '/' + lmsAreaCode)
// }
// LMSProcessingPayrollarea/getBusinessCycleForEmployeeTab/110/71/LMS123
getBusinesscycle(businessid,companyid,lmsarea):Observable<any>{
  http://localhost:8093/hrms/v1/LMSProcessing/getOpenCycle/{businessCycleDefinitionId}/{lmsAreaCode}
  return this.http.get<any>(environment.baseUrl8093 + 'LMSProcessingPayrollarea/getBusinessCycleForEmployeeTab/'+ businessid + '/' + companyid + '/' + lmsarea)
}
getemployeeset():Observable<any>{
  return this.http.get<any>(environment.baseUrl8084 + 'EmployeeMaster/getAllEmployeeSet')
// https://test.deliziahr.com:8084/hrms/v1/EmployeeMaster/getAllEmployeeSet
}

getEmployeecode(companyid,payrollcode):Observable<any>{

  return this.http.get<any>(environment.baseUrl8093 +'LMSProcessing/getEmployeeListByPayrollArea/'+ companyid + '/' + payrollcode)
}
// https://test.deliziahr.com:8093/hrms/v1/LMSProcessing/getLMSAreaList/1/189
getLMSAREAformbusinessidandcompanyid(businessCycleDefinitionId,companyid):Observable<any>{
  return this.http.get<any>(environment.baseUrl8093 +'LMSProcessing/getLMSAreaList/'+ businessCycleDefinitionId + '/' + companyid)
}

goButtonforemployee(data):Observable<any>{
  return this.http.post(environment.baseUrl8093  + 'LMSProcessingPayrollarea/getEmployeeListForEmployeeTab', data)
  .pipe(map((res: any) => {
    return res;
  }));
}

processforemployee(data):Observable<any>{
  return this.http.post(environment.baseUrl8093  + 'LMSProcessingTaskDetails/addEmployee', data)
  .pipe(map((res: any) => {
    return res;
  }));
}
/*******************************************LMS Area****** */
getBusinesscycleforlms(businesscycledefinitionid):Observable<any>{
  return this.http.get<any>(environment.baseUrl8093 +'LMSProcessingPayrollarea/getBusinessCycle/'+ businesscycledefinitionid )
}

getLMSareaforlms(businessCycleDefinitionId,companyid):Observable<any>{
  return this.http.get<any>(environment.baseUrl8093 +'LMSProcessingPayrollarea/getLMSArea/'+ businessCycleDefinitionId + '/' + companyid)
}
goButton(data):Observable<any>{
  return this.http.post(environment.baseUrl8093  + 'LMSProcessingPayrollarea/getEmployeeList/', data)
  .pipe(map((res: any) => {
    return res;
  }));
}
process(data):Observable<any>{
  return this.http.post(environment.baseUrl8093  + 'LMSProcessingTaskDetails/add', data)
  .pipe(map((res: any) => {
    return res;
  }));
}
// http://localhost:8093/hrms/v1/LMSProcessingTaskDetails/employeeFound/LMS123
getEmployee(data):Observable<any>{
  return this.http.post<any>(environment.baseUrl8093 +'LMSProcessingTaskDetails/employeeFound', data)
}
getEmp(value: Object): Observable<any> {

  let formData = new FormData();
  formData.append('requestDTO', JSON.stringify(value));
  return this.http.post<any>(`${environment.baseUrl8082}EmployeeSearch/searchEmployees/`, formData);
}
getManualLeaveCompOffData():Observable<any>{
  return this.http.get(environment.baseUrl8093 +'leaveApplication/getAllComoffComputationDatils')
}
getManualLeaveCompOffDatafastEntery(data):Observable<any>{
  return this.http.post(environment.baseUrl8093 +'leaveApplication/getAllComoffComputationDatilsByEmpId',data)
}
}
