<div>
    <div class="section-body mt-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card ht-500">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="rup mt-5">
                                        <div class="row clearfix row d-flex justify-content-center text-bold">
                                            <div *ngFor="let userRole of distinctRoles" class=" col-md-4">
                                                <div class="card  btn-bg-green">
                                                    <div class="card-body">
                                                        <a (click)="rediredToEmployee(userRole)"
                                                            [routerLink]="['/employee-master/employee-dashboard']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fa fa-users"></i>
                                                            <span>{{userRole.roleName}} </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>