<!-- <div class="row">
    <div class="col-md-12"*ngIf="showHelpDoc">
        <div class="float-right mr-3"><span (click)="openPopup(document)" *ngIf="isShowUserGuide">
                <i class="fa-solid fa-file-export text-link" tooltip="User Guide"></i>
                <span class="ml-1 text-link ">User Guide</span>
            </span>
        </div>
    </div>
</div> -->

<div *ngIf="!grandChild" [ngClass]="{'disableLoader':isLoader}">
    <div class="ml-5 section-body mt-3 ">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <div class="">
                            <div class="row">
                                <div class="col-md-2 mr-4" (click)="onClickChildManu(data)"
                                    *ngFor="let data of childMenuData">
                                    <div class="stats-box sales"><i
                                            class=" fa-solid {{data.childIcon}}  menu-icon-css"></i>
                                        <br>
                                        <div class="statistics-header text-center mt-1 cursor-pointer">
                                            {{data.childMenuName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isShowLandingPage"><app-emp-master-landing-page></app-emp-master-landing-page></div>
    <div *ngIf="isShowPayrollList"><app-payroll-list></app-payroll-list></div>
    <div *ngIf="isShowLMSFormInputList"><app-lms-forms-input></app-lms-forms-input></div>
</div>
<div *ngIf="grandChild" [ngClass]="{'disableLoader':isLoader}">
    <div class="ml-5 section-body mt-3 ">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <div class="">
                            <div class="row">
                                <div class="col-md-2 mr-4" (click)="onClickGrandChildManu(child)"
                                    *ngFor="let child of grandChildDataList">
                                    <div class="stats-box sales"><i
                                            class=" fa-solid {{child.grandChildIcon}} menu-icon-css"></i>

                                        <div class="statistics-header text-center mt-1 cursor-pointer">
                                            {{child.grandChildName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #template1>
    <div class="modal-header foot">
        <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple-new font-16 font700">Information
            </span></h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true"><i class="fa-regular fa-circle-xmark text-purple-new"></i></span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body tab">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <h6 class="">
                            <p></p>
                            <p class="font-12">Here Payroll related setting will be done. </p>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer foot">
        <button type="button" (click)="modalRef.hide()"
            class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left ok-btn">
            <b><i class="fa-solid fa-thumbs-up" aria-hidden="true"></i></b> Ok
        </button>
    </div>
</ng-template>

<!-- <ng-template #document>
    <app-help-document-popup [modalRef1]="modalRef" [type]="type"></app-help-document-popup>
</ng-template> -->

<!-- <div *ngIf="isLoader" class="page-loader-wrapper">
    <div class="loader2"></div>
  </div> -->
<div class="page-loader-wrapper" *ngIf="isLoader">
    <div class="loader-1">
        <div class="loader-outter"></div>
        <div class="loader-inner"></div>
    </div>
</div>