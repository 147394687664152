import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { LazyLoadEvent } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { ExcelserviceService } from 'src/app/core/services/excelservice.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { FiltersService } from 'src/app/modules/filters/filters.service';
import { PayrollInputsService } from 'src/app/modules/payroll-inputs/payroll-inputs.service';
import { QueryService } from 'src/app/modules/query/query.service';
export interface User1 {
  checked;
  headtype;
  headcode;
  headdesc;
  openingval;
  chngamount;
  chngper;
  closingamt;
  unitofmeasure;
  remark;
}
@Component({
  selector: 'app-payroll-list',
  templateUrl: './payroll-list.component.html',
  styleUrls: ['./payroll-list.component.scss']
})
export class PayrollListComponent implements OnInit {
  public users: Array<any> = [];
  public checkedEmployeeList: Array<any> = [];
  public modalRef: BsModalRef;
  selectedEmployeeData: any = [];
  generationFormData: any;
  onBehalfValue: any = '';
  sameContentValue: any = '';
  sameContentViewFlag: boolean = false;
  selectedEmpLength = 0
  adhocData: any;
  isadhoc: boolean = false;
  issupplimentary: boolean = false;
  suppData: any;
  AWPayrollData: any;
  excelData: any[] = [];
  displayedColumns: string[] = [];
  employeeList: any = [];
  firstColumnDropdown: any = [];
  selectedEmployeeDataflag: boolean;
  public tabIndex = 0;
  display: boolean = false;
  allFilterSummary: any;
  selectedPayrollAreaCode: any = [];
  allPayrollSameFlag: any = true;
  filterApplyData: any = []
  copyUsers: any = []
  companySessionData: any = []
  globalCompanyMasterIdList:any;
  isArrear:boolean = false
  page: number = 1;
  size: any = 100;
  totalRecords: any;
  pageLinks: any;
  filteredRows: any[];
  displayedRows: any[];
  totalRowsNb: number;
  allrows:any = ['employeeCode','fullName','payrollAreaCode','companyName','employeeType','establishment','department','grade','designation1','globalFilter']
  isFilterApply: boolean = false;
  header: string[];
  payrollListLength: any = [];

  constructor(private service: PayrollInputsService, private router: Router, private modalService: BsModalService,
    private alertService: AlertServiceService, private excelservice: ExcelserviceService,
    public queryService: QueryService, private filterService: FiltersService, public authService: AuthService,
    private datepipe:DatePipe ) {
    // localStorage.clear()
    this.companySessionData = authService.getComapnyFromSession()
    this.globalCompanyMasterIdList = this.companySessionData.globalCompanyMasterId
    localStorage.removeItem('payrollListEmpData')
    this.issupplimentary = false
    this.isadhoc = false
    this.AWPayrollData = []
    this.selectedEmployeeData = []
    if (localStorage.getItem('adhocData') != null) {
      this.adhocData = JSON.parse(localStorage.getItem('adhocData'))
      this.isadhoc = true
      this.isArrear = this.adhocData.arrear
      this.service.getAWSuppPayrollList(this.adhocData.id).subscribe(res => {
        res.data.results.forEach(element => {
          this.AWPayrollData.push(element.payrollAreaCode)
        });
      })
    }
    if (localStorage.getItem('suppData') != null) {
      this.suppData = JSON.parse(localStorage.getItem('suppData'))
      this.issupplimentary = true
      this.isArrear = true
      this.service.getAWSuppPayrollList(this.suppData.id).subscribe(res => {
        res.data.results.forEach(element => {
          this.AWPayrollData.push(element.payrollAreaCode)
        });
      })
    }
    localStorage.removeItem('adminListEmpDataForLoan');
    localStorage.removeItem('selectedEmployeeDataflag');
    localStorage.removeItem('loanApplyData');
    localStorage.removeItem('SDMLoanData')
  }
  public ngOnInit(): void {
    setTimeout(() => {
      this.getAllEmployeeDetails();
    }, 2000);
    this.getAllFilterSummary();
  }

   /** Dynamic Pagination */
   paginate(event: LazyLoadEvent) {
     //console.log(JSON.stringify(event)); 
      //event.first: Index of first record being displayed 
      //event.rows: Number of rows to display in new page 
      //event.page: Index of the new page 
      //event.pageCount: Total number of pages 
  
    let pageIndex = event.first/event.rows + 1 // Index of the new page if event.page not defined.
    this.page = pageIndex;
    this.size = event.rows;   
    

    this.users = []
    let data = {
      "globalCompanyMasterIdList": this.globalCompanyMasterIdList,
      "pageNo": this.page,
      "recordCount": this.size
    }
    this.service.getEmployeeListByGroupId(data).subscribe(res => {
      if (!this.isadhoc && !this.issupplimentary) {
        res.data.results.forEach(element => {
          element.active = false;
        });
        this.users = res.data.results;
        this.copyUsers = res.data.results;
        this.users.forEach(ele=>{
          if(ele.joiningDate != null || ele.joiningDate != ''){
            ele.joiningDate = new Date(ele.joiningDate)
          }

          if(ele.lastWorkingDate != null || ele.lastWorkingDate != ''){
            ele.lastWorkingDate = new Date(ele.lastWorkingDate)
          }
         
        })
      } else {
        this.AWPayrollData.forEach(ele => {
          res.data.results.forEach(element => {
            element.active = false;
            if (ele == element.payrollAreaCode) {
              this.copyUsers.push(element)
            }
          });
        });
        this.users = this.copyUsers
      }

      if (event.sortField !== undefined) { 
        let tableData:any;
        let sortingField = event.sortField
        if(event.sortOrder == 1){
          this.copyUsers.sort((a, b) => a[sortingField].localeCompare(b[sortingField]));
          tableData = this.copyUsers
        }else{
          this.copyUsers.reverse();
          tableData = this.copyUsers
        }
        this.users = []; // empty old row data array
        this.users = tableData; // fill sorted  row data array

        // filter data
        this.filteredRows = this.allrows.filter(row => this.filterField(row, event.filters))
        // sort data
        this.filteredRows.sort((a, b) => this.compareField(a, b, event.sortField) * event.sortOrder);
        this.displayedRows = this.filteredRows.slice(event.first, (event.first + event.rows))
        this.totalRowsNb = this.filteredRows.length;
      }  
    })
  }


  filterField(row, filter) {
    let isInFilter = false;
    let noFilter = true;

    for (var columnName in filter) {
      if (row[columnName] == null) {
        return;
      }
      noFilter = false;
      let rowValue: String = row[columnName].toString().toLowerCase();
      let filterMatchMode: String = filter[columnName].matchMode;
      if (filterMatchMode.includes("contains") && rowValue.includes(filter[columnName].value.toLowerCase())) {
        isInFilter = true;
      } else if (filterMatchMode.includes("startsWith") && rowValue.startsWith(filter[columnName].value.toLowerCase())) {
        isInFilter = true;
      } else if (filterMatchMode.includes("in") && filter[columnName].value.includes(rowValue)) {
        isInFilter = true;
      }
    }

    if (noFilter) { isInFilter = true; }

    return isInFilter;
  }

  compareField(rowA, rowB, field: string): number {
    if (rowA[field] == null) return 1; // on considère les éléments null les plus petits
    if (typeof rowA[field] === 'string') {
      return rowA[field].localeCompare(rowB[field]);
    }
    if (typeof rowA[field] === 'number') {
      if (rowA[field] > rowB[field]) return 1;
      else return -1;
    }
  }
  
  public getAllEmployeeDetails(): void {
    // let json = {
    //   "globalCompanyMasterIdList": this.globalCompanyMasterIdList
    // }
    // this.service.getEmployeeListByGroupIdTotalRecordCount(json).subscribe(res=>{
    //   this.totalRecords = parseInt(res.data.results[0]);
    //   this.pageLinks = Math.round(this.totalRecords / parseInt(this.size))
      this.users = []
      let data = {
        "globalCompanyMasterIdList": this.globalCompanyMasterIdList,
       // "pageNo": this.page,
       // "recordCount": this.size
      }
      this.service.getEmployeeListByGroupId(data).subscribe(res => {
        if (!this.isadhoc && !this.issupplimentary) {
          res.data.results.forEach(element => {
            element.active = false;
            if(element.payrollAreaCode != '' || element.payrollAreaCode != null){
              this.payrollListLength.push(element.payrollAreaCode)
            }
          });
          this.users = res.data.results;
          this.copyUsers = res.data.results;
        } else {
          this.AWPayrollData.forEach(ele => {
            res.data.results.forEach(element => {
              element.active = false;
              if (ele == element.payrollAreaCode) {
                this.copyUsers.push(element)
              }
            });
          });
          this.users = this.copyUsers
        }

        if(this.payrollListLength.length > 0){
          this.payrollListLength = [...new Set(this.payrollListLength)];
          console.log(this.payrollListLength)
        }
      })
   // })
    
  }

  public updateEmployeeSelectedLists(event: any, id: any): void {
    const checked = event.target.checked;
    if (checked) {
      this.checkedEmployeeList.push(id);
    } else {
      this.checkedEmployeeList.forEach((value, index) => {
        if (value === id) { this.checkedEmployeeList.splice(index, 1); }
      });
    }
    this.service.setEmployeeListArray(this.checkedEmployeeList);
  }

  public updateAllEmployeeSelectedLists(event: any): void {
    const checked = event.target.checked;
    this.checkedEmployeeList = [];
    if (checked) {
      this.users.forEach((val) => {
        this.checkedEmployeeList.push(val.employeeMasterId);
      });
    } else {
      this.checkedEmployeeList = [];
    }
    this.service.setEmployeeListArray(this.checkedEmployeeList);
  }

  getAllSelectedEmployee(event){
    if (event.checked) {
      this.users.forEach(ele =>{
        this.selectedEmployeeData.push(ele)
        this.selectedEmpLength = this.selectedEmpLength + 1
        this.selectedPayrollAreaCode.push(ele.payrollAreaId)
      })
    } else {
      this.selectedEmployeeData = []
      this.selectedPayrollAreaCode = []
      this.selectedEmpLength = 0
    }
    if (this.selectedPayrollAreaCode.length > 0) {
      this.allPayrollSameFlag = this.selectedPayrollAreaCode.every(val => val === this.selectedPayrollAreaCode[0])
      if (this.selectedEmployeeData.length > 0) {
        if (this.allPayrollSameFlag) {
          localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
          this.users.forEach(ele =>{
            ele.active = true
          })     
        } else {
          this.alertService.sweetalertWarning("Payroll area is different")
          this.users.forEach(ele =>{ 
            this.selectedEmployeeData.forEach((element, index) => {
              if (element.employeeMasterId == ele.employeeMasterId) {
                let ind = index;
                ele.active = false
                this.selectedEmployeeData.splice(ind, 1)
                this.selectedPayrollAreaCode.splice(ind, 1)
              }
            });
          });  
          localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
        }
      }
    }
  }

  /** get selected employee data */
  getSelectedEmployee(user, event,index) {
    if (event.checked) {
      this.selectedEmployeeData.push(user)
      this.selectedEmpLength = this.selectedEmpLength + 1
      this.selectedPayrollAreaCode.push(user.payrollAreaId)
    } else {
      if (this.selectedEmployeeData.length > 0) {
        this.selectedEmployeeData.forEach((element, index) => {
          if (element.employeeMasterId == user.employeeMasterId) {
            let ind = index;
            this.selectedEmployeeData.splice(ind, 1)
            this.selectedPayrollAreaCode.splice(ind, 1)
          }
        });
      }
      this.selectedEmpLength = this.selectedEmpLength - 1
    }
    if (this.selectedPayrollAreaCode.length > 0) {
      this.allPayrollSameFlag = this.selectedPayrollAreaCode.every(val => val === this.selectedPayrollAreaCode[0])
      if (this.selectedEmployeeData.length > 0) {
        if (this.allPayrollSameFlag) {
          localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
          this.users[index].active = true
        } else {
          this.alertService.sweetalertWarning("Payroll area is different")
          this.users[index].active = false
          this.selectedEmployeeData.forEach((element, index) => {
            if (element.employeeMasterId == user.employeeMasterId) {
              let ind = index;
              this.selectedEmployeeData.splice(ind, 1)
              this.selectedPayrollAreaCode.splice(ind, 1)
            }
          });
          localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
        }
      }
    }
  }

  navigateToNRAmt() {
    if (!this.isadhoc && !this.issupplimentary) {
      if (this.selectedEmployeeData.length > 0) {
        if (this.allPayrollSameFlag) {
          localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
          this.router.navigate(['/PayrollInputs/Non-Recurring-Amount'])
        } else {
          this.alertService.sweetalertWarning("Payroll area is different")
        }
      } else {
        this.router.navigate(['/PayrollInputs/Non-Recurring-Amount'])
      }
    } else {
      if (this.isadhoc || this.issupplimentary) {
        if (this.selectedEmployeeData.length > 0) {
         localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
          this.router.navigate(['/PayrollInputs/Non-Recurring-Amount'])
        } else {
          this.alertService.sweetalertWarning("Please Select Atleast One Employee")
        }
      }
    }
  }

  navigateToReplacement() {
    if (this.selectedEmployeeData.length > 0) {
      if(this.allPayrollSameFlag){
        localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
        this.router.navigate(['/PayrollInputs/replacementtax'])
      }else{
        this.alertService.sweetalertWarning("Payroll area is different")
      }   
    } else {
      this.router.navigate(['/PayrollInputs/replacementtax'])
    }
  }

  navigateToNRQty() {
    if (!this.isadhoc && !this.issupplimentary) {
      if (this.selectedEmployeeData.length > 0) {
        if (this.allPayrollSameFlag) {
          localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
          this.router.navigate(['/PayrollInputs/Non-Recurring-qty'])
        } else {
          this.alertService.sweetalertWarning("Payroll area is different")
        }
      } else {
        this.router.navigate(['/PayrollInputs/Non-Recurring-qty'])
      }
    } else {
      if (this.isadhoc || this.issupplimentary) {
        if (this.selectedEmployeeData.length > 0) {
          localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
          this.router.navigate(['/PayrollInputs/Non-Recurring-qty'])
        } else {
          this.alertService.sweetalertWarning("Please select atleast one employee")
        }
      }
    }
  }

  navigateToGarnishmentApplication() {
    if (this.selectedEmployeeData.length > 0) {
      if (this.allPayrollSameFlag) {
        localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
        this.router.navigate(['/PayrollInputs/Garnishment-Transaction'])
      } else {
        this.alertService.sweetalertWarning("Payroll area is different")
      }
    } else {
      this.router.navigate(['/PayrollInputs/Garnishment-Transaction'])
    }
  }

  navigateToFinancialMaster() {
    if (this.selectedEmployeeData.length > 0) {
      if (this.allPayrollSameFlag) {
        localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
        this.router.navigate(['/PayrollInputs/Financial-Master'])
      } else {
        this.alertService.sweetalertWarning("Payroll area is different")
      }
    } else {
      this.router.navigate(['/PayrollInputs/Financial-Master'])
    }
  }

  /** Excel */
  SummaryexportAsXLSX(): void {
    this.excelData = [];
    this.header = ["Emp.Code", "Emp. Name", "Payroll Area", "Company Name", "Establishment",
			"Department", "Grade", "Designation",]
    this.users.forEach(element => {
      let obj = {
        "Emp.Code": element.employeeCode,
        "Emp. Name": element.fullName,
        "Joining Date": element.joiningDate == null ? '' : this.datepipe.transform(new Date(element.joiningDate),'dd-MMM-yyyy'),
        "Leaving Date": element.lastWorkingDate == null ? '' : this.datepipe.transform(new Date(element.lastWorkingDate),'dd-MMM-yyyy'),
        "Payroll Area": element.payrollAreaCode,
        "Company Name": element.companyName,
       // "Emp. Type": element.employeeType,
        "Establishment": element.establishment,
        "Department": element.department,
        "Grade": element.grade,
        "Designation": element.designation1,
      }
      this.excelData.push(obj)
    });
    this.excelservice.exportAsExcelFile(this.excelData, 'Employee List', 'Employee List', this.header);
  }

  navigateToAttendance() {
    this.router.navigate(['/PayrollInputs/attendance'])
  }
  // navigateToReplacement() {
  //   this.router.navigate(['/PayrollInputs/replacementtax'])
  // }
  navigateToPayrollAsset() {
    this.router.navigate(['/PayrollInputs/Payroll-Asset'])
  }
  employeeListCopyPaste(event, pasteEmployee: TemplateRef<any>) {
    if (event.checked) {
      this.modalRef = this.modalService.show(
        pasteEmployee,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    }
  }
  employeeListPasteData(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let row_data = pastedText.split('\n');
    this.displayedColumns = ["EmpCode"]
    this.employeeList = [];
    for (let i = 0; i < row_data.length; i++) {
      let data = row_data[i].replace('\r', '');
      this.employeeList.push(data)
    }
    let lastIndex = this.employeeList.length - 1
    this.employeeList.splice(lastIndex, 1)
    this.selectedEmployeeData = []
    this.users.forEach(user => {
      this.employeeList.forEach(element => {
        if (user.employeeCode == element) {
          user.active = true;
          this.selectedEmployeeData.push(user)
          this.selectedEmpLength = this.selectedEmpLength + 1
          console.log(this.selectedEmployeeData)
          localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
        }
      });
    })
  }
  // ............................................Add Query....................................................
  navigateToQuery() {
    if (this.selectedEmployeeData.length > 0) {
      localStorage.setItem('queryListEmpData', JSON.stringify(this.selectedEmployeeData))
      this.router.navigate(['/admin-query-generation'])
    } else {
      this.alertService.sweetalertWarning('Please select employee first')
    }
  }
  smallpopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-md' })
    );
  }
  addQueryGeneration() { //post api for saving data
    let data = {
      'onBehalf': this.onBehalfValue,
      'sameContent': this.sameContentValue
    }
    localStorage.setItem('emlpoyeeSelectionData', JSON.stringify(data))
  }
  onBehalf(value) {
    this.onBehalfValue = value;
  }
  sameContent(value) {
    this.sameContentValue = value;
  }
  navigateToLoan() {
    if (this.selectedEmployeeData.length > 0) {
      localStorage.setItem('adminListEmpDataForLoan', JSON.stringify(this.selectedEmployeeData))
      this.router.navigate(['/loan/add-new-loan'])
    } else {
      // this.alertService.sweetalertWarning('Please select employee first')
      this.router.navigate(['/loan/add-new-loan'])
      this.selectedEmployeeDataflag = true;
      localStorage.setItem('selectedEmployeeDataflag', JSON.stringify(this.selectedEmployeeDataflag))
    }
  }
  // ................................journal voucher............................
  navigateTojv() {
    if (this.selectedEmployeeData.length > 0) {
      localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
      this.router.navigate(['/journal-voucher/jvspliting'])
    } else {
      this.router.navigate(['/journal-voucher/jvspliting'])
    }
  }
  // ................................Workflow Master............................
  navigateToWorkflow() {
    if (this.selectedEmployeeData.length > 0) {
      localStorage.setItem('payrollListEmpData', JSON.stringify(this.selectedEmployeeData))
      this.router.navigate(['/workflow/workflow-master-template'])
    } else {
      this.router.navigate(['/workflow/workflow-master-template'])
    }
  }


  /*************************** Filters  Apply *******************************/
  /** Show Filter UI */
  showFilterUI() {
    this.display = !this.display
  }

  /*** get all filters summary data */
  getAllFilterSummary() {
    this.filterService.getAllFiltersSummary().subscribe(res => {
      this.allFilterSummary = res.data.results[0];
    })
  }

  /** After apply filter on side bar  */
  filterEventHander($event: any) {
    this.isFilterApply = false;
    this.filterApplyData = $event;
    this.users = []
    this.copyUsers.forEach(ele =>{
      this.filterApplyData.forEach(element => {
        if(parseInt(ele.employeeMasterId) == parseInt(element.employeeMasterId)){
          this.users.push(ele)
          this.isFilterApply = true; 
        }
      });
    })
  }

  /** Call on Dropdown Selection ****/
  filterApply(value){
    this.users = []
    this.isFilterApply = false; 
    if(value != ''){
      this.filterService.getEmployeeJobinformationData(value).subscribe(res =>{
        this.filterApplyData = res.data.results[0];
        this.isFilterApply = true; 
        this.display=false
        this.filterApplyData.forEach(element => {
          let obj = element;
          this.users.push(obj)
        })
      })
    }else{
      /**  if no filter selected into dropdown then reset summary and display all default data   */
      this.users = this.copyUsers
      this.isFilterApply = false;
    }
    
  }

 
}