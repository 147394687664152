import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { translocoLoader } from './../../core/strategies/transloco.loader';
import { TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule } from '@ngneat/transloco';
import localeGb from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import { AccordionModule } from 'primeng/accordion';     //accordion and accordion tab

registerLocaleData( localeFr, 'fr' );
registerLocaleData( localeGb, 'en-GB' );

import { LeftadminmenuComponent } from './leftadminmenu/leftadminmenu';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ChildmenuComponent } from './childmenu/childmenu.component';
import { GrandchildmenuComponent } from './grandchildmenu/grandchildmenu.component';
import { SharedLayoutRoutingModule } from './sharedlayout-routing.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

// import { LMSModule } from '../lms/lms.module';
import { ShowChildMenuComponent } from './show-child-menu/show-child-menu.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginatorModule } from 'primeng/paginator';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { HelpDocumentPopupComponent } from './help-document-popup/help-document-popup.component';
import { SearchEmployeeService } from './services/payrollInputCommonService/search-employee.service';
import { DateCompareService } from './services/OtherMasterCommonService/date-compare.service';
import { AssetCommonService } from './services/AssetCommonService/asset-common.service';
import { KeyParaService } from './services/KeyParaService/key-para.service';
import { SingleReportSharedService } from './services/singleReport/single-report-shared.service';
import { PayrollAreaMasterComponent } from './sharedcomponent/payroll-area-master/payroll-area-master.component';
import { OtherMasterCommonService } from './services/OtherMasterCommonService/other-master-common.service';
import { LmsFormsInputComponent } from './sharedcomponent/lmsInputForm/lms-forms-input.component';
import { EmpMasterLandingPageComponent } from './sharedcomponent/masterLandingPage/emp-master-landing-page.component';
import { EsopApproverCommonService } from './services/EsopCommonService/esop-approver-common.service';
import { PayrollListComponent } from './sharedcomponent/payroll-list/payroll-list.component';
import { FiltersModule } from '../filters/filters.module';


@NgModule( {
  declarations: [FooterComponent, HeaderComponent, LeftmenuComponent, LeftadminmenuComponent, ChildmenuComponent, 
    GrandchildmenuComponent, BreadcrumbComponent, ShowChildMenuComponent,HelpDocumentPopupComponent,PayrollAreaMasterComponent,
    PayrollAreaMasterComponent,LmsFormsInputComponent,EmpMasterLandingPageComponent,PayrollListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedLayoutRoutingModule,
    CollapseModule.forRoot(), 
    TranslocoModule,
    AccordionModule,
    TooltipModule.forRoot(),
    // LMSModule,
    InputNumberModule,
    TableModule,
    MultiSelectModule,
    PaginatorModule,
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FiltersModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LeftmenuComponent,
    LeftadminmenuComponent,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    ShowChildMenuComponent,
    InputNumberModule,
    TableModule,
    MultiSelectModule,
    BsDatepickerModule,
    PaginatorModule,
    TooltipModule,
    AlertModule,
    ModalModule,
    ChildmenuComponent,
    BsDropdownModule,
    PayrollAreaMasterComponent,
    AccordionModule
  ],
  providers: [ 
    DatePipe,
    SearchEmployeeService,
    DateCompareService,
    AssetCommonService,
    KeyParaService,
    SingleReportSharedService,
    OtherMasterCommonService,
    EsopApproverCommonService,
    translocoLoader, {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: [{ id: 'en', label: 'English' }, { id: 'fr', label: 'French' }],
        listenToLangChange: true,
        reRenderOnLangChange: true,
        defaultLang: 'en',
        fallbackLang: 'fr',

        prodMode: false
      } as TranslocoConfig
    }],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
} )
export class SharedlayoutModule { }