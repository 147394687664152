import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class JobInformationService {
  constructor(private httpClient: HttpClient) { }
  private dataLoad = new Subject<void>();
  dataLoading() {
    return this.dataLoad;
  }
  getSummaryDetails(payrollAreaId, employeeMasterId, summaryType, jobId, jobDetail) {
    let formData: any = new FormData();
    formData.append('payrollAreaId', JSON.stringify(payrollAreaId));
    formData.append('employeeMasterId', employeeMasterId);
    formData.append('summaryType', Number(summaryType));
    formData.append('jobId', jobId);
    formData.append('jobDetail', jobDetail);
    return this.httpClient.post(environment.baseUrl8082 + 'employee-job-information/summary/', formData, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getOtherMasterDetails(copmanyId) {
    return this.httpClient.get(environment.baseUrl8083 + 'job-master-mapping/company/' + copmanyId, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getJobMasterDetails() {
    return this.httpClient.get(environment.baseUrl8083 + 'job-master/', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getJobMasterByType(type) {
    return this.httpClient.get(environment.baseUrl8083 + 'job-master/' + type, { headers: { 'X-Tenantid': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getJobMasterByJobMasterId(jobMasterId) {
    return this.httpClient.get(environment.baseUrl8083 + 'job-master-mapping/master/' + jobMasterId, { headers: { 'X-Tenantid': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getAvailableJobMappingId(employeeMasterId) {
    return this.httpClient.get(environment.baseUrl8082 + 'employee-job-information/isAvailable/' + employeeMasterId, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getAvailablePositionMappingId(employeeMasterId) {
    return this.httpClient.get(environment.baseUrl8082 + 'employee-job-information/isAvailable/' + employeeMasterId, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getAllReportingTo() {
    return this.httpClient.get(environment.baseUrl8082 + 'EmployeeReportingToDetails/getAll', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getPositionDD() {
    return this.httpClient.get(environment.baseUrl8083 + 'drop-down/details', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  postOrganizationDetails(organizationFormModel: any) {
    return this.httpClient.post(environment.baseUrl8082 + 'employee-job-information/', organizationFormModel, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getOrganizationDetails(employeeMasterId, payrollAreaId) {
    let payId = Number(payrollAreaId);
    const formData: FormData = new FormData();
    formData.append('payrollAreaId', JSON.stringify(payId));
    formData.append('employeeMasterId',employeeMasterId);
    return this.httpClient.post(environment.baseUrl8082 + 'employee-job-information/latest/', formData, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getEstaDetails() {
    return this.httpClient.get(environment.baseUrl8083 + 'establishment-master/details/', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  postPositionDetails(positionFormModel: any) {
    return this.httpClient.post(environment.baseUrl8082 + 'position-details', positionFormModel, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getPositionDetails(employeeMasterId, payrollAreaCode) {
    const params = new HttpParams();
    params.set('payrollAreaCode', payrollAreaCode);
    return this.httpClient.get(environment.baseUrl8082 + 'position-details/employeeMasterId/' + employeeMasterId, { headers: { 'X-TenantId': 'PaysquareDefault' }, params })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  postMinimumWagesDetails(MinumumWagesDetailsModel) {
    return this.httpClient.post(environment.baseUrl8082 + 'minimumwages-info', MinumumWagesDetailsModel, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getMinimumWagesDetails(employeeMasterId, payrollAreaCode) {
    const params = new HttpParams()
      .set('payrollAreaCode', payrollAreaCode);
    return this.httpClient.get(environment.baseUrl8082 + 'minimumwages-info/employeeMaster/' + employeeMasterId, { headers: { 'X-TenantId': 'PaysquareDefault' }, params })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getMinimumDropdown() {
    return this.httpClient.get(environment.baseUrl8082 + 'minimumwages-dropdown/details', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  postProjectDetails(ProjectDetailsModel) {
    return this.httpClient.post(environment.baseUrl8082 + 'project-details', ProjectDetailsModel, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getProjectDetails(employeeProjectDetailId, payrollAreaCode) {
    const params = new HttpParams()
      .set('payrollAreaCode', payrollAreaCode);
    return this.httpClient.get(environment.baseUrl8082 + 'project-details/employeeMaster/' + employeeProjectDetailId, { headers: { 'X-TenantId': 'PaysquareDefault' }, params })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getAllEmployees(companyId) {
    return this.httpClient.get(environment.baseUrl8082 + 'employee-master/approver/' + companyId, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  postRepotingToDetails(data: any) {
    return this.httpClient.post(environment.baseUrl8082 + 'EmployeeReportingToDetails/AddReport', data, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }),
        tap(() => {
          this.dataLoad.next();
        }))
  }
  searchCriteria(data: any) {
    return this.httpClient.post(environment.baseUrl8082 + 'EmployeeSearch/searchEmployees', data, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  postEmployeeSearch(employeeId) {
    return this.httpClient.get(environment.baseUrl8082 +
      'employment-info/joining/employeeMasterId/' + employeeId, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getAllApproverDetails(employeeMasterId: any) {
    return this.httpClient.get(environment.baseUrl8082 + 'EmployeeReportingToDetails/GetByemployeeMasterId/' + employeeMasterId, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }
  updateApproverDetails(data: any) {
    return this.httpClient.put<any>(environment.baseUrl8082 + 'EmployeeReportingToDetails/UpdateReport', data, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }),
        tap(() => {
          this.dataLoad.next();
        }));
  }
  getEmployeeSummaryInformation(body) {
    return this.httpClient.get(environment.baseUrl8082 + 'EmployeeMasterLandingPage/getAllDetails', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }

  /**
   * 
   * Minimum Eages new requirment 28-07-2023
   * 
   */
  getMinimumWageFields(establismentMasterId) {
    // http://localhost:8082/hrms/v1/education/getMinimumWagesSettings/4
    return this.httpClient.get(environment.baseUrl8082 + 'education/getMinimumWagesSettings/' + establismentMasterId)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getMinimumWageFieldsDropDownValue(establismentMasterId) {
    // http://localhost:8082/hrms/v1/education/getMinimumWagesDropDown/92

    return this.httpClient.get(environment.baseUrl8082 + 'education/getMinimumWagesDropDown/' + establismentMasterId)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  // http://localhost:8082/hrms/v1/minimumWages/Save
  postMinimumWages(data: any) {
    return this.httpClient.post(environment.baseUrl8082 + 'minimumWages/minimumWagesEmployeeJobInfo_Splitting/Save', data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  // http://localhost:8082/hrms/v1/minimumWages/Save
  getMinimumWages(data: any) {
    return this.httpClient.post(environment.baseUrl8082 + 'minimumWages/getByEstablishmentIdAndEmpId', data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getMinimumWageData(data) {
    // http://localhost:8082/hrms/v1/education/getMinimumWagesDropDown/92
    // https://dev.deliziahr.com:8082/hrms/v1/minimumWages/getByEstablishmentAndEmpId
    return this.httpClient.post(environment.baseUrl8082 + 'minimumWages/getByEstablishmentAndEmpId',data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
}

