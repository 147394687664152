<!-- Main body part -->
<div>

    <div class="section-body mt-3">
         <div class="container-fluid">
            <div class="">
                <div class="row">
                    <div class="col-12">
                        <form class="card" role="form" [formGroup]="lmsInputForm" #formDirective="ngForm" method="post" novalidate>
                        <div class="card">
                            <div class="card-body">
                                <!-- <div class="row new-row-background"> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/roaster-updation']"
                                    (click)="getLeaveAdjustment()">
                                        <div class="stats-box sales"><i class="fa-solid fa-list-ul menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Roster
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/manual-leave-updation']"
                                        (click)="getManualAppEmp()">
                                        <div class="stats-box sales"><i
                                                class="fa-solid fa-calendar-day menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Manual Leave
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4"
                                        [routerLink]="['/lms/lms-input-form-attendance-regularization']"
                                        (click)="getArManualAppEmp()">
                                        <div class="stats-box sales"><i
                                                class="fa-solid fa-user-clock menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Atten. Regularization
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/lms-leave-adjustment-form']"
                                        (click)="getLeaveAdjustment()">
                                        <div class="stats-box sales"><i class="fa-solid fa-sliders menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Leave Adjustment
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/optional-holiday-form']">
                                        <div class="stats-box sales"><i class="fa-solid fa-umbrella menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Optional Holidays
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/lms-input-form-leave-donation']"
                                        (click)="getLeaveAdjustment()">
                                        <div class="stats-box sales"><i
                                                class="fa-solid fa-hand-holding-dollar menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Leave Donation
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/lms-input-form-leave-encashment']"
                                        (click)="getEncashment()">
                                        <div class="stats-box sales"><i
                                                class="fa-solid fa-file-invoice-dollar menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Leave Encashment
                                            </div>
                                        </div>
                                    </div> -->
                                     <!-- <div class="col-md-3">
                                        <div
                                            class="card align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/manual-leave-updation']"
                                                    href="javascript:void(0)" class=" " (click)="getManualAppEmp()">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Manual Leave
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> -->
                                    <!-- </div> -->
                                    <!-- <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/lms-input-form-attendance-regularization']"
                                                    href="javascript:void(0)" (click)="getArManualAppEmp()">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Atten. Regularization
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/lms-leave-adjustment-form']"
                                                    href="javascript:void(0)" class="" (click)="getLeaveAdjustment()">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Leave Adjustment
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> -->
                                    <!-- </div> -->
                                    <!-- <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/optional-holiday-form']"
                                                    href="javascript:void(0)">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Optional Holidays
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a (click)="getLeaveAdjustment()"
                                                    [routerLink]="['/lms/lms-input-form-leave-donation']"
                                                    href="javascript:void(0)">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Leave Donation
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/lms-input-form-leave-encashment']"
                                                    href="javascript:void(0)" (click)="getEncashment()">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Leave Encashment
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div> 
                                 </div> -->
                                <div class="row mt-25">
                                    <div class="col-md-12">
                                        <div class="table-responsive Custom_Table" formArrayName="lmsInputFormArray">
                                            <div class="table-responsive Custom_Table">
                                            <p-table #dt1 [value]="lmsInputArray.controls" [paginator]="true" [rows]="10"
                                                [showCurrentPageReport]="true"
                                                [rowsPerPageOptions]="[10,25,50,100,200]"
                                                totalRecords="{{lmsInputArray.controls.length}}"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"                                              
                                                [globalFilterFields]="['controls.isChecked.value','controls.employeeCode.value','controls.fullName.value','controls.payrollAreaCode.value','controls.companyName.value','controls.establishment.value',
                                                'controls.employeeType.value','controls.grade.value','controls.department.value','controls.designation1.value','controls.lmsAreaCode.value']">
                                                <ng-template pTemplate="caption">
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <label
                                                                class="custom-control custom-checkbox checkbox-center">
                                                                <input type="checkbox"
                                                                    (click)="employeeListCopyPaste($event.target,pasteEmployee)"
                                                                    name="example-inline-radios"
                                                                    class="custom-control-input" />
                                                                <span class="custom-control-label text-black">Employee
                                                                    List</span>
                                                            </label>

                                                        </div>
                                                        <div class="col-md-9">
                                                            <div class="float-right">
                                                                <div class="d-flex">
                                                                     <i class=" fas fa-filter text-purple mr-2 mt-1  font-24"></i> 
                                                                     <span class="p-input-icon-left p-ml-auto">
                                                                        <i class="pi pi-search search-css"></i>
                                                                        <input pInputText type="text"
                                                                            (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                                                            placeholder="Search" />
                                                                    </span>
                                                                    <i class="fa fa-file-excel-o excel-icon-css" (click)="exportToExcel()"></i> 
                                                                 </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </ng-template>

                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th class="text-center">
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Emp. Code
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.employeeCode.value"
                                                                        pSortableColumn="controls.employeeCode.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.employeeCode.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Emp. Name
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.fullName.value"
                                                                        pSortableColumn="controls.fullName.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.fullName.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Payroll Area
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.payrollAreaCode.value"
                                                                        pSortableColumn="controls.payrollAreaCode.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.payrollAreaCode.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                LMS Area
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.lmsAreaCode.value"
                                                                        pSortableColumn="controls.lmsAreaCode.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.lmsAreaCode.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th> 
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Company Name
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.companyName.value"
                                                                        pSortableColumn="controls.companyName.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.companyName.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Emp. Type
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.employeeType.value"
                                                                        pSortableColumn="controls.employeeType.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.employeeType.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Establishment
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.establishment.value"
                                                                        pSortableColumn="controls.establishment.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.establishment.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Department
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.department.value"
                                                                        pSortableColumn="controls.department.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.department.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Grade
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.grade.value"
                                                                        pSortableColumn="controls.grade.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.grade.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Designation
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="controls.designation1.value"
                                                                        pSortableColumn="controls.designation1.value"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="controls.designation1.value"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-user1 let-rowIndex="rowIndex">
                                                    <!-- <tr class="p-selectable-row"> -->
                                                        <tr [formGroupName]="rowIndex" class="p-selectable-row">
                                                        <td class="text-center">
                                                                <!-- <input type="checkbox" name='select' formControlName="isChecked"
                                                                (change)="selectSingleEmp($event.target.checked,user1.controls.employeeMasterId.value,rowIndex,user1.controls.lmsAreaCode.value,user1)"
                                                                [checked]="user1.isChecked"> -->
                                                                <input type="checkbox" name='select' formControlName="isChecked" class="form-control"
                                                                [checked]="user1.controls.employeeMasterId.value"
                                                                (click)="selectSingleEmp($event.target.checked,user1.controls.employeeMasterId.value,rowIndex,user1.controls.lmsAreaCode.value,user1)"
                                                                >
                                                        </td>
                                                        <td class="text-center">
                                                            <!-- {{user1.employeeCode}} -->
                                                            {{user1.controls.employeeCode.value}}
                                                        </td>
                                                        <td class="text-left">
                                                            <!-- {{user1.fullName}} -->
                                                            {{user1.controls.fullName.value}}
                                                        </td>
                                                        <td class="text-left">
                                                            <!-- {{user1.payrollAreaCode}} -->
                                                            {{user1.controls.payrollAreaCode.value}}
                                                        </td>
                                                        <td class="text-left">
                                                            <!-- {{user1.lmsAreaCode}} -->
                                                            {{user1.controls.lmsAreaCode.value}}
                                                        </td>
                                                        
                                                        <td class="text-center">
                                                            <!-- {{user1.companyName}} -->
                                                            {{user1.controls.companyName.value}}
                                                        </td>
                                                        <td class="text-center">
                                                            <!-- {{user1.employeeType}} -->
                                                            {{user1.controls.employeeType.value}}
                                                        </td>
                                                        <td class="text-left">
                                                            <!-- {{user1.establishment}} -->
                                                            {{user1.controls.establishment.value}}
                                                        </td>
                                                        <td class="text-left">
                                                            <!-- {{user1.department}} -->
                                                            {{user1.controls.department.value}}
                                                        </td>
                                                        <td class="text-left">
                                                            <!-- {{user1.grade}} -->
                                                            {{user1.controls.grade.value}}
                                                        </td>
                                                        <td class="text-left">
                                                            <!-- {{user1.designation1}} -->
                                                            {{user1.controls.designation1.value}}
                                                        </td>
                                                    </tr>
                                                </ng-template>

                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="15" class="font-14 font500 text-center text-red">
                                                            <span>No
                                                                Data
                                                                Available</span>
                                                        </td>
                                                    </tr>
                                                 </ng-template> 
                                            </p-table>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
    <div class="page-loader-wrapper" *ngIf="isLoader">
        <div class="loader-1">
            <div class="loader-outter"></div>
            <div class="loader-inner"></div>
        </div>
    </div>
    <ng-template #pasteEmployee>
        <div class="modal-header">
            <h5 class="modal-title" id="UploadModalLabel">Employee List</h5>
            <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
            <div class="card">
                <div class="card-body">
                    <div class="row  ">
                        <div class="col-md-12">
                            <textarea (paste)="employeeListPasteData($event)" rows="3" cols="60"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Proceed</button>
            <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
        </div>
    </ng-template>