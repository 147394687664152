import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-psadmin-component-selection',
  templateUrl: './psadmin-component-selection.component.html',
  styleUrls: ['./psadmin-component-selection.component.scss']
})
export class PsadminComponentSelectionComponent implements OnInit {
  distinctRoles: Array<any>=[];
  globalUser: boolean;

  constructor( private service: AuthService,private router:Router) { }

  ngOnInit(): void {
    let obj={
      "ipAddress":sessionStorage.getItem('qrip')
     }
    this.service.getAssignedUserRoles(obj).subscribe(res=>{   
      this.distinctRoles = res.data.results[0]
    },(err)=>{
      err.error.status.message
    }) 
  }

  rediredToEmployee(role){
    // sessionStorage.setItem('%qr',JSON.stringify(role))
    sessionStorage.setItem('%qr',this.service.enObject(role))
    // sessionStorage.setItem('%qrCopy',JSON.stringify(role));
    sessionStorage.setItem('%qrCopy',this.service.enObject(role))
      sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(role.userRoleId)))
  if(role.type=='Employee'){
    this.service.shareData.next(role);
    this.router.navigate(['/employee-master/employee-dashboard']);
  }else if(role.type=='Global'){ 
    let companies  = role.globalCompanyMasterIdList.split(',');
    if(companies.length>1){
    this.service.shareData.next(role) 
    this.router.navigate(['/userGroupSelectPage']); 
    }else{
      this.service.shareData.next(role) 
      this.router.navigate(['/dashboard']);  
    }
  }else {
    //admin access 
    let companies  = role.globalCompanyMasterIdList.split(',');
    if(companies.length>1){
      this.service.shareData.next(role) 
      this.router.navigate(['/userGroupSelectPage']); 
    }else{
    this.service.shareData.next(role) 
    this.router.navigate(['/dashboard']);     
    }
  }
  }  
}
