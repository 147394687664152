import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExcelserviceService } from '../../../excel_service/excelservice.service';
import { Router } from '@angular/router';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { FiltersService } from 'src/app/modules/filters/filters.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { LazyLoadEvent } from 'primeng/api';
import { HelpDocumentService } from 'src/app/common-shared/help-document/help-document.service';
import { EmpMasterLandingPageServiceService } from 'src/app/modules/employee-master/components/emp-master-landing-page/emp-master-landing-page-service.service';
import { SharedInformationService } from 'src/app/modules/employee-master/employee-master-services/shared-service/shared-information.service';
@Component({
  selector: 'app-emp-master-landing-page',
  templateUrl: './emp-master-landing-page.component.html',
  styleUrls: ['./emp-master-landing-page.component.scss']
})
export class EmpMasterLandingPageComponent implements OnInit {
  public modalRef: BsModalRef;
  type: any = {
    "category": "Employee Master",
    "tableName": "AdminLandingPage",
    "tableId": 2
  }
  showHelpDoc: boolean = false;
  isrental: boolean = false;
  countSummary: any[];
  inputData: Array<any> = [];
  groupCompanyId: any;
  EmployeeSummary: any;
  EmployeeSummary1: Array<any> = [];
  selectedEmployeeData: any = [];
  excelData: any[];
  header: any[];
  @Output() employeeData = new EventEmitter<any>();
  onBehalfValue: any;
  disableRejoin: boolean = true;
  @ViewChild('e') e: ElementRef;
  visibleButtons: boolean = true;
  // type: String = 'Employee Master/0'
  /** Dynamic Page */
  page: number = 1;
  size: any = 100;
  totalRecords: any;
  pageLinks: any;
  filteredRows: any[];
  displayedRows: any[];
  totalRowsNb: number;
  allrows: any = ['employeeCode', 'fullName', 'officialMobileNumber', 'joiningDate', 'lastWorkingDate', 'status', 'establishment', 'department', 'grade', 'designation1', 'globalFilter']
  globalCompanyMasterIdList: string;
  createLoginDisable: boolean = false;
  /** Filters -  sidebar */
  copyEmployeeSummary: any = []
  isDisplayFilter: boolean = false;
  filterApplyData: any = []
  allFilterSummary: any;
  employeeMasterId: any;
  companyId: any;
  isPermissable: boolean = false;
  isLoader: boolean = true;
  pastedData: string;
  isSelection: any;
  constructor(private modalService: BsModalService, private employeelandingPageService: EmpMasterLandingPageServiceService, private datePipe: DatePipe,
    private excelservice: ExcelserviceService, private router: Router, private alertService: AlertServiceService, private filterService: FiltersService,
    private authService: AuthService, private sharedInformationService: SharedInformationService, private commonPopup: HelpDocumentService
  ) { }
  ngOnInit(): void {
    this.selectedEmployeeData = [];
    localStorage.clear();
    this.countSummary = [];
    this.groupCompanyId = 1;
    this.inputData.push({ groupCompanyId: this.groupCompanyId })
    let company = this.authService.deValue(sessionStorage.getItem('globalCompanyList'));
    let commaSepratedList;
    company = company.split(',').length === 1 ? JSON.parse(company) : company;
    company.split(',').forEach((x, index) => {
      if (index === 0) {
        commaSepratedList = x;
      } else {
        commaSepratedList = x + ',' + commaSepratedList;
      }
    });

    // let globalComopanyList = JSON.parse(this.authService.deValue(sessionStorage.getItem('globalCompanyList')));
    this.globalCompanyMasterIdList = commaSepratedList;
    const logInTypeData = this.authService.deObject(sessionStorage.getItem('%qr'));
    if (logInTypeData.type === 'Employee') {
      this.visibleButtons = true;
    } else {
      this.visibleButtons = false;
    }

    const data = {
      "globalCompanyMasterIdList": commaSepratedList,
      // "pageNo": this.page,
      //"recordCount": this.size 
    }
    try {
      this.employeelandingPageService.getEmployeeSummaryInformation(data).subscribe(res => {
        this.EmployeeSummary1 = []
        this.EmployeeSummary = []
        res.data.results.forEach(ele => {
          ele.joiningDate = this.datePipe.transform(ele.joiningDate, 'dd-MMM-yyyy');
          ele.reJoiningDate = ele.reJoiningDate == null ? '' : this.datePipe.transform(ele.reJoiningDate, 'dd-MMM-yyyy');
          ele.lastWorkingDate = this.datePipe.transform(ele.lastWorkingDate, 'dd-MMM-yyyy');
          ele.approval = 'Approved'
          let alreadyAvailable = this.EmployeeSummary1.find(x => x.employeeMasterId == ele.employeeMasterId);
          if (!alreadyAvailable) {
            this.EmployeeSummary1.push(ele)
          }
          ele.checked = false;
          this.EmployeeSummary.push(ele)
        })
        this.copyEmployeeSummary = res.data.results;
        // this.EmployeeSummary.forEach(element => {
        //   element.joiningDate = this.datePipe.transform(element.joiningDate, 'dd-MMM-yyyy');
        //   element.reJoiningDate = element.reJoiningDate == null ? '' : this.datePipe.transform(element.reJoiningDate, 'dd-MMM-yyyy');
        //   element.lastWorkingDate = this.datePipe.transform(element.lastWorkingDate, 'dd-MMM-yyyy');
        //   let alreadyAvailable = this.EmployeeSummary1.find(x => x.employeeMasterId == element.employeeMasterId);
        //   if (!alreadyAvailable) {
        //     this.EmployeeSummary1.push(element)
        //   }
        // });
        if (sessionStorage.getItem('editEmployeeMasterId')) {
          let employeeMasterId = this.authService.deValue(sessionStorage.getItem('editEmployeeMasterId'));
          let data = this.EmployeeSummary.find(x => x.employeeMasterId == employeeMasterId)
          //this.getSelectedEmployee(data, true);
          this.clearSession();
        }
        this.isLoader = false;
      }, (error) => {
        this.isLoader = false;
      }
      )
    } catch {
      this.isLoader = false;
    }
    //})  
    this.getAllFilterSummary();
    this.authService.getEmpLoginPermissableForAdmin().subscribe((res: any) => {
      if (res.data.results[0] == 'Permissable') {
        this.isPermissable = true;
      } else {
        this.isPermissable = false;
      }
    })
    this.getData();
  }
  async getData() {
    await this.commonPopup.getHelpDocument(this.type).toPromise().then(success => {
      this.showHelpDoc = success.data.results[0].length > 0 ? true : false;
    }).catch(error => {
      this.showHelpDoc = false;
    })
  }
  // openPopup(document: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(
  //     document
  //     , Object.assign({}, { class: "gray modal-md" })
  //   )
  //   this.commonPopup.openPopup();
  // }
  Emplist(emplist: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      emplist,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }
  getSelectedEmployee(user, checked) {
    user.checked = checked;
    const data = this.EmployeeSummary.findIndex(x => x.employeeMasterId == user.employeeMasterId);
    if (checked == true) {
      const index = this.selectedEmployeeData.findIndex(x => x.employeeMasterId == user.employeeMasterId)
      this.EmployeeSummary.forEach((x) => {
        if (x.employeeMasterId == user.employeeMasterId) {
          x.checked = true
        }
      })
      if (index < 0) {
        user.checked = true;
        this.selectedEmployeeData.push(user);
      } else {
        this.editEmployee('')
        this.clearSession()
        this.selectedEmployeeData.splice(index, 1);
      }
      if (this.selectedEmployeeData.length == 1) {
        // sessionStorage.setItem('editEmployeeMasterId', user.employeeMasterId);
        sessionStorage.setItem('editEmployeeJoiningDate', this.authService.enValue(typeof user.joiningDate !== 'string' ? String(user.joiningDate) : user.joiningDate));
        // sessionStorage.setItem('editEmployeeMasterId', this.authService.enValue(typeof user.userId !== 'string' ? String(user.userId) : user.userId))
        sessionStorage.setItem('editEmployeeMasterId', this.authService.enValue(typeof user.employeeMasterId !== 'string' ? String(user.employeeMasterId) : user.employeeMasterId))
        sessionStorage.setItem('editEmployeeFullName', this.authService.enValue(typeof user.fullName !== 'string' ? String(user.fullName) : user.fullName));
        sessionStorage.setItem('editEmployeeGroupCompanyId', this.authService.enValue(typeof user.groupCompanyId !== 'string' ? String(user.groupCompanyId) : user.groupCompanyId));
        sessionStorage.setItem('editEmployeePayrollAreaCode', this.authService.enValue(typeof user.payrollAreaCode !== 'string' ? String(user.payrollAreaCode) : user.payrollAreaCode));
        localStorage.setItem('employeeMasterId', user.employeeMasterId);
        sessionStorage.setItem('editEmployeeEmployeeCode', this.authService.enValue(typeof user.employeeCode !== 'string' ? String(user.employeeCode) : user.employeeCode));
        sessionStorage.setItem('editEmployeePayrollAreaId', this.authService.enValue(typeof user.payrollAreaId !== 'string' ? String(user.payrollAreaId) : user.payrollAreaId));
        sessionStorage.setItem('editEmployeePayrollAreaCode', this.authService.enValue(typeof user.payrollAreaCode !== 'string' ? String(user.payrollAreaCode) : user.payrollAreaCode));
        sessionStorage.setItem('birthDate', this.authService.enValue(typeof user.dateOfBirth !== 'string' ? String(user.dateOfBirth) : user.dateOfBirth));
        if (user.lastWorkingDate) {
          this.disableRejoin = false
        } else {
          this.disableRejoin = true
        }
        this.editEmployee(user)
      } else {
        this.clearSession()
        this.editEmployee('')
      }
    } else {
      this.EmployeeSummary.forEach((x) => {
        if (x.employeeMasterId == user.employeeMasterId) {
          x.checked = false
        }
      })
      this.selectedEmployeeData.splice(user, 1);
      this.clearSession()
    }
  }
  exportAsXLSX(): void {
    this.excelData = [];
    this.header = []
    this.header = ["Emp.Code", "Employee  Name", "Company Name", "Mobile Number", "Joining Date", "ReJoining Date", "Leaving Date", "Status", "Payroll Area", "Establishment",
      "Department", "Grade", "Designation",]
    this.EmployeeSummary.forEach(element => {
      let obj = {
        "Emp.Code": element.employeeCode,
        "Employee  Name": element.fullName,
        "Company Name": element.companyName,
        "Mobile Number": element.officialMobileNumber,
        "Joining Date": element.joiningDate != null ? new Date(element.joiningDate) : element.joiningDate,
        "ReJoining Date": element.reJoiningDate != null && element.reJoiningDate != undefined && element.reJoiningDate != "" ? new Date(element.reJoiningDate) : '',
        "Leaving Date": element.lastWorkingDate != null ? new Date(element.lastWorkingDate) : element.lastWorkingDate,
        "Status": element.status,
        "Payroll Area": element.payrollAreaCode,
        "Establishment": element.establishment,
        "Department": element.department,
        "Grade": element.grade,
        "Designation": element.designation1
      }
      this.excelData.push(obj)
    });
    this.excelservice.exportAsExcelFile(this.excelData, 'Employee Summary', 'Employee Summary', this.header);
  }
  editEmployee(user1) {
    // sessionStorage.setItem('editEmployeeMasterId', user1.employeeMasterId);
    sessionStorage.setItem('editEmployeeMasterId', this.authService.enValue(typeof user1.employeeMasterId !== 'string' ? String(user1.employeeMasterId) : user1.employeeMasterId))
    sessionStorage.setItem('viewMode', 'false');
    this.employeelandingPageService.getEmployeeData(user1);
  }
  navigateToQuery() {
    localStorage.setItem('queryListEmpData', JSON.stringify(this.selectedEmployeeData))
    this.router.navigate(['/admin-query-generation'])
  }
  navigateToForm() {
    this.sharedInformationService.routingLink();
    this.router.navigate([this.sharedInformationService.routingLinkSequence[0].childObj[1].childRouterLink])
  }
  smallpopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-md' })
    );
  }
  addNewEmployee() {
    this.employeelandingPageService.deleteSharedEmployeeData();
    localStorage.clear();
    sessionStorage.setItem('transferId', null);
    sessionStorage.removeItem('editEmployeeMasterId')
    sessionStorage.removeItem('viewMode')
  }
  onBehalf(value) {
    this.onBehalfValue = value;
  }
  createLogin(employeeList, type) {
    this.createLoginDisable = true;
    if (employeeList.length === this.EmployeeSummary1.length) {
      this.employeelandingPageService.createLoginAll().subscribe((res) => {
        this.alertService.sweetalertMasterSuccess(res.status.messsage, '')
        let data = this.EmployeeSummary1.map(x => x.employeeMasterId);
        const obj1 = {
          "employeeMasterIdList": data,
          "category": "Greetings",
          "event": "Welcome"
        }
        this.employeelandingPageService.sendWelcomeMailToEmail(obj1).subscribe((res) => {
          this.createLoginDisable = false;
          this.alertService.sweetalertMasterSuccess(res.status.messsage, '')
        }, error => {
          this.createLoginDisable = false;
          this.alertService.sweetalertError(error['error']['status']['messsage'])
        })
      }, error => {
        this.createLoginDisable = false;
        this.alertService.sweetalertError(error['error']['status']['messsage'])
      })
    } else {
      let data = this.selectedEmployeeData.map(x => x.employeeMasterId);
      const obj = {
        "employeeMasterList": data,
        "loginType": type
      }
      this.employeelandingPageService.createLoginSelected(obj).subscribe((res) => {
        this.alertService.sweetalertMasterSuccess(res.status.messsage, '')
        const obj1 = {
          "employeeMasterIdList": data,
          "category": "Greetings",
          "event": "Welcome"
        }
        this.employeelandingPageService.sendWelcomeMailToEmail(obj1).subscribe((res) => {
          this.createLoginDisable = false;
          this.alertService.sweetalertMasterSuccess(res.status.messsage, '')
        }, error => {
          this.createLoginDisable = false;
          this.alertService.sweetalertError(error['error']['status']['messsage'])
        })
      }, error => {
        this.createLoginDisable = false;
        this.alertService.sweetalertError(error['error']['status']['messsage'])
      })
    }
  }
  getSelectedEmployee1(event) {
    sessionStorage.setItem('viewMode', 'false')
    if (event) {
      this.EmployeeSummary.forEach(
        a => {
          let alreadyAvailable = this.selectedEmployeeData.find(x => x.employeeMasterId == a.employeeMasterId);
          if (!alreadyAvailable) {
            this.selectedEmployeeData.push(a)
          }
        });
    } else {
      this.selectedEmployeeData = [];
    }
  }
  onClickReJoine() {
    localStorage.setItem('employeeMasterId', this.selectedEmployeeData[0].employeeMasterId)
  }
  viewEmployee(user1: any) {
    this.editEmployee(user1)
    sessionStorage.setItem('viewMode', 'true')
  }
  clearSession() {
    sessionStorage.removeItem('editEmployeeMasterId')
    sessionStorage.removeItem('editEmployeeJoiningDate')
    sessionStorage.removeItem('editEmployeeFullName')
    sessionStorage.removeItem('editEmployeeGroupCompanyId')
    sessionStorage.removeItem('birthDate');
    sessionStorage.removeItem('editEmployeeMasterEmployeeExitInfoId')
    sessionStorage.removeItem('editEmployeePayrollAreaId');
    sessionStorage.removeItem('editEmployeePayrollAreaCode');
    this.disableRejoin = true;
    localStorage.removeItem('employeeMasterId');
    sessionStorage.removeItem('editEmployeeEmployeeCode')
    sessionStorage.removeItem('jobInformationPayrollAreaCode')
    sessionStorage.removeItem('jobInformationCompanyName')
  }
  /** Dynamic Pagination */
  paginate(event: LazyLoadEvent) {
    let pageIndex = event.first / event.rows + 1
    this.page = pageIndex;
    this.size = event.rows;
    let data = {
      "globalCompanyMasterIdList": this.globalCompanyMasterIdList,
      "pageNo": this.page,
      "recordCount": this.size
    }
    this.employeelandingPageService.getEmployeeSummaryInformation(data).subscribe(res => {
      this.EmployeeSummary1 = []
      this.EmployeeSummary = []
      res.data.results.forEach(ele => {
        ele.checked = false;
        this.EmployeeSummary.push(ele)
      })
      this.copyEmployeeSummary = res.data.results;
      this.EmployeeSummary.forEach(element => {
        element.joiningDate = this.datePipe.transform(element.joiningDate, 'dd-MMM-yyyy');
        let alreadyAvailable = this.EmployeeSummary1.find(x => x.employeeMasterId == element.employeeMasterId);
        if (!alreadyAvailable) {
          this.EmployeeSummary1.push(element)
        }
      });
      if (sessionStorage.getItem('editEmployeeMasterId')) {
        let employeeMasterId = this.authService.deValue(sessionStorage.getItem('editEmployeeMasterId'));
        let data = this.EmployeeSummary.find(x => x.employeeMasterId == employeeMasterId)
        this.getSelectedEmployee(data, true);
      }
      if (event.sortField !== undefined) {
        let tableData: any;
        let sortingField = event.sortField
        if (event.sortOrder == 1) {
          this.EmployeeSummary.sort((a, b) => a[sortingField].localeCompare(b[sortingField]));
          // tableData = this.EmployeeSummary
        } else {
          this.EmployeeSummary.reverse();
          // tableData = this.EmployeeSummary
        }
        // filter data
        this.filteredRows = this.allrows.filter(row => this.filterField(row, event.filters))
        // sort data
        this.filteredRows.sort((a, b) => this.compareField(a, b, event.sortField) * event.sortOrder);
        this.displayedRows = this.filteredRows.slice(event.first, (event.first + event.rows))
        this.totalRowsNb = this.filteredRows.length;
      }
    })
  }
  filterField(row, filter) {
    let isInFilter = false;
    let noFilter = true;
    for (var columnName in filter) {
      if (row[columnName] == null) {
        return;
      }
      noFilter = false;
      let rowValue: String = row[columnName].toString().toLowerCase();
      let filterMatchMode: String = filter[columnName].matchMode;
      if (filterMatchMode.includes("contains") && rowValue.includes(filter[columnName].value.toLowerCase())) {
        isInFilter = true;
      } else if (filterMatchMode.includes("startsWith") && rowValue.startsWith(filter[columnName].value.toLowerCase())) {
        isInFilter = true;
      } else if (filterMatchMode.includes("in") && filter[columnName].value.includes(rowValue)) {
        isInFilter = true;
      }
    }
    if (noFilter) { isInFilter = true; }
    return isInFilter;
  }
  compareField(rowA, rowB, field: string): number {
    if (rowA[field] == null) return 1; // on considère les éléments null les plus petits
    if (typeof rowA[field] === 'string') {
      return rowA[field].localeCompare(rowB[field]);
    }
    if (typeof rowA[field] === 'number') {
      if (rowA[field] > rowB[field]) return 1;
      else return -1;
    }
  }
  /************************************ Filters *********************/
  /** Show Filter UI */
  showFilterUI() {
    this.isDisplayFilter = !this.isDisplayFilter
  }
  /*** get all filters summary data */
  getAllFilterSummary() {
    this.filterService.getAllFiltersSummary().subscribe(res => {
      this.allFilterSummary = res.data.results[0];
    })
  }
  /** After apply filter on side bar  */
  filterEventHander($event: any) {
    this.filterApplyData = $event;
    this.EmployeeSummary = []
    this.EmployeeSummary1.forEach(ele => {
      this.filterApplyData.forEach(element => {
        if (parseInt(ele.employeeMasterId) == parseInt(element.employeeMasterId)) {
          this.EmployeeSummary.push(ele)
        }
      });
    })
  }
  /** Call on Dropdown Selection ****/
  filterApply(value) {
    this.EmployeeSummary = []
    if (value != '') {
      this.filterService.getEmployeeJobinformationData(value).subscribe(res => {
        this.filterApplyData = res.data.results[0];
        // this.allFilterSummary = false
        this.EmployeeSummary1.forEach(ele => {
          this.filterApplyData.forEach(element => {
            if (parseInt(ele.employeeMasterId) == parseInt(element.employeeMasterId)) {
              this.EmployeeSummary.push(ele)
            }
          });
        })
      })
    } else {
      /**  if no filter selected into dropdown then reset summary and display all default data   */
      this.EmployeeSummary = [...this.copyEmployeeSummary]
    }
  }
  onEmpLogin() {
    this.authService.empLoginDetails(this.employeeMasterId, this.companyId)
  }
  showPopupModal(template: TemplateRef<any>, empId, compId) {
    this.employeeMasterId = empId;
    this.companyId = compId;
    this.modalRef = this.modalService.show(
      template, Object.assign({}, { class: 'gray modal-md' })
    );
  }
  openPopup(document: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      document
      , Object.assign({}, { class: "gray modal-md" })
    )
    this.commonPopup.openPopup();
  }
  copySelectDeselectPopUp(
    copyDeselect: TemplateRef<any>,
    isSelection: boolean
  ) {
    this.isSelection = isSelection;
    this.modalRef = this.modalService.show(
      copyDeselect,
      Object.assign({}, { class: 'gray modal-md' })
    );
  }
  deselectFromPaste() {
    this.modalRef.hide();
    let array = this.pastedData.split('\n');
    this.pastedData = '';
    let arrayToTableData = [];
    if (this.isSelection) {
      array.forEach((element) => {
        this.EmployeeSummary.filter((x) => {
          if (x.employeeCode.trim() == element.trim()) {
            arrayToTableData.push(x);
          } else {
            return null;
          }
        });
      });
      arrayToTableData.forEach((element) => {
        if (element != null && element != undefined) {
          this.getSelectedEmployee(element, false);
        }
      });
      this.alertService.sweetalertInfo(
        `Deselected ${arrayToTableData.length} Records`
      );
      // this.onSubmitDeselectEmployees(true);
    } else {
      array.forEach((element) => {
        this.EmployeeSummary.filter((x) => {
          if (x.employeeCode.trim() == element.trim()) {
            arrayToTableData.push(x);
          } else {
            return null;
          }
        });
      });
      arrayToTableData.forEach((element) => {
        if (element != null && element != undefined) {
          this.getSelectedEmployee(element, true);
        }
      });
      // let b = this.employeeWiseDeselectTableData;
      // this.employeeWiseDeselectTableData = this.rejectedEmployeesTableData;
      // this.rejectedEmployeesTableData = b;
      this.alertService.sweetalertInfo(
        `Selected ${arrayToTableData.length} Records`
      );
      // this.onSubmitDeselectEmployees(true);
    }
  }
}

