import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { HelpDocumentService } from '../services/help-document.service';

@Component({
  selector: 'app-help-document-popup',
  templateUrl: './help-document-popup.component.html',
  styleUrls: ['./help-document-popup.component.scss']
})
export class HelpDocumentPopupComponent implements OnInit {
  @Input() modalRef1: BsModalRef;
  @Input() type: any
  documentArry: Array<any> = [];
  destroyed = new Subject();
  constructor(private helpDocumentPopup: HelpDocumentService,private authService:AuthService) { }


  ngOnInit(): void {
    let abc = this.authService.deObject(sessionStorage.getItem('%qr'))
    let sessionData = this.authService.deObject(sessionStorage.getItem('%qrCopy'))
    // let type = sessionData.type
    let data = {};
    if (this.type == 'Employee Master') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Employee Master',
        "tableId": 0
      }
    } else if (this.type == 'Remibursement') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Remibursement',
        "tableId": 0
      }

    } else if (this.type == 'Approval') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Approval',
        "tableId": 0
      }

    } else if (this.type == 'CRM') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'CRM',
        "tableId": 0
      }

    } else if (this.type == 'Expense Reimbursement') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Expense Reimbursement',
        "tableId": 0
      }

    } else if (this.type == 'General Claim') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'General Claim',
        "tableId": 0
      }

    } else if (this.type == 'Payroll') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Payroll',
        "tableId": 0
      }

    } else if (this.type == 'master') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'master',
        "tableId": 0
      }

    } else if (this.type == 'LMS') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'LMS',
        "tableId": 0
      }

    } else if (this.type == 'payrollApplications') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'payrollApplications',
        "tableId": 0
      }

    } else if (this.type == 'Query') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Query',
        "tableId": 0
      }

    } else if (this.type == 'Report') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Report',
        "tableId": 0
      }

    } else if (this.type == 'Setting') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Setting',
        "tableId": 0
      }

    } else if (this.type == 'Survey') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'Survey',
        "tableId": 0
      }

    } else if (this.type == 'LMS Input') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'LMS Input',
        "tableId": 0
      }

    }else if (this.type == 'ESOP') {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type,
        "tableName": 'ESOP',
        "tableId": 0
      }

    } else {
      data = {
        "groupCompanyID": abc.globalCompanyMasterId,
        "category": this.type.category,
        "tableName": this.type.tableName,
        "tableId": this.type.tableId
      }
    }
    this.helpDocumentPopup.getHelpDocument(data).pipe(takeUntil(this.destroyed)).subscribe((res) => {
      this.documentArry = []
      res.data.results[0].forEach(ele => {
        if (ele.documentList1 != null) {
          ele.documentList1.forEach(element => {
            this.documentArry.push(element)
          })
        }
        if (ele.documentList != null) {
          ele.documentList.forEach(element => {
            this.documentArry.push(element)
          })
        }
      })
    })
  }
  ngOnDestroy(): void {
    this.destroyed.next()
    this.destroyed.complete()

  }

  // Method to Close Opened Help Document Template 
  closePopup() {
    this.modalRef1.hide()
  }

}
