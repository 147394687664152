import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../app/modules/auth/auth.service';
import { AlertServiceService } from '../../services/alert-service.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appCheckUserTime]'
})
export class CheckUserTimeDirective {
  setTimeLeft
  interval
  constructor(private router:Router,private authService:AuthService,private alertService:AlertServiceService,
    private http: HttpClient) { }
  @HostListener('click') onClick() {
    this.checkDevTools()
    if(window.location.pathname != '/login'){
    this.setTimeLeft = 59;
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.setTimeLeft > 0) {
        this.setTimeLeft--;

        // console.log('set Intervel', this.setTimeLeft);
      } else {
        clearInterval(this.interval);
        // console.log('clear Intervel');
        // if ( this.router.getCurrentNavigation() === null ) {
        //   if (!this.authService.isLoggedIn()) {
        //     if(window.location.pathname == '/Set-Password'){
        //       this.router.navigate( ['/Set-Password'] );
        //     }else{
        //     this.router.navigate( ['/login'] );
        //     }
        //   }
        // }
        // this.authService.getLogout().subscribe((res:any)=>{
        this.authService.logout()
        alert('Session Expire')
      }
    }, 60000);
  }
  }

  stopInterval(){
    clearInterval(this.interval)
  }


  @HostListener('window:beforeunload',['$event']) doLogout(event:any) {
    if(window.location.pathname != '/login'){
      clearInterval(this.interval);
        // this.authService.getLogout().subscribe((res:any)=>{
          // this.authService.logout()
        // }, (error: any) => {
        //   this.alertService.sweetalertError(error["error"]["status"]["messsage"]);
        // })
    }
    // return false

  }

  @HostListener('window:load',['$event']) doLoad(event:any) {
    if(window.location.pathname != '/login'){
      if(this.authService.isLoggedIn()){
        // this.onClick()
        this.authService.getLogin().subscribe((res:any)=>{
        }, (error: any) => {
          this.alertService.sweetalertError(error["error"]["status"]["messsage"]);
        })
      }
    }
    // return false

  }
  @HostListener('window:offline')
  setNetworkOffline(): void {
    this.alertService.sweetalertError('You are offline');
  }

  @HostListener('window:online')
  setNetworkOnline(): void {
    this.alertService.sweetalertMasterSuccess("Back to online", "");
    let ipAdd:any;
    // setTimeout(()=>{
    //   this.authService.getIPAddress().subscribe((res:any)=>{
    //     ipAdd = res.ip;
    //     console.log('change IP Address',ipAdd)
    //     if(ipAdd != sessionStorage.getItem('qrip')){
    //       this.authService.logout()
    //     }
    //   },(error:any)=>{
    //     console.log('before error in IP')
    //   })
    // },1000)
    // this.authService.getAssignedUserRoles().subscribe((res:any)=>{
      // console.log('res',res)
      this.authService.getIPAddress().subscribe((res:any)=>{
        ipAdd = res.ip;
        console.log('After API change IP Address',res)
        ipAdd = res.ip;
        console.log('After API change IP Address',ipAdd)
      },(error:any)=>{
        console.log('After error in IP')
      })
    // })

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkDevTools();
  }
  devToolsStatus: string = 'Unknown';
  checkDevTools(): void {
    if (window.outerWidth - window.innerWidth > 100 || window.outerHeight - window.innerHeight > 205) {
      this.devToolsStatus = 'Open';
      if (window.location.host == 'www.deliziahr.com') {
      // this.authService.logout()
      }
    } else {
      this.devToolsStatus = 'Closed';
    }
    // console.log('devToolsStatus',this.devToolsStatus);
    // alert(this.devToolsStatus)

}


}
