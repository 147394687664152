import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../../auth/auth.service';
import { TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeftMenuServiceService } from '../left-menu-service.service';
import { CheckUserTimeDirective } from './../../../../app/core/utility/directives/check-user-time.directive';
import { AlertServiceService } from '../../../core/services/alert-service.service';
import { SharedInformationService } from '../../employee-master/employee-master-services/shared-service/shared-information.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit,AfterViewInit {
  @Input() delziahrLogoShow:boolean;
  
  public isFullScreen: boolean;
  public contactTab: boolean;
  public groupTab: boolean;
  public chatTab = true;
  public title: any;
  public userInfo: any;
  public userName: string;
  public modalRef: BsModalRef;
  userData: any;
  imageUrl: string;
  passExpiry: any;
  sessionTimeExpiry: any;
  sessionOut: any;
  policy: any;
  passExpiryBeforDate: Date;
  employeeInfo: any;
  distinctRoles: any[];
  distinctRolesCopy: any[];
  roleType: string;
  groupCompanyData: any;
  distinctGroups: any;
  distinctGroupsCopy: any;
  distinctCompany: any;
  distinctCompanyCopy: any;
  companyLength: boolean=false;
  groupLength: boolean;
  roleLength: boolean;
  role: any;
  roleCheck: any;
  compName: any;
  roleShow: any = '';
  groupName: any;
  isSSOLogin : boolean = false;
  isEmpLoginByAdminYes: boolean = false;
  isSSOYes : boolean = false;
  parentRoleName: string;
  userGroup:String;
  constructor(private modalService: BsModalService, private service: AuthService,
    private route: Router, private leftMenuSerivce: LeftMenuServiceService,private checkDir:CheckUserTimeDirective,
    private alertService:AlertServiceService,private empMasterSharedInformationService:SharedInformationService) {
    this.title = route.url;
    this.title = this.title.replace(/\//g, '  ');
    this.title = this.title.replace('HousingLoan', 'House Property - Stamp Duty/ Registration Charges');
    this.title = this.title.replace('HouseProperty', 'House Property - Stamp Duty/ Registration Charges');
    this.title = this.title.toUpperCase();
    }
  public ngOnInit(): void {
    if(JSON.parse(sessionStorage.getItem('%rd')) != null && JSON.parse(sessionStorage.getItem('%rd')) != undefined){
      this.groupCompanyData = JSON.parse(sessionStorage.getItem('%rd'))
    }
    if(JSON.parse(sessionStorage.getItem('%distComp')) != null && JSON.parse(sessionStorage.getItem('%distComp')) != undefined){
      this.distinctCompany = JSON.parse(sessionStorage.getItem('%distComp'))
      this.distinctCompanyCopy = [...this.distinctCompany]
    }
    if(JSON.parse(sessionStorage.getItem('%distGrp')) != null && JSON.parse(sessionStorage.getItem('%distGrp')) != undefined){
      this.distinctGroups = JSON.parse(sessionStorage.getItem('%distGrp'))
      this.distinctGroupsCopy = [...this.distinctGroups]    
    }

    let isSSO : boolean = JSON.parse(sessionStorage.getItem('issso'))
    if(isSSO){
      this.isSSOLogin = true
    }else{
      this.isSSOLogin = false
    }
    
setTimeout(() => {
    if(!this.roleType){
      if(this.service.deObject(sessionStorage.getItem('%qr')) != null && this.service.deObject(sessionStorage.getItem('%qr')) != undefined){
    this.roleType = this.service.deObject(sessionStorage.getItem('%qr')).type
    this.userGroup = this.service.deObject(sessionStorage.getItem('%qr')).loginCompanyName
      }
    }
    this.role = this.service.deObject(sessionStorage.getItem('%qr'))
    this.userGroup = this.role.loginCompanyName
    this.userInfo = this.service.getprivileges();
    this.userName = this.userInfo.UserDetails.userName;
    this.userInfo = []
    this.dataFromLocalFile();
    this.roleShow = this.service.deValue(sessionStorage.getItem('selectedRole'))
    this.groupName = this.service.deValue(sessionStorage.getItem('selectedGroup'))
    this.compName = this.service.deValue(sessionStorage.getItem('selectedCompany'))
    this.service.shareGroupName.subscribe(res=>{
      this.groupName = res
    })
    this.service.shareCompName.subscribe(res=>{
      this.compName = res
    })
    this.service.shareCompanies.subscribe(res=>{
      if(res){
        if(res.length > 1){
        this.distinctCompany=res;
        this.distinctCompanyCopy = [...res]
        sessionStorage.setItem('%distComp', JSON.stringify(this.distinctCompanyCopy));
        }
      }
    })
    this.service.shareGroups.subscribe(res=>{
      if(res){
        setTimeout(() => {
          this.distinctGroups=res;
          this.distinctGroupsCopy = [...res]
          sessionStorage.setItem('%distGrp', JSON.stringify(this.distinctGroupsCopy));
        }, 1000);
      }
    })
    this.service.shareRoleName.subscribe(res=>{
      if(res){
        this.roleShow = res
        if(res == 'Live Employee'){this.roleShow = ''}
      }
    })

    this.service.groupCompanyData.subscribe(res=>{
      this.groupCompanyData = res
    })
     this.getUserDetail()
    this.passwordExpiry()
     this.sessionExpiry();
    this.userInfo = []
    this.userInfo = this.service.getprivileges();
    this.userName = this.userInfo.UserDetails.userName;
    this.getUserDetail();
      }, 1000);

      this.service.loadHeader.subscribe(res=>{
        if(res){
          this.ngOnInit()
          this.roleCheck = 'Employee'
          this.roleType = 'Live Employee'
        }
      })

      this.isEmpLoginByAdminYes = JSON.parse(sessionStorage.getItem('isemploginbyadmin'));
      this.isSSOYes = JSON.parse(sessionStorage.getItem('issso'));
      this.parentRoleName = JSON.parse(sessionStorage.getItem('ptrrolename'));
  }
 ngAfterViewInit(): void {
  this.checkDir.onClick()
 }
  logout() {
    // this.service.getLogout().subscribe((res:any)=>{
      this.checkDir.stopInterval()
      this.service.logout();
    // }, (error: any) => {
    //   this.alertService.sweetalertError(error["error"]["status"]["messsage"]);
    // })
  }
  mToggoleMenu() {
    document.getElementsByTagName('body')[0].classList.toggle('offcanvas-active');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }
  getUserDetail(): any {
    let data = this.service.deObject(sessionStorage.getItem('%qrCopy'));
    this.service.userDetail().subscribe(res => {
      this.employeeInfo = res.data.results[0]
      var timestamp = this.employeeInfo.lastLoginDateTime * 1000;
      this.employeeInfo.time = new Date(timestamp).toLocaleTimeString();
      this.leftMenuSerivce.setEmployeedata(this.employeeInfo);
      if (this.employeeInfo.image) {
        this.imageUrl = 'data:' + this.employeeInfo.type + ';base64,' + this.employeeInfo.image;
      }
    }, err => {
      this.employeeInfo = {
        lastLoginDateTime: '',
        designation1: '',
        fullName: this.userInfo.UserDetails.userName,
        employeeCode: ''
      }
    })
  }
  passwordExpiry(): any {
    this.service.getPassPolicy().subscribe(res => {
      this.policy = res.data.results[0];
      this.service.getPasswordExpiry().subscribe(res => {
        this.passExpiry = res.data.results[0];
        this.passExpiryBeforDate = new Date(this.passExpiry.expiryTimeStamp);
        this.passExpiryBeforDate.setDate(this.passExpiryBeforDate.getDate() - this.policy.dueInDays);
        let today = new Date();
        let passExpiryActualDate = new Date(this.passExpiry.expiryTimeStamp);
        if (this.passExpiryBeforDate < today && today <= passExpiryActualDate) {
          let element: HTMLElement = document.getElementById('passwordExpiryPopUp') as HTMLElement
          element.click();
        }
      }, error => {
      })
    }, err => {
    })
  }
  sessionExpiry() {
    this.service.getSessionExpiry().subscribe(res => {
      this.sessionTimeExpiry = res.data.results[0];
      this.sessionOut = new Date(this.sessionTimeExpiry.expiryTimeStamp);
      this.sessionOut = this.sessionOut.getTime() / 1000;
      this.sessionOut = this.sessionOut - 60000;
      setTimeout(() => {
        let element : HTMLElement = document.getElementById('sessionPopUp') as HTMLElement
        element.click();
      }, this.sessionOut);
    }, err => {
    })
  }
  passwordExpiryPopUp(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }
  sessioTimeOutPopUp(template2: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template2,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }
  noteToggle() {
    document.getElementsByClassName('sticky-note')[0].classList.toggle('open');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }
  openRightMenu() {
    document.getElementById('rightbar').classList.toggle('open');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }
  openfullScreen() {
    const elem = document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen ||
      elem.requestFullscreen || elem["mozRequestFullscreen"] || elem['msRequestFullscreen'];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
    this.isFullScreen = true;
  }
  closeFullScreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    this.isFullScreen = false;
  }
  onTab(number) {
    this.chatTab = false;
    this.groupTab = false;
    this.contactTab = false;
    if (number == '1') {
      this.chatTab = true;
    } else if (number == '2') {
      this.groupTab = true;
    } else if (number == '3') {
      this.contactTab = true;
    }
  }
  dataFromLocalFile() {
    let obj={
      // "ipAddress":sessionStorage.getItem('qrip')
      "ipAddress":null
    }
    this.service.getAssignedUserRoles(obj).subscribe(res => {
      this.distinctRoles = res.data.results[0];
      let isEmpLoginByAdmin : boolean = JSON.parse(sessionStorage.getItem('isemploginbyadmin'))
            if(isEmpLoginByAdmin){
              for(let i=0;i<this.distinctRoles.length;i++){
                let ind = this.distinctRoles.findIndex(e=> e.type == 'Global' || e.type == 'Admin')
                if(ind != -1){
                  this.distinctRoles.splice(ind,1)
                  i--;
                }
              }
            }
      // let count = 0
      //  this.distinctRoles.forEach(ele=>{
      //     let obj = {
      //       "roleName":ele.roleName,
      //       "ipAddress":sessionStorage.getItem('qrip')
      //     }
      //     if(sessionStorage.getItem('qrip') != null && sessionStorage.getItem('qrip') != '' && sessionStorage.getItem('qrip') != undefined){
      //     this.service.checkIPUserRole(obj).subscribe((res:any)=>{
      //       count++
      //       if(this.distinctRoles.length == count){
      //         this.distinctRolesCopy = [...this.distinctRoles]
      //         this.distinctRoles = this.distinctRolesCopy.filter(x => x.userRoleId != this.role.userRoleId);
      //         this.roleLength = res.data.results[0].length>1?true:false;
      //       }
      //     },(error)=>{
      //       let ind = this.distinctRoles.findIndex(e=> e.roleName == ele.roleName)
      //       if(ind != -1){
      //         this.distinctRoles.splice(ind,1)
      //       }
      //       if(this.distinctRoles.length == count){
      //         if(this.distinctRoles.length != 0){
      //         this.distinctRolesCopy = [...this.distinctRoles]
      //         this.distinctRoles = this.distinctRolesCopy.filter(x => x.userRoleId != this.role.userRoleId);
      //         this.roleLength = res.data.results[0].length>1?true:false;
      //         }else{
      //         this.alertService.sweetalertError('Access Denied !');
      //         this.service.logout();
      //         }
      //       }
      //     })
      //   }
      //   })

      this.distinctRolesCopy = [...this.distinctRoles]
      this.distinctRoles = this.distinctRolesCopy.filter(x => x.userRoleId != this.role.userRoleId);
      this.roleLength = res.data.results[0].length>1?true:false;
    });
  }
  changeUser(role) {
    this.distinctCompany=[]
    this.distinctCompanyCopy=[]
    this.distinctGroups=[]
    this.distinctGroupsCopy=[]
    this.compName = ''
    this.groupName = ''
    sessionStorage.setItem('%chm','false');
    this.service.showChildMenuHeader.next(false)
    sessionStorage.setItem('in','false')
    sessionStorage.setItem('bread',JSON.stringify([]));
    this.roleCheck = role.type;
    this.employeeInfo.roleName=role.roleName;
    localStorage.setItem('roleId', role.userRoleId);
    sessionStorage.setItem('selectedCountry','')
    sessionStorage.setItem('selectedGroup','')
    sessionStorage.setItem('selectedCompany','')
    sessionStorage.setItem('editEmployeeMasterId','')
    this.service.saveChangeLoginRole(role.type,role.globalUserMasterId).subscribe((res:any)=>{
      //Save success
    })
    this.distinctRoles = this.distinctRolesCopy.filter(x => x.userRoleId != role.userRoleId);
    if (role.type == "Employee") {
      let breadData = [{"parentMenuName":"Employee Dashboard","path":"/employee-master/employee-dashboard"}]
      this.leftMenuSerivce.shareBreadcrumb.next(breadData)
      this.roleShow = '';
      this.roleType = 'Employee';
      this.roleCheck = 'Employee';
      role.globalCompanyMasterId1 = role.globalCompanyMasterId
      // sessionStorage.setItem('%qr', JSON.stringify(role));
      sessionStorage.setItem('%qr',this.service.enObject(role))
      this.service.allowedPathVariables(role)
      this.service.shareData.next(role);
      // this.route.navigate(['/employee-master/employee-dashboard']);
      this.route.navigate(['/dashboard']);
      sessionStorage.setItem('in','true')
    } else if (role.type == 'Global') {      
      this.roleShow = role.roleName;
      sessionStorage.setItem('selectedRole',this.service.enValue(role.roleName))
      this.roleType = 'Global';
      this.roleCheck = 'Global';
      this.getCompanyData(role.userRoleId)
      let companies = role.globalCompanyMasterIdList.split(',');
      if (companies.length > 1) {
        this.leftMenuSerivce.leftMenuDataClear.next()
        this.leftMenuSerivce.shareBreadcrumb.next([])
        this.groupLength = true;
        role.globalCompanyMasterId1 = role.globalCompanyMasterId
        sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(role.userRoleId)))
        // sessionStorage.setItem('%qrCopy', JSON.stringify(role));
        sessionStorage.setItem('%qrCopy',this.service.enObject(role))
        sessionStorage.setItem('%qr', null);
        this.route.navigate(['/userGroupSelectPage']);
      } else {
        let breadData = [{"parentMenuName":"dashboard","path":"/dashboard"}]
      this.leftMenuSerivce.shareBreadcrumb.next(breadData)
        this.service.allowedPathVariables(role)
        role.globalCompanyMasterId1 = role.globalCompanyMasterId
        this.service.shareData.next(role)
        this.route.navigate(['/dashboard']);
        sessionStorage.setItem('in','true')
      }
    } else
    {
      this.getCompanyData(role.userRoleId)
      this.roleShow = role.roleName;
      sessionStorage.setItem('selectedRole',this.service.enValue(role.roleName))
      this.roleType = 'Admin';
      this.roleCheck = 'Admin';
      this.companyLength = role.globalCompanyMasterIdList.split(',').length> 1 ?true:false;
      if (role.globalCompanyMasterIdList.split(',').length > 1) {
        this.leftMenuSerivce.leftMenuDataClear.next()
        this.leftMenuSerivce.shareBreadcrumb.next([])
        sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(role.globalCompanyMasterIdList)))
        this.getCompanyData(role.userRoleId)
        role.globalCompanyMasterId1 = role.globalCompanyMasterId
        sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(role.userRoleId)))
        // sessionStorage.setItem('%qrCopy', JSON.stringify(role));
        sessionStorage.setItem('%qrCopy',this.service.enObject(role))
        sessionStorage.setItem('%qr', null);
        this.route.navigate(['/userGroupSelectPage']);
      } else {
        let breadData = [{"parentMenuName":"dashboard","path":"/dashboard"}]
      this.leftMenuSerivce.shareBreadcrumb.next(breadData)
        role.globalCompanyMasterId1 = role.globalCompanyMasterId
        // sessionStorage.setItem('%qr', JSON.stringify(role))
        sessionStorage.setItem('%qr',this.service.enObject(role))
        sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(role.globalCompanyMasterId)))
        this.service.allowedPathVariables(role)
        this.service.shareData.next(role)
        this.route.navigate(['/dashboard']);
        sessionStorage.setItem('in','true')
      }
    }
    this.getUserDetail();
    this.empMasterSharedInformationService.clearSession();
    this.empMasterSharedInformationService.clearLocalStorage();
    
  }
  getCompanyData(roleId) {
    this.service.checkUser(roleId).subscribe(res => {
      this.groupCompanyData = res.data.results;
      sessionStorage.setItem('%rd', JSON.stringify(this.groupCompanyData))
      if(this.groupCompanyData.length == 1){
        this.compName = this.groupCompanyData[0].companyName
      }
    });
  }
  switchGroup(groupName){
    sessionStorage.setItem('%chm','false');
    this.service.showChildMenuHeader.next(false)
    sessionStorage.setItem('selectedGroup',this.service.enValue(groupName))
    sessionStorage.setItem('bread',JSON.stringify([]));
    let group  = this.groupCompanyData.find(x=>x.companyGroupName==groupName);
    let json = this.service.deObject(sessionStorage.getItem('%qrCopy'));
    let json1 = this.service.deObject(sessionStorage.getItem('%qr'))
    json.groupName= groupName;
    json1.groupName= groupName;
    this.distinctCompany = this.groupCompanyData.filter(x=>x.companyGroupName==groupName).map(x=>x.companyName)
    this.distinctCompanyCopy = [...this.distinctCompany]
    sessionStorage.setItem('%distComp', JSON.stringify(this.distinctCompanyCopy));
    this.compName = this.distinctCompany[0];
    json.globalCompanyMasterId1 = group.globalCompanyMasterId
    json.globalCompanyMasterId = group.globalCompanyMasterId
    json1.globalCompanyMasterId1 = group.globalCompanyMasterId
    sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(json1.globalCompanyMasterIdList)))
    // sessionStorage.setItem('%qr',JSON.stringify(json))
    sessionStorage.setItem('%qr',this.service.enObject(json))
    // sessionStorage.setItem('%qrCopy',JSON.stringify(json1))
    sessionStorage.setItem('%qrCopy',this.service.enObject(json1))
    if(json1.globalCompanyMasterIdList.split(',').length > 1){
      sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(json.userRoleId)))
      delete json.groupName;
      delete json1.groupName;
    // sessionStorage.setItem('%qr',JSON.stringify(json))
    sessionStorage.setItem('%qr',this.service.enObject(json))
    // sessionStorage.setItem('%qrCopy',JSON.stringify(json1))
    sessionStorage.setItem('%qrCopy',this.service.enObject(json1))
      this.route.navigate(['/userGroupSelectPage']);
    }else{
    this.service.shareData.next(json)
        this.route.navigate(['/dashboard']);
    }
this.getUserDetail();
this.empMasterSharedInformationService.clearSession();
this.empMasterSharedInformationService.clearLocalStorage();
}
switchCompany(data){
  this.compName = data;
  sessionStorage.setItem('%chm','false');
  this.service.showChildMenuHeader.next(false)
  sessionStorage.setItem('bread',JSON.stringify([]));
  let group  = this.groupCompanyData.find(x=>x.companyName==data);
sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(group.globalCompanyMasterId)))
let json = this.service.deObject(sessionStorage.getItem('%qrCopy'));
let json1 = this.service.deObject(sessionStorage.getItem('%qr'))
json.groupName= group.groupName;
json.globalCompanyMasterId1 = group.globalCompanyMasterId
json.globalCompanyMasterId = group.globalCompanyMasterId
json1.globalCompanyMasterId1 = group.globalCompanyMasterId
json1.groupName= group.groupName;
// sessionStorage.setItem('%qr',JSON.stringify(json))
sessionStorage.setItem('%qr',this.service.enObject(json))
// sessionStorage.setItem('%qrCopy',JSON.stringify(json1))
sessionStorage.setItem('%qrCopy',this.service.enObject(json1))
this.service.shareData.next(json);
this.route.navigate(['/dashboard']);
this.getUserDetail();
this.empMasterSharedInformationService.clearSession();
this.empMasterSharedInformationService.clearLocalStorage();
}
toggleMenu() {
  this.service.toggelChange.next();
}
dropDown(event) {
  event.stopPropagation()
}

searchGroup(value){
  value = value.toLocaleLowerCase();

  this.distinctGroups=  this.distinctGroupsCopy.filter(it => {
    return it.toLocaleLowerCase().includes(value);
  });
  console.log('Filter Group List',this.distinctGroups)
}
searchCompany(value){
  value = value.toLocaleLowerCase();

  this.distinctCompany=  this.distinctCompanyCopy.filter(it => {
    return it.toLocaleLowerCase().includes(value);
  });
  console.log('Filter Company List',this.distinctCompany)
}
backToParentLogin(){
  this.service.backToParentLogin()
  this.ngOnInit()
}
}
