import { Component, OnInit } from '@angular/core';
import { LeftMenuServiceService } from '../left-menu-service.service';

@Component({
  selector: 'app-grandchildmenu',
  templateUrl: './grandchildmenu.component.html',
  styleUrls: ['./grandchildmenu.component.scss']
})
export class GrandchildmenuComponent implements OnInit {
  grandChildDataList:any;
  grandChildDataList1: any;
  value=0;

  constructor(private service:LeftMenuServiceService) {
    let data=0
   }

  ngOnInit(): void {    
    this.grandChildDataList=  this.service.getShareToData();
  }
  

}
