<div *ngIf="!grandChild" [ngClass]="{'disableLoader':isLoader}">
    <div class="section-body mt-3 ">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <div class="">
                            <div class="row">
                                <!-- <div class="wid-130 mr-2" (click)="onClickChildManu(data)" *ngFor="let data of childMenuData">
                                    <div class="stats-box-new sales">
                                        <div class="statistics-header text-center mt-1 cursor-pointer">
                                            {{data.childMenuName}}
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <ul id="cardsbreadcrumb">
                                    <li class="cursor-pointer" (click)="onClickChildManu(data)" *ngFor="let data of childMenuData">
                                        <a [ngClass]="{'pinkColor': data.rowNum ==rowNumber, 'blueColor':data.rowNum != rowNumber}">
                                            {{data.childMenuName}}</a>
                                    </li>

                                </ul> -->
                                <ul id="cardsbreadcrumb">
                                    <li class="cursor-pointer" [ngClass]="i==0?'ml30':''"
                                        (click)="onClickChildManu(data)"
                                        *ngFor="let data of childMenuData;let i = index">
                                        <a
                                            [ngClass]="data.rowNum ==rowNumber?'pinkColor':'blueColor'">
                                            {{data.childMenuName}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="isShowLandingPage"><app-emp-master-landing-page></app-emp-master-landing-page></div>
    <div *ngIf="isShowPayrollList"><app-payroll-list></app-payroll-list></div>
    <div *ngIf="isShowLMSFormInputList"><app-lms-forms-input></app-lms-forms-input></div> -->
</div>
<div *ngIf="grandChild" [ngClass]="{'disableLoader':isLoader}">
    <div class="ml-5 section-body mt-3 ">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <div class="">
                            <div class="row">
                                <div class="col-md-2 mr-4" (click)="onClickGrandChildManu(child)"
                                    *ngFor="let child of grandChildDataList">
                                    <div class="stats-box sales"><i
                                            class=" fa-solid {{child.grandChildIcon}} menu-icon-css"></i>

                                        <div class="statistics-header text-center mt-1 cursor-pointer">
                                            {{child.grandChildName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-loader-wrapper" *ngIf="isLoader">
    <div class="loader-1">
        <div class="loader-outter"></div>
        <div class="loader-inner"></div>
    </div>
</div>