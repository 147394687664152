import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChildmenuComponent } from './childmenu/childmenu.component';
import { GrandchildmenuComponent } from './grandchildmenu/grandchildmenu.component';

const routes: Routes = [
    {path: '',
    children: [
      {
        path:   'menu-forms',
        component:  ChildmenuComponent,
        data: { title: ':: DeliziaHR :: ' },

      },
      {
        path:   'grandChild',
        component:  GrandchildmenuComponent,
        data: { title: ':: DeliziaHR :: ' },

      }]}]

    @NgModule({
        imports: [
          RouterModule.forChild(routes),
        ],
        exports: [RouterModule],
    })
export class SharedLayoutRoutingModule {}