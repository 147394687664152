<div class="auth">
    <div class="auth_left">
        <div class="card">
            <div class="card-body">
                <div class="display-3 text-muted mb-5"><i class="si si-exclamation"></i> 404</div>
                <h1 class="h3 mb-3">Oops.. You just found an error page..</h1>
                <p class="h6 text-muted font-weight-normal mb-3">We are sorry but our service is currently not
                    available&hellip;</p>
                <a class="btn btn-primary" href="javascript:history.back()"><i class="fe fe-arrow-left mr-2"></i>Go
                    back</a>
            </div>
        </div>
    </div>
    <div class="auth_right">
        <carousel [isAnimated]="true">
            <slide>
                <img src="assets/images/slider1.svg" class="img-fluid" alt="first slide">
                <div class="px-4 mt-4">
                    <h4>Fully Responsive</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                </div>
            </slide>
            <slide>
                <img src="assets/images/slider2.svg" class="img-fluid" alt="second slide">
                <div class="px-4 mt-4">
                    <h4>Quality Code and Easy Customizability</h4>
                    <p>There are many variations of passages of Lorem Ipsum available.</p>
                </div>
            </slide>
            <slide>
                <img src="assets/images/slider3.svg" class="img-fluid" alt="third slide">
                <div class="px-4 mt-4">
                    <h4>Cross Browser Compatibility</h4>
                    <p>Overview We're a group of women who want to learn JavaScript.</p>
                </div>
            </slide>
        </carousel>
    </div>
</div>