<div>
    <div class="section-body mt-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="rup mt-3">
                        <div class="row clearfix row d-flex justify-content-center text-bold">
                            <div *ngFor="let child of grandChildDataList" class="col-md-3">
                                <div class="card  btn-bg-green">
                                    <div class="card-body">
                                        <a href="javascript:void(0)" routerLink="{{child.routerLink}}"
                                            class="my_sort_cut text-muted">
                                            <i class="fa fa-briefcase"></i>
                                            <span>{{child.grandChildName}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>