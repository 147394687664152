<div >  
    <div class="section-body mt-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card ht-500" >
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="rup ">
                                        <div class="row clearfix row d-flex justify-content-center text-bold">
                                            <div class=" col-md-3">
                                                <div class="card  btn-bg-green">
                                                    <div class="card-body">
                                                        <a [routerLink]="['/investment/Other-TaxAdjustments']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fas fa-handshake"></i>
                                                            <span>CRM </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="card btn-bg-green">
                                                    <div class="card-body">
                                                        <a [routerLink]="['/investment/Other-OtherIncome']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fa fa-cog"></i>
                                                            <span>Company Setting</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="card btn-bg-green">
                                                    <div class="card-body ribbon">
                                                        <a [routerLink]="['/investment/Other-Child-Hostel-Allowance']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fab fa-codepen"></i>
                                                            <span>Master</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="card btn-bg-green">
                                                    <div class="card-body ribbon">
                                                        <a [routerLink]="['/investment/Other-Child-Hostel-Allowance']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fas fa-expand-arrows-alt"></i>
                                                            <span>Cascading</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-25 clearfix row d-flex justify-content-center text-bold">
                                            <div class=" col-md-3">
                                                <div class="card  btn-bg-green">
                                                    <div class="card-body">
                                                        <a [routerLink]="['/investment/Other-TaxAdjustments']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fas fa-users"></i>
                                                            <span>User Management </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="card btn-bg-green">
                                                    <div class="card-body">
                                                        <a [routerLink]="['/investment/Other-OtherIncome']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fas fa-question-circle"></i>
                                                            <span>Query</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="card btn-bg-green">
                                                    <div class="card-body ribbon">
                                                        <a [routerLink]="['/investment/Other-Child-Hostel-Allowance']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fas fa-search-dollar"></i>
                                                            <span>Payment Tracking</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="card btn-bg-green">
                                                    <div class="card-body ribbon">

                                                        <a [routerLink]="['/investment/Other-Child-Hostel-Allowance']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fas fa-user-clock"></i>
                                                            <span>AMS</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-25 clearfix row d-flex  text-bold">
                                            <div class=" col-md-3">
                                                <div class="card  btn-bg-green">
                                                    <div class="card-body">
                                                        <a [routerLink]="['/investment/Other-TaxAdjustments']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fas fa-file-invoice-dollar"></i>
                                                            <span>Billing </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="card btn-bg-green">
                                                    <div class="card-body">
                                                        <a [routerLink]="['/investment/Other-OtherIncome']"
                                                            href="javascript:void(0)" class="my_sort_cut text-muted">
                                                            <i class="fas fa-copy"></i>
                                                            <span>Report & Analytics</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>     
</div>
