<!-- <div class="sidenav"> -->
<div class="modal-header">
    <h5 class="modal-title" id="UploadModalLabel">User Guide List</h5>
    <button type="button" (click)="closePopup()" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">×</span></button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <div class="row ">
                <div class="col-md-12">
                    <div class="table-responsive Custom_Table">
                        <p-table #dt1 [value]="documentArry">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center">
                                        Sr. No.
                                    </th>
                                    <th class="text-center">
                                        Document
                                    </th>
                                    <!-- <th class="text-center">
                                            Action</th> -->
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-user1 let-rowIndex="rowIndex">
                                <tr class="p-selectable-row">
                                    <td class="text-center">{{rowIndex + 1}}</td>
                                    <td class="text-left"><a class="text-link" href="{{user1.blobURI}}" target="_blank">
                                            {{user1?.fileName}}
                                        </a>
                                    </td>
                                    <!-- <td class="text-center">
                                        <a href="{{user1.blobURI}}" target="_blank">
                                            <i class="fa fa-files-o font-16 text-dark-green cursor-pointer"></i>
                                        </a>
                                    </td> -->
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="15" class="font-14 font500 text-center text-red">
                                        <span>No Data Available</span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" tooltip="Close" class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left"
        (click)="closePopup()"><b><i class="fa fa-times"></i></b> Close</button>
</div>
<!-- </div> -->