import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  public apiUrl = environment.baseUrl8084;
  public apiUrl83 = environment.baseUrl8083;
  filterPageName: string = '';
  filterMasterId: number = 0;

  constructor(private http: HttpClient) { }

  /** Get All Filter Summary Data */
  getAllFiltersSummary(): Observable<any> {
    // return this.http.get<any>(this.apiUrl + 'FilterMaster')
    return this.http.get<any>(this.apiUrl83 + 'filter-master')   
  }

  /** save All Filter Data */
  saveAllFilters(data): Observable<any> {
   // return this.http.post<any>(this.apiUrl + 'FilterMaster/Add1', data)
   return this.http.post<any>(this.apiUrl83 + 'filter-master',data)  
  }

  /** update All Filter Data */
  updateAllFilters(data): Observable<any> {
    //return this.http.put<any>(this.apiUrl + 'FilterMaster/Update', data)
    return this.http.post<any>(this.apiUrl83 + 'filter-master',data)  
  }

  /** Soft Delete Filter data */
  deleteFilterData(id): Observable<any> {
    //return this.http.delete<any>(this.apiUrl + 'FilterMaster/deleteByfilterMasterId/' + id)
    let data = [id]
    return this.http.post<any>(this.apiUrl83 + 'filter-master/delete' , data)
  }


  /***************************** Employee Form ***************************************/
  /** Get Employee Set Data */
  getEmployeeSet(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'EmployeeMaster/getAllEmployeeSet/')
  }

  /** Get Employee Service Age Data */
  getEmployeeServiceAge(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'filterofEmployee/getByServiceage/1-15')
  }

  /** Get Employee Age Data */
  getEmployeeAge(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'EmployeeSkillInfo/getByEmployeeAge/5-50')
  }

  /** Get Employee Language Data */
  getEmployeeLanguage(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'filterofEmployee')
  }

  /** Get Employee Skill Data */
  getEmployeeSkill(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'EmployeeSkillInfo')
  }

  /** Get Employee Education Data */
  getEmployeeEducation(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'EmployeeEducation')
  }

  /*********************************** Job Info Form ****************************************/
  /** Get Job Information By Company Id */
  getJobMasterInformation(companyId, name): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'filterofEmployee/getAllJobMasater/' + companyId + '/' + name)
  }

  /** Get All Company List Data */
  getAllGroupCompany(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'groupCompany/')
  }

  /** Get Payroll Area Details */
  getPayrollAreaDetails(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'payrollArea-details')
  }

  /** Get JobMaster Mapping Data By GroupCompanyId */
  getAllJobMasaterData(data): Observable<any> {
    return this.http.post<any>(environment.baseUrl8083 + 'job-master-mapping/getJobMasterMappingDataByGroupCompanyId', data)
  }

  getAllJobMasater(id):Observable<any>{
    return this.http.get<any>(environment.baseUrl8084 + 'filterofEmployee/getAllJobMasater/'+id)
  }
  
  /**get all Payrollareacode By company ids */
  getAllPayrollareacodeBycompanyids(data): Observable<any> {
    // return this.http.post<any>(environment.baseUrl8084 + 'filterofEmployee/AddJobMasterData', data)
    return this.http.get<any>(environment.baseUrl8084 + 'filterofdate/CompanyWisePayrollArea/' + data)
  }

  /** get All Establisment masters by company */
  getEstablishmentDetails(groupCompanyIdList): Observable<any> {
    return this.http.get<any>(environment.baseUrl8083 + 'establishment-master/esmaster/' + groupCompanyIdList)
  }

 
  /******************************************* Apply Filter **************************************************/
  getEmployeeJobinformationData(filterMasterId): Observable<any> {
    //return this.http.get<any>(this.apiUrl + 'filterByJobDetails/getAllFileterDataByFilterMasterId/' + filterMasterId)

    return this.http.get<any>(this.apiUrl83 + 'filter-master/getEmployeeDetailsById?filterMasterId=' + filterMasterId)
  }

   /** get All filter Data by passing filter Master */
  //  getAllFileterDataByFilterMasterId(filterMasterId): Observable<any> {
  //   return this.http.get<any>(environment.baseUrl8084 + 'filterByJobDetails/getAllFileterDataByFilterMasterId/' + 8)
  //   // return this.http.get<any>(environment.baseUrl8083 + 'filter-master/getEmployeeDetailsById?filterMasterId=' + filterMasterId)
  // }



  /****************************** Date Form *********************************************/

  /**Fetch all cycle definition */
  getAllCycleDefinition(): Observable<any> {
    return this.http.get(environment.baseUrl8084 + `businessCycleDefinition/GETAllCycle/`)
  }

  /** Get Cycle Name By Id */
  getCycleNameById(id): Observable<any> {
    return this.http.get(environment.baseUrl8086 + `business-cycle/getCycle/` + id)
  }

  /**Get current Cycle */
  getCurrentCycleNameById(id): Observable<any> {
    return this.http.get(environment.baseUrl8086 + `business-cycle/currentCycle/` + id)
  }

  /** Fetch All Period Defination List - */
  getAllPeriodDefnList(id): Observable<any> {
    return this.http.get<any>(environment.baseUrl8084 + 'FrequencyMaster/FrequencyMasterId/' + id)
  }


  /******For single report page set page name to apply filter and set flag for delete when download report 04/04/24 */

  setFilterPageName(pageName : string){
    this.filterPageName = pageName
  }

  getFilterPageName(){
    return this.filterPageName;
  }

  setFilterMasterId(filterMasterId: number){
    this.filterMasterId = filterMasterId
  }

  getFilterMasterId(){
     return this.filterMasterId;
  }

}