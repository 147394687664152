import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class EsopApproverCommonService {

  constructor(private httpClient:HttpClient) { }


  getSummaryByEmp(data:any):Observable<any>{
    return this.httpClient.post<any>(`${environment.baseUrl8088}workflowMaster-report/approverDetails`,data);
  }

  getFrequency():Observable<any>{
    return this.httpClient.get<any>(`${environment.baseUrl8099}EsopScheme/getAllFrequencyMaster`);
  }

  getByeId(id:number):Observable<any>{
    let opt= new HttpParams();
    opt= opt.append('esopSchemeId',id.toString());
    return this.httpClient.get<any>(`${environment.baseUrl8099}EsopScheme/getbyesopSchemeId`,{params:opt});
  }
}
