<ng-template #template>
    <div class="modal-header foot">
        <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16 font-700">Password
                Policy</span></h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body tab">
                <div class="tabview-contents">
                    <div class="row mt-15">
                        <div class="col-md-12">
                            <ol>
                                <li> Password should have minimum length of {{policy.minLength}} characters & maximum
                                    length
                                    of
                                    {{policy.maxLength}} characters.</li>
                                <li>Password should be alphanumeric having minimum {{policy.noOfalphUpperCaseReq}}
                                    uppercase (A-Z), {{policy.noOfalphalowerCaseReq}} lowercase (a-z),
                                    {{policy.noOfNumericReq}} numeral (0-9)
                                    and {{policy.specialCharactersRequiredCount}} special character
                                    ( {{policy.allowedSetOfSpecialChars}} ).</li>
                                <li>Not to use string of {{policy.repeatCharsCount}} or more consecutive character in
                                    password (aaa).</li>
                                <li>Not to use <span *ngIf="policy.isFirstNameAllowed == true">Firstname</span> <span
                                        *ngIf="policy.isFirstNameAllowed == true && policy.isLastNameAllowed == true">
                                        and </span> <span *ngIf="policy.isLastNameAllowed == true">Lastname</span> in
                                    the password.</li>
                                <li>Password shall expire on {{policy.expiryInDays}} day from date of password change.
                                </li>
                                <li>Three unsuccessful login attempts would lock the account,
                                    which can be unlocked by Admin only.</li>
                                <li>In case you forget the password or your password has expired click on forgot
                                    password on the login page.
                                </li>
                                <li>Change password link sent through email shall become invalid after 7 days.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer foot">
        <button _ngcontent-wxs-c616="" type="button" tooltip="Button" (click)="modalRef.hide()"
            class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted  " tabindex="0"
            ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b _ngcontent-wxs-c616=""><i
                    class="fa fa-thumbs-up" aria-hidden="true"></i>
            </b> OK
        </button>
    </div>
</ng-template>

<div class="login-background">
    <div class="auth">
        <div class="row">
            <div class="col-md-5" *ngIf="tab1">
                <div class="card new-white-card">
                    <div class="text-center p-10">
                        <a class="header-brand">
                            <span>
                                <img src="assets/images/Paysquare_logo.png" class="mt-2 img wid-6">
                                <span class="font-20 logo-new-purple">Pay </span>
                                <span class="font-20 logo-dark-green margin-left-6">square</span>
                            </span>
                        </a>
                    </div>
                    <hr class="new-hr">
                    <div class="card-body">
                        <div class="card-title text-center name-frgt-pass">Forgot Password</div>
                        <div class="float-left mt-4">
                            <label class="custom-switch mt-5">
                                <span class="form-label mt-2">Email </span>&nbsp;&nbsp;
                                <input (click)="getSelectedNature($event.target.checked)" type="checkbox"
                                    name="custom-switch-checkbox" class="custom-switch-input"
                                    [checked]="natureValueFlag">
                                <span class="custom-switch-indicator"></span>&nbsp;&nbsp;
                                <span class="form-label mt-2">Mobile No. </span>
                            </label>
                        </div>

                        <div class="form-group mt-5" *ngIf="natureValueFlag == false">
                            <input type="email" class="form-control" [(ngModel)]="email" id="exampleInputEmail1"
                                aria-describedby="emailHelp" [ngClass]="{'is-invalid' : mobile=='' ? true:false}"
                                placeholder=" Email">
                        </div>
                        <div class="form-group mt-5" *ngIf="natureValueFlag == true">
                            <input type="text" class="form-control" [(ngModel)]="mobile" id="exampleInputEmail1"
                                aria-describedby="emailHelp" [ngClass]="{'is-invalid' : mobile=='' ? true:false}"
                                (keypress)="keyPress($event)" placeholder=" Mobile No." />
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <div class="float-right">
                                    <label class=" text-center form-label"><a [routerLink]="['/login']"
                                            href="javascript:void(0)" class="font-10 text-link">Back to
                                            Login</a></label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button type="button" (click)="submitMobileOrMail()"
                                    class="btn btn-primary btn-block font600 new-purple-btn">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="p-2 copyright-footer">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label font-10 under-hover"> Copyright @ 2023</label>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="float-right font-10 under-hover">Contact Us | Privacy Policy</div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-5" *ngIf="tab2">
                <div class="card new-white-card">
                    <div class="text-center p-10">
                        <a class="header-brand">
                            <span>
                                <img src="assets/images/Paysquare_logo.png" class="mt-2 img wid-6">
                                <span class="font-20 logo-new-purple">Pay </span>
                                <span class="font-20 logo-dark-green margin-left-6">square</span>
                            </span>
                        </a>
                    </div>
                    <hr class="new-hr">
                    <div class="card-body">
                        <div class="card-title text-center name-frgt-pass">Forgot Password</div>
                        <div *ngIf="!captchaShow" class="form-group mt-5">
                            <input type="number" [disabled]="isResendOTP" [(ngModel)]="otp" name="otp"
                                class="form-control" placeholder="OTP">
                            <a class="float-left  font-10 mt-3 red">Time Left: {{minLeft}}m :
                                {{secLeft}}s</a>
                            <a *ngIf="timeLeft==0?true:false" class="float-right font-10 text-link mt-3"
                                (click)="resendOTP()">Resend
                                OTP
                            </a>

                        </div>
                        <div *ngIf="captchaShow" class="form-group mt-5">
                            <label class="form-label">Captcha</label>
                            <input type="text" class="form-control text-center mb-2" name="example-disabled-input"
                                [value]="equation" readonly="">
                            <input type="text" [(ngModel)]="resultGiven" class="form-control"
                                placeholder="Enter Captcha">

                        </div>
                        <div class="row mt-5">
                            <div class="col-md-4"></div>
                            <div class="col-md-4 mt-3">
                                <button (click)="submitCaptchaOne()" type="button"
                                    class="btn btn-primary btn-block font600 new-purple-btn">Submit</button>
                                <label class="text-center form-label mt-4"><a [routerLink]="['/login']"
                                        href="javascript:void(0)" class="font-10 text-link">Back to Login</a></label>
                            </div>
                        </div>
                    </div>
                    <div class="p-2 copyright-footer">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label font-10 under-hover"> Copyright @ 2023 </label>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="float-right font-10 under-hover">Contact Us | Privacy Policy</div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-5" *ngIf="tab3">
                <div class="card new-white-card">
                    <div class="text-center p-10">
                        <a class="header-brand">
                            <span>
                                <img src="assets/images/Paysquare_logo.png" class="mt-2 img wid-6">
                                <span class="font-20 logo-new-purple">Pay </span>
                                <span class="font-20 logo-dark-green margin-left-6">square</span>
                            </span>
                        </a>
                    </div>
                    <hr class="new-hr">
                    <div class="card-body">
                        <div class="card-title text-center name-frgt-pass">Forgot Password</div>
                        <div class="form-group mt-5">

                            <input type="text" class="form-control" [(ngModel)]="motherName" id="exampleInputEmail1"
                                aria-describedby="emailHelp" placeholder=" Mother's Name">
                        </div>
                        <div class="form-group">
                            <input data-provide="datepicker" placeholder="User Date of Birth" [(ngModel)]="dateOfBirth"
                                [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }" data-date-autoclose="true"
                                class="form-control" bsDatepicker>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="float-right">
                                    <label class=" text-center form-label "><a [routerLink]="['/login']"
                                            href="javascript:void(0)" class="font-10 text-link">Back to
                                            Login</a></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-4"></div>
                        <div class="col-md-4 ">
                            <button type="button" (click)="validateMNameAndDOB()"
                                class="btn btn-primary btn-block font600 new-purple-btn">Submit</button>

                        </div>
                    </div>
                    <div class="p-2 copyright-footer-20">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label font-10 under-hover"> Copyright @ 2023</label>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="float-right font-10 under-hover">Contact Us | Privacy Policy</div>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-5" *ngIf="tab4">
                <div class="card new-white-card ht-490">
                    <div class="text-center p-10">
                        <a class="header-brand">
                            <span>
                                <img src="assets/images/Paysquare_logo.png" class="mt-2 img wid-6">
                                <span class="font-20 logo-new-purple">Pay </span>
                                <span class="font-20 logo-dark-green margin-left-6">square</span>
                            </span>
                        </a>
                    </div>
                    <hr class="new-hr">
                    <form [formGroup]="form">
                        <div class="card-body">
                            <div class="card-title text-center name-frgt-pass">Change Password</div>
                            <div class="input-group">
                                <div class="col-md-12">
                                    <label class="form-label">Enter New Password <i class="fa fa-info-circle ml-3 mt-3"
                                            (click)="pwpolicy(template)"></i></label>
                                </div>
                                <input type="password" class="form-control" name="password" formControlName="newPass"
                                    *ngIf="!isShowNewPassword" placeholder="Enter New Password"
                                    (keypress)="passwordValidation($event.target.value,$event)"
                                    (keyup)="passwordValidation($event.target.value,$event)"
                                    [ngClass]="{'is-invalid' : isPasswordValid == false}">
                                <i class="fa fa-eye icon-css eye-pswd-icon" (click)="onClickNewPassEye()"
                                    *ngIf="!isShowNewPassword"
                                    [ngClass]="isShowNewPassword == true ? 'fa fa-eye-slash':'fa fa-eye'"></i>

                                <input type="test" class="form-control" name="password" formControlName="newPass"
                                    *ngIf="isShowNewPassword" placeholder="Enter New Password"
                                    (keypress)="passwordValidation($event.target.value,$event)"
                                    (keyup)="passwordValidation($event.target.value,$event)"
                                    [ngClass]="{'is-invalid' : isPasswordValid == false}">
                                <i class="fa fa-eye icon-css eye-pswd-icon" (click)="onClickNewPassEye()"
                                    *ngIf="isShowNewPassword"
                                    [ngClass]="isShowNewPassword == true ? 'fa fa-eye-slash':'fa fa-eye'"></i>
                            </div>
                            <div class="input-group" *ngIf="this.form.get('newPass').value?.length > 0">
                                <div class="col-md-6">
                                    <label *ngIf="isPasswordValid == false" class="form-label font-12 text-red">Weak
                                        Password</label>
                                    <label *ngIf="isPasswordValid == true" class="form-label font-12 text-green">Strong
                                        Password</label>
                                </div>
                            </div>
                            <div class="input-group">
                                <div class="col-md-12 mt-4">
                                    <label class="form-label">Confirm New Password</label>

                                </div>
                                <input type="password" class="form-control" name="password" appBlockCopyPaste
                                    formControlName="confirmPass" *ngIf="!isShowConfirmPassword"
                                    placeholder="Enter Confirm Password"
                                    (keypress)="confirmPasswordValidation($event.target.value,$event)"
                                    (keyup)="confirmPasswordValidation($event.target.value,$event)"
                                    [ngClass]="{'is-invalid' : this.form.get('confirmPass').value == null || this.form.get('confirmPass').value == '' || this.form.get('confirmPass').value != this.form.get('newPass').value }">
                                <i class="fa fa-eye icon-css eye-pswd-icon" (click)="onClickConfirmPassEye()"
                                    *ngIf="!isShowConfirmPassword"
                                    [ngClass]="isShowConfirmPassword == true ? 'fa fa-eye-slash':'fa fa-eye'"></i>

                                <input type="test" class="form-control" name="password" formControlName="confirmPass"
                                    appBlockCopyPaste *ngIf="isShowConfirmPassword" placeholder="Enter Confirm Password"
                                    (keypress)="confirmPasswordValidation($event.target.value,$event)"
                                    (keyup)="confirmPasswordValidation($event.target.value,$event)"
                                    [ngClass]="{'is-invalid' : this.form.get('confirmPass').value == null || this.form.get('confirmPass').value == '' || this.form.get('confirmPass').value != this.form.get('newPass').value }">
                                <i class="fa fa-eye icon-css eye-pswd-icon" (click)="onClickConfirmPassEye()"
                                    *ngIf="isShowConfirmPassword"
                                    [ngClass]="isShowConfirmPassword == true ? 'fa fa-eye-slash':'fa fa-eye'"></i>
                            </div>
                            <div class="input-group" *ngIf="this.form.get('confirmPass').value?.length > 0">
                                <div class="col-md-10">
                                    <label *ngIf="this.form.get('confirmPass').value != this.form.get('newPass').value"
                                        class="form-label font-12 text-red">Enter correct
                                        confirm Password</label>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <label class="form-label font-12">Captcha</label>
                                    <input type="text" class="form-control" name="example-disabled-input"
                                        placeholder="Disabled.." formControlName="equation" readonly="">
                                </div>
                                <div class="col-md-1 mt-30 text-center">
                                    =
                                </div>
                                <div class="col-md-5 font-12">
                                    <label class="form-label">Answer</label>
                                    <input type="text" class="form-control mt-10" name="example-disabled-input"
                                        autocomplete="off" placeholder="Enter Captcha" formControlName="resultTotal">
                                </div>
                            </div>
                            <div class="mt-4">
                                <div class="col-md-12">
                                    <div class="float-left">
                                        <button type="button" tooltip="Save"
                                            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2"
                                            tabindex="0" ng-reflect-tooltip="Button" (click)="saveChangePass()"
                                            [disabled]="isPasswordValid == false || this.form.get('resultTotal').value == null || this.form.get('resultTotal').value == '' || this.form.get('confirmPass').value != this.form.get('newPass').value"
                                            [ngClass]="{'disabled-btn-bg-green' : isPasswordValid == false || this.form.get('resultTotal').value == null || this.form.get('resultTotal').value == '' || this.form.get('confirmPass').value != this.form.get('newPass').value , 'btn-bg-green': isPasswordValid == true && this.form.get('resultTotal').value != null && this.form.get('resultTotal').value != '' && this.form.get('confirmPass').value == this.form.get('newPass').value }"><b><i
                                                    class="fa fa-floppy-o"></i></b> Save </button>

                                        <button type="button" tooltip="Reset"
                                            class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left mr-2 ng-star-inserted  "
                                            tabindex="0" ng-reflect-tooltip="Button" (click)="reset()"
                                            ng-reflect-router-link="/loan/rescheduleRequest"><b><i
                                                    class="fa fa-refresh "></i></b> Reset </button>
                                    </div>
                                    <div class="float-right">
                                        <label class=" text-center form-label "><a [routerLink]="['/login']"
                                                href="javascript:void(0)" class="font-10 text-link">Back to
                                                Login</a></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="p-2 copyright-footer-30">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label font-10 under-hover"> Copyright @ 2023</label>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="float-right font-10 under-hover">Contact Us | Privacy Policy</div>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7 ml-5">
            <carousel [isAnimated]="true">
                <slide>
                    <img src="assets/images/lgn16.svg" class="img-fluid ht-400" alt="first slide">
                </slide>
                <slide>
                    <img src="assets/images/undraw_data_trends_re_2cdy.svg" class="img-fluid ht-400" alt="second slide">
                </slide>
                <slide>
                    <img src="assets/images/undraw_finance_re_gnv2.svg" class="img-fluid ht-400" alt="third slide">
                </slide>
            </carousel>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-header foot">
        <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16 font700">Password
                Policy</span></h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body  tab">
                <div class="tabview-contents">
                    <div class="row mt-15">
                        <div class="col-md-12">
                            <ol>
                                <li> Password should have minimum length of {{policy.minLength}} characters &
                                    maximum
                                    length
                                    of
                                    {{policy.maxLength}} characters.</li>
                                <li>Password should be alphanumeric having minimum one
                                    uppercase (A-Z), one lowercase (a-z), one numeral (0-9) and one special
                                    character
                                    ( {{policy.allowedSetOfSpecialChars}} ).</li>
                                <li>Not to use string of {{policy.repeatCharsCount}} or more consecutive character
                                    in
                                    password (aaa).</li>
                                <li>Not to use Firstname and Lastname in the password.</li>
                                <li>Password shall expire on {{policy.expiryInDays}} day from date of password
                                    change.
                                </li>
                                <li>Three unsuccessful login attempts would lock the account,
                                    which can be unlocked by Admin only.</li>
                                <li>In case you forget the password or your password has expired click on forgot
                                    password on the login page.
                                </li>
                                <li>Change password link sent through email shall become invalid after 7 days.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer foot">
        <button _ngcontent-wxs-c616="" type="button" tooltip="Button" (click)="modalRef.hide()"
            class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted  " tabindex="0"
            ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b _ngcontent-wxs-c616=""><i
                    class="fa fa-thumbs-up" aria-hidden="true"></i>
            </b> OK
        </button>
    </div>
</ng-template>