import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UserRole } from './model/userRole.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  authorizedRoutURL: any;
  constructor(private authService: AuthService,
              private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if(state.url!='/userGroupSelectPage' && state.url!='/login'){
    //  below are old code of Auth
          // check if route is restricted by role
          // if (!this.authService.checkUrlAllowed(state.url)) {
          //     // role not authorised so redirect to home page
          //     this.router.navigate(['/dashboard']);
          //     return false;
          // }
          //Below are the new Validation code
          // let value = this.authService.deObject(sessionStorage.getItem('%qr'))
          // this.authService.allowedPathVariablesOne(value).subscribe(res => {
          //   this.authorizedRoutURL = res.data.results
          //   if (this.authorizedRoutURL.indexOf(state.url) === -1) {
          //     if (value.type == 'Employee') {
          //       sessionStorage.setItem('selectedRole','')
          //       this.router.navigate(['/employee-master/employee-dashboard']);
          //     }
          //     else {
          //       sessionStorage.setItem('selectedRole',this.authService.enValue(value.roleName))
          //       this.router.navigate(['Dashboard'])
          //     }
          //     return false
          //   } else {
          //     return true;
          //   }
          // })
          // authorised so return true
          return this.roleGurd(route);
    }
  }
    // canActivate() {
      
    //   return this.canLoad();
    // }


    canLoad() {
      if (!this.authService.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      return this.authService.isLoggedIn();
    }

    roleGurd(route: ActivatedRouteSnapshot):boolean{
      const userRole = this.authService.deObject(sessionStorage.getItem('%qr')).type;
      this.authService.setUserRole(userRole);
      if(userRole === 'Admin' || userRole === 'Employee'|| userRole === 'Global' ){
        return true
      }
      return false;
    }

}
