import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { LeftMenuServiceService } from '../left-menu-service.service';

@Component({
  selector: 'app-show-child-menu',
  templateUrl: './show-child-menu.component.html',
  styleUrls: ['./show-child-menu.component.scss']
})
export class ShowChildMenuComponent implements OnInit,OnDestroy {
  childMenuData: any;
  grandchildData: any;
  role: any;
  leftMenudata: any;
  childData: any;
  grandChild: boolean=false;
  grandChildDataList: any;
  shareChildSubject: any;
  needToLoadAgain: boolean;
  isLoader:boolean = false;
  isShowLandingPage: boolean = false;
  isShowPayrollList:boolean = false;
  isShowLMSFormInputList:boolean = false;
  rowNumber:number;
  constructor(private service:LeftMenuServiceService,private router:Router,private authService:AuthService) {
    
  }

  ngOnInit(): void {
    let chd = JSON.parse(sessionStorage.getItem('chd'));
    this.grandChild =  chd
    this.getLoadData()
    this.authService.showChildMenu.subscribe((res:any)=>{
      this.getLoadData()
    })
    this.authService.changeTabShowChildMenu.subscribe((res:any)=>{
      let tempInd = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch')).rowNum;
      if(tempInd != -1){
      this.rowNumber = tempInd;
      }
    })
  }
 
  ngOnDestroy(){
    console.log('')
    // this.authService.changeTabShowChildMenu.unsubscribe();
  }

  getDataFromLocalFile(roleId,company){
    this.role =this.authService.deObject(sessionStorage.getItem('%qr'));
     this.authService.getleftMenuData(this.role.userRoleId,this.role.globalCompanyMasterId).subscribe(res=>{  
       this.leftMenudata = res.data.results;
       this.childMenuData = this.leftMenudata.filter(x=>x.menuName==sessionStorage.getItem('%qrch'))[0].childObj;      
     })
   }

  onClickChildManu(event){
 sessionStorage.setItem('%qrgch',event.childMenuName)
 sessionStorage.setItem('chd','true');
 this.rowNumber =event.rowNum
//  this.service.shareChildSubject.next(event);
 this.grandChild = true;  
    this.grandchildData = this.childMenuData.find(x=>x==event).grandChildList;
    if(this.grandchildData.length>0){
        this.grandChildDataList = this.grandchildData       
          this.grandChild = true;      
    }else{
      let selectedChildMenu = this.childMenuData.find(x=>x==event)  
      this.router.navigate([selectedChildMenu.childRouterLink]);
    }
    this.authService.showChildMenuHeader.next()
  }

  onClickGrandChildManu(child){
    this.service.shareChildSubject.next(child);
      this.router.navigate([child.routerLinkGrandChild]);
  }

  getLoadData(){
    
    this.authService.childDataForBreadcrumb.subscribe((res)=>{
      if(res.length > 0){
        this.grandChild=false;
        //     let chd = JSON.parse(sessionStorage.getItem('chd'));
        // this.grandChild =  chd
              if(this.grandChild == false){
              this.isLoader = true
              // this. authService. getChildMenuByParent().subscribe(res=>{
                this.isLoader = false
                this.childMenuData = res
                this.grandChild=false
                this.rowNumber = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch')).rowNum;
              // }, (error: any) => {
              //   this.isLoader = false
              // })
         
      }else{
        this.isLoader = true
              // this. authService. getChildMenuByParent().subscribe(res=>{
                this.isLoader = false
                this.childMenuData = res
                this.grandChild=true
                let findData  = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch'));
                this.onClickChildManu(findData)
                this.rowNumber = findData.rowNum;
              // }, (error: any) => {
              //   this.isLoader = false
              // })
      }
    
      }else{
        this.grandChild=false;
        //     let chd = JSON.parse(sessionStorage.getItem('chd'));
        // this.grandChild =  chd
              if(this.grandChild == false){
              this.isLoader = true
              this. authService. getChildMenuByParent().subscribe(res=>{
                this.isLoader = false
                this.childMenuData = res.data.results[0] 
                this.grandChild=false
                this.rowNumber = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch')).rowNum;
              }, (error: any) => {
                this.isLoader = false
              })
         
      }else{
        this.isLoader = true
              this. authService. getChildMenuByParent().subscribe(res=>{
                this.isLoader = false
                this.childMenuData = res.data.results[0] 
                this.grandChild=true
                let findData  = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch'));
                this.onClickChildManu(findData)
                this.rowNumber = findData.rowNum;
              }, (error: any) => {
                this.isLoader = false
              })
      }
    
         
      //   if(this.grandChild==true){
      //     this.isLoader = true
      //         this. authService. getChildMenuByParent().subscribe(res=>{
      //           this.isLoader = false
      //           this.childMenuData = res.data.results[0] 
      //           this.grandChild=true
      //           let findData  = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch'));
      //           this.onClickChildManu(findData)
      //         }, (error: any) => {
      //           this.isLoader = false
      //         })
      //     this.isShowLandingPage = false;
      //       this.isShowPayrollList = false;
      //       this.isShowLMSFormInputList = false;
      //   }else{
      //     this.isLoader = true
      //   this. authService. getChildMenuByParent().subscribe(res=>{
      //     this.isLoader = false
      //     this.childMenuData = res.data.results[0] 
      //     this.grandChild=false
      //   }, (error: any) => {
      //     this.isLoader = false
      //   })
      // }
      }
    })
       
  }

}
