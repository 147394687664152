import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeftMenuServiceService {
  grandData: any;
  shareBreadcrumb = new Subject<any>();
  shareChildSubject = new Subject();
  reverseShareGrandChildFlag = new Subject();
  leftMenuDataClear = new Subject<any>();
  employeeInfo: any;

  constructor(http:HttpClient) { }

shareData = new Subject();
changeAuthorization = new Subject ();
shareToData(data){
  this.grandData = data;
}
getShareToData(){
  return this.grandData;
}

getMenu(): Array<any> {
  const menu = [
    { name: 'home', path: './home', children: [] },
    { 
      name: 'stores', 
      path: './stores', 
      children: [
        {
          name: 'books',
          path: './books',
          children: [
            { 
              name: 'THE FELLOWSHIP OF THE RING Details',
              path: './book1'
            },
            { 
              name: 'THE RETURN OF THE KING',
              path: './book2'
            },
            { 
              name: 'Harry Potter and the Philosopher\'s Stone',
              path: './book3'
            },
            { 
              name: 'Harry Potter and the Chamber of Secrets',
              path: './book4'
            }              
          ]
        }
      ] 
    },
  ];

  return menu;
}
setEmployeedata(emp){
  this.employeeInfo=emp;
}

getEmployeedata(){
  return this.employeeInfo;
}

}
