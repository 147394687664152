import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AssetCommonService {

  constructor(private httpClient:HttpClient) { }

  getAssetTransactionDetails(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.baseUrl8083}AssetTransaction/getByNew/${id}`);
  }

  getEmpMasterDetails(id):Observable<any> {
    // let token = this.authService.getJwtToken()
    // const headers = new HttpHeaders()
    //   .set('X-Authorization', token);
    return this.httpClient.get<any>(`${environment.baseUrl8082}employee-fin-details/${id}`);
  }

  postApproverDetails(data):Observable<any> {
    return this.httpClient.post<any>(`${environment.baseUrl8088}workflowMaster-report/approverDetails`, data);
  }

  updateAssetTransactionRepair(data):Observable<any> {
    return this.httpClient.put<any>(`${environment.baseUrl8083}AssetTransaction/update`, data);
  }

  getAssetFinanceDetails(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.baseUrl8083}AssetTransaction/getCompanyBankInfo/${id}`);
  }
}
