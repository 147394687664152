<div class="modal-header foot">
    <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple-new font-16 font700">Privacy Policy
        </span></h5>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body tab">
            <div class="row clearfix">
                <div class="col-md-12">
                    <h6 class="text-center"><u>Privacy Policy</u></h6>
                    <p> This Notice explains how we at Paysquare Consultancy Ltd. collect, use, retain and disclose
                        Personal Data collected from you on our website.</p>
                    <p><b>WHO WE ARE?</b></p>
                    <p>We are Paysquare Consultancy Ltd. incorporated in India in 2000 with headquarter at Pune. We
                        help organizations in end to end payroll processing. You can find more about us at <a
                            href="http://paysquare.com/"><u>http://paysquare.com/</u></a></p>
                    <p>We play the role of a data controller when we collect Personal Data from our employees, and
                        the Personal Data we get from you on your visit to our website.</p>
                    <p>We play the role of data processor when we process or otherwise use Personal Data via or on
                        behalf of a third party, and the Personal Data you provide us, as a registered user.</p>
                    <p><b>OUR COMMITMENT TO COMPLY WITH APPLICABLE LAWS</b></p>
                    <p>We commit and hence our Personal Data handling practices are continually reviewed to ensure
                        compliance with the following laws and regulations:</p>
                    <p>1. The Indian Information Technology (Amendment) Act, 2008 and the Information Technology
                        (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules,
                        2011.</p>
                    <p>2. Singapore’s Personal Data Protection Act, 2012.</p>
                    <p>3. European Union General Data Protection Regulation (GDPR), 2018.</p>
                    <p><b>PERSONAL DATA COLLECTION</b></p>
                    <p>We collect your Personal Data in the following ways:</p>
                    <p>A. Through information provided by you when you visit our website:</p>
                    <p>1. You provide us Personal Data such as Name, email, phone number, company name when you
                        visit our home page and request for a demo;</p>
                    <p>2. You provide Personal Data such as Name, email, website when you visit out blogs and leave
                        a reply;</p>
                    <p>3. You provide Personal Data such as name, email, phone, website, company, company website
                        when you visit our Reach out or Contact us Page;</p>
                    <p>4. You provide Personal Data such as Name, Phone Number, E-mail, address when you visit our
                        Careers page;</p>
                    <p>B. Through information provided by you as registered user of our DeliziaHR:</p>
                    <p>1. You provide us your PI such as User code, Password;</p>
                    <!-- <p>C. Through Information provided by you as a new user of our ITR Portal:</p>
                    <p>1. You provide us your PI such as Reference Code, E-mail Id, User name, Password, PAN, Mobile
                        Number;</p> -->
                    <p>C. Through cookies on our Website:</p>
                    <p>The cookies collect information about you when you use our website. For more information
                        about cookies, please refer to the ‘Cookies Information’ section on this Notice.</p>
                    <p><b>PERSONAL DATA PURPOSE & USAGE</b></p>
                    <p>We use the collected Personal Data:</p>
                    <p>A. To provide our products and services to you,</p>
                    Legal basis-
                    <p>1. The processing is necessary to perform the agreement we have with you or to take steps to
                        enter into an agreement with you.</p>
                    <p>2. The processing is necessary for compliance with a legal obligation we have.</p>
                    <p>3. The processing is necessary for the purposes of a Legitimate Interest (defined below)
                        pursued by us.</p>
                    <p>B. To provide and manage your account(s) and our relationship with you,</p>
                    <p>Legal basis-</p>
                    <p>1. The processing is necessary to perform the agreement we have with you or to take steps to
                        enter into an agreement with you.</p>
                    <p>2. The processing is necessary for compliance with a legal obligation we have.</p>
                    <p>3. The processing is necessary for the purposes of a Legitimate Interest pursued by us.</p>
                    <p>C. To keep you informed about products and services you hold with us and to send you
                        information about products or services (including those of other companies) which may be of
                        interest to you,</p>
                    <p>Legal basis-</p>
                    <p> 1. The processing is necessary for the purposes of a Legitimate Interest pursued by us.</p>
                    <p>D. To contact you,</p>
                    <p>Legal basis-</p>
                    <p>1. The processing is necessary to perform the agreement we have with you or to take steps to
                        enter into an agreement with you.</p>
                    <p>2. The processing is necessary for compliance with a legal obligation we have.</p>
                    <p>3. The processing is necessary for the purposes of a Legitimate Interest pursued by us.</p>
                    <p>E. To meet our regulatory and reporting obligations,</p>
                    <p>Legal basis-</p>
                    <p>1. The processing is necessary to perform the agreement we have with you or to take steps to
                        enter into an agreement with you.</p>
                    <p>2. The processing is necessary for compliance with a legal obligation we have.</p>
                    <p>3. The processing is necessary for the purposes of a Legitimate Interest pursued by us.</p>
                    <p><b>RETENTION AND DISPOSAL OF PERSONAL DATA</b></p>
                    <p>We retain and use your Personal Data as long as it is necessary for fulfilment of the
                        respective purposes as specified in this section and/or to meet our legal and regulatory
                        obligations. We shall dispose the Personal Data as per the Company Policy.</p>
                    <p><b>CHOICE & CONSENT</b></p>
                    <p>On our website, every page that collects Personal Data from you, states the purpose of
                        collection of Personal Data. If you agree to the purpose, you can provide your Personal Data
                        and thus give your consent to us to use the Personal Data provided for the stated purpose.
                    </p>
                    <p>If, at any point, we decide to use your Personal Data for a different purpose, we will notify
                        you and obtain your consent.</p>
                    <p>You can withdraw your consent at any point in time by reaching out to us as at details
                        mentioned in the ‘Contact Us‘ section. Consequences of withdrawal will be communicated to
                        you and, on your agreement, your request for withdrawal will be processed.</p>
                    <p><b>COOKIE INFORMATION</b></p>
                    <p>Cookies are small text files that are placed on your computer by websites that you visit.
                        They are widely used in order to make websites work, or work more efficiently, as well as to
                        provide information to the owners of the website.</p>
                    <p>The cookies collected by Paysquare fall under the following two categories-</p>
                    <p>a. Strictly Necessary Cookies: Necessary cookies help make a website usable by enabling basic
                        functions like page navigation and access to secure areas of the website. The website cannot
                        function properly without these cookies</p>
                    <p>b. Statistic Cookies: Statistic cookies help website owners to understand how visitors
                        interact with websites by collecting and reporting information anonymously</p>
                    <p>You can find more detailed information about cookies and adjusting your browser settings to
                        refuse the use of cookies by visiting<a href="http://www.allaboutcookies.org/manage-cookies/">
                            <u>http://www.allaboutcookies.org/manage-cookies/</u></a></p>
                    <p>Each of these third parties are governed by their own privacy policies. Please go through
                        their privacy policies to get information on their Privacy Practices.</p>
                    <p><b>DISCLOSURE</b></p>
                    <p>Personal Data collected from you will not be disclosed to any third-party. Exception to this
                        is when such disclosure is necessary for compliance to any legal and/or law enforcement
                        obligations. We will do so after following necessary due processes. If anything, other than
                        this is to be disclosed, will obtain consent from the user.</p>
                    <p> We will make every effort, where possible, that such mandated disclosures are communicated
                        to you.</p>
                    <p><b>ACCESS & CORRECTION</b></p>
                    <p>When Personal Data is directly collected from you, you can update or correct your Personal
                        Data by updating your profile details using your registered account. This is applicable only
                        for registered users.</p>
                    <p>For other visitors to the website who have provided their Personal Data through online forms
                        like ‘Contact Us’, they can call support contact number available on our website to get
                        Personal Data corrected.</p>
                    <p><b>DATA SECURITY</b></p>
                    <p>We are committed to protecting your Personal Data in our custody.</p>
                    <p>We take reasonable steps to ensure appropriate physical, technical and managerial safeguards
                        are in place to protect your Personal Data from misuse, loss, unauthorized access,
                        modification, disclosure, transmission, and deletion. We have implemented reasonable
                        security practices and procedures through Information Security Policies. We retain and use
                        Personal Data for purpose and use as specified in ‘Personal Data Purpose and Usage’ section
                        of this Privacy Notice</p>
                    <p><b>DATA TRANSFER</b></p>
                    <p>Paysquare shall not transfer Personal Data received from you to any other entity or geography
                        unless it is necessary for the performance of the lawful contract between Paysquare or any
                        person on its behalf and you.</p>
                    <p>You can contact us to obtain a copy of safeguards provided to the Personal Data which is
                        transferred. Contact details are provided in the “Contact Us” section in this Privacy
                        Notice.</p>
                    <p><b>LINKS TO OTHER WEBSITES</b></p>
                    <p>Our Website has links that redirects you to our social networking pages of Facebook, Twitter,
                        LinkedIn, Google +, and YouTube and other sites where we may have pages in future. These
                        pages are hosted on separate sites that are governed by their own privacy policies. Please
                        go through their privacy policies to get information on their Privacy Practices.</p>
                    <p><b>DO NOT CALL</b></p>
                    <p>We may use your Contact Number to call, SMS, Fax you for our services and other legitimate
                        interest. At any time you wish to opt-out of receiving any calls, SMS, or faxes, or register
                        your Singapore phone number with DNC Register in Singapore, you can inform us on the details
                        provided under the Contact Us section of this Privacy Notice.</p>
                    <p><b>COMMON RIGHTS PROVIDED TO YOU UNDER GDPR and PDPA</b></p>
                    <p>In case you are accessing the website from within the European Economic Area and/or
                        Singapore, we are committed to providing you with the following rights where you can
                        exercise:</p>
                    <table>
                        <tr>
                            <th>Rights under GDPR</th>
                            <th>How we facilitate your Rights?</th>
                        </tr>
                        <tr>
                            <td>Right to be Informed</td>
                            <td>We aid this right to you through our Privacy Notice.<p>Our Privacy Notice
                                    communicates how we handle your Personal Data collected through our website. For
                                    any further information, feel free to contact us.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Right of Access</td>
                            <td>Under GDPR, you can contact us to get access to your Personal Data along with
                                information regarding its purpose, disclosure, categories of Personal Data, storage
                                period, source of collection of Personal Data, confirmation whether they are being
                                processed or not.<p>Under PDPA, in case where we are a Controller, and you access
                                    our website from Singapore, you may request a copy of ways in which your
                                    Personal Data has been used or may have been used or disclosed by us within a
                                    year from the date of your raising of the request.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Right to Rectification</td>
                            <td>As a registered user, you can rectify your provided details anytime by accessing
                                your account and editing the details.<p>As a visitor, you can call support contact
                                    number available on our website to get the information corrected.</p>
                            </td>
                        </tr>
                    </table>
                    <p><b>ADDITIONAL RIGHTS PROVIDED TO YOU UNDER GDPR</b></p>
                    <p>In case you are accessing the website from within the European Economic Area, we are
                        committed to providing you with the following additional rights where you can exercise:</p>
                    <table>
                        <tr>
                            <th>Right to Erasure</th>
                            <th>You can contact us to get your Personal Data deleted or erased</th>
                        </tr>
                        <tr>
                            <td>Right to Restriction of Processing</td>
                            <td>You can contact us and request restriction of further processing in case:<p>1. You
                                    contest the accuracy of Personal Data provided to us and you like to restrict
                                    its processing for a period enabling us to verify the Personal Data.</p>
                                <p>2.You think the processing of Personal Data is unlawful and request restriction
                                    instead of deletion. </p>
                            </td>
                        </tr>
                        <tr>
                            <td>Right to Data Portability</td>
                            <td>You can contact us to get your Personal Data in a structured, machine-readable
                                format and if you want, can have it transmitted to another organisation.</td>
                        </tr>
                        <tr>
                            <td>Right to Object</td>
                            <td>We send you emails containing our marketing material when you opt in for it at the
                                time of registration. You can choose to opt out of receiving marketing material from
                                us anytime by clicking on the unsubscribe link at the bottom of the marketing email
                                that you receive from us.</td>
                        </tr>
                        <tr>
                            <td>Right not to be subject to Automated individual decision-making, including profiling
                            </td>
                            <td>We do not process your Personal Data for automated decision-making including
                                profiling.</td>
                        </tr>
                        <tr>
                            <td>Right to lodge a complaint with the Supervisory Authority</td>
                            <td>You have the right to lodge a complaint with a supervisory authority, in the
                                particular Member State of your habitual residence, place of work or place of an
                                alleged infringement of the General Data Protection Regulation.</td>
                        </tr>
                    </table>
                    <p></p>
                    <p>We will verify your identity when you exercise these rights. For exercising your rights and
                        getting information about any limitations which apply on them, you can reach out to us at
                        the contact details given below.</p>
                    <p><b>NOTIFICATION OF CHANGES</b></p>
                    <p> We reserve the right to amend this Privacy Notice at any time. We will update our Privacy
                        Notice with a new effective date stated at the beginning of it. Our processing of your
                        Personal Data will be governed by the practices set out in that new version of the Privacy
                        Notice from its effective date onwards.</p>
                    <p><b>CONTACT US</b></p>
                    <p>For any further queries and compliants related to privacy, or exercising your rights under
                        GDPR, or any further information on our Privacy Practices and Policies, you could reach us
                        at: <a href="crm@paysquare.com"><u>crm@paysquare.com</u></a></p>
                    <p>Your queries will be addressed within one month of receipt of such requests. In case of any
                        delay in addressing such requests, the delay will be communicated to you within a month of
                        receipt of request.</p>
                </div>
            </div>
        </div>
    </div>
</div>