import { Injectable } from '@angular/core';
import {Route,PreloadingStrategy} from '@angular/router'
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomePrealoadStrategiesService implements PreloadingStrategy{
  preload(route:Route,load: ()=>Observable<any>):Observable<any>{
    return route.data && route.data['preload'] ? load().pipe(delay(1000)) : of(null);
  }
}
