import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AlertServiceService } from './../../../core/services/alert-service.service';
import { AuthService } from './../auth.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  user: any;
  Group: any;
  userType:any;

  constructor(private authService: AuthService,
    private alertService: AlertServiceService,
    private router: Router) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!window.navigator.onLine){
      this.alertService.sweetalertError('Please check your internet connection, then try again');
    }

    ////Temporary added the url check for http://localhost:8081/runSelenium Used in payment tracking clear component on proceed button click

    if (this.authService.getJwtToken()&&request.url!=="http://localhost:8081/runSelenium") {
      request = this.addToken(request, this.authService.getJwtToken());
    }
    return next.handle(request).pipe(catchError((error) => {
      const type = error.status;
      const message = error.message
      switch (type) {
        case 401: {
          this.authService.logout();
          this.alertService.sweetalertError('Your session is expired please login again !!');
          this.router.navigate(['/login']);
          break;
        }
        case 400: {
          if(environment.baseUrl8080 + 'users/check' != error.url){
          if (error.error.status.code == '401') {
            this.authService.logout();
            this.alertService.sweetalertError('Your session is expired please login again !!');
            this.router.navigate(['/login']);
          }
        }
          break;
        }
      }
      return throwError(error);
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    this.user = this.authService.getprivileges()
    let grp = this.authService.deObject(sessionStorage.getItem('%qr'));
    if(grp){
    if(grp.hasOwnProperty('groupName')){
      this.Group = grp.groupName
    }else{
      this.Group= this.user.UserDetails.groupName
    }

    if(grp.loginCompanyName == 'PaysquareDefault' && grp.type == 'Global'){
      this.userType = 'PaysquareUser'
    }else if(grp.loginCompanyName == 'PaysquareDefault' && grp.type == 'Employee'){
      this.userType = 'PaysquareUserEmployee'
    }else if(grp.loginCompanyName == 'PaysquareDefault' && grp.type == 'Admin'){
      this.userType = 'PaysquareUserAdmin'
    }else if(grp.loginCompanyName != 'PaysquareDefault' && grp.type == 'Employee'){
      this.userType = 'NonPaysquareUserEmployee'
    }else{
      this.userType = 'NonPaysquareUser'
    }
  }
    return request.clone({
      setHeaders: {
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/json, text/plain, */*',
        'X-Authorization': token,
        'Access-Control-Max-Age': '600',
        'X-TenantID': this.Group? this.Group :'PaysquareDefault',
        'X-UserType': this.userType? this.userType :'PaysquareUser'
      },
    });
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt);
          return next.handle(this.addToken(request, token.jwt));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
