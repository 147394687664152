import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../../../environments/environment';
import Swal from 'sweetalert2';

import { EmpMasterLandingPageServiceService } from '../../components/emp-master-landing-page/emp-master-landing-page-service.service';



@Injectable({
  providedIn: 'root'
})
export class SharedInformationService {
  routingLinkSequence: any[];

  constructor(private httpClient: HttpClient,private employeelandingPageService:EmpMasterLandingPageServiceService) { }

  getLocationInformation() {

    return this.httpClient.get(environment.baseUrl8082 + 'location-information/country/', { headers: { 'X-TenantId': 'PaysquareGlobal' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getCountryCodes() {

    return this.httpClient.get(environment.baseUrl8082 + 'location-information/phone-code/', { headers: { 'X-TenantId': 'PaysquareGlobal' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getAddressFromPIN(postalCode) {

    return this.httpClient.get(environment.baseUrl8082 + 'pincode-details-check/' + postalCode, { headers: { 'X-TenantId': 'PaysquareGlobal' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getGlobalLabels(selectedLanguage) {

    return this.httpClient.get(environment.baseUrl8083 + 'custom-labelsettings/1/' + selectedLanguage, { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getAdditionalFields() {

    return this.httpClient.get(environment.baseUrl8083 + 'additional-fieldsettings/PersonalInfo/2', { headers: { 'X-TenantId': 'PaysquareDefault' } })
      .pipe(map((res: any) => {
        return res;
      }))
  }

  sweetalertMasterSuccess(message: any, text: any) {
    Swal.fire({
      title: message,
      text: text,
      //  showCloseButton: true,
      showCancelButton: false,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      icon: 'success',
      timer: 3000,
      // timerProgressBar: true,
    })
  }

  sweetalertError(message: any) {
    Swal.fire({
      title: message,
      showCloseButton: true,
      showCancelButton: false,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      icon: 'error',
      timer: 3000,
      timerProgressBar: true,
    })
  }

  sweetalertWarning(message: any) {
    Swal.fire({
      title: message,
      showCloseButton: true,
      showCancelButton: false,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      background: '#e68a00',
      icon: 'warning',
      timer: 5000,
      timerProgressBar: true,
    })
  }

  clearSession() {
    sessionStorage.removeItem('companyId');
    sessionStorage.removeItem('fullName');
    sessionStorage.removeItem('LastTransaction');
    sessionStorage.removeItem('joiningDate');
    sessionStorage.removeItem('noticePeriodMonthModel');
    sessionStorage.removeItem('noticePeriodMonthModel');
    sessionStorage.removeItem('employementJoiningInfoId');
    sessionStorage.removeItem('editEmployeeMasterId');
    sessionStorage.removeItem('editEmployeeJoiningDate');
    sessionStorage.removeItem('editEmployeeFullName');
    sessionStorage.removeItem('editEmployeeGroupCompanyId');
    sessionStorage.removeItem('editEmployeeGroupCompanyId');
    sessionStorage.removeItem('payrollAreaId');
    sessionStorage.removeItem('jobInformationCompanyName');
    sessionStorage.removeItem('jobInformationPayrollAreaCode');
    sessionStorage.removeItem('editEmployeeEmployeeCode');

    sessionStorage.removeItem('editEmployeePayrollAreaCode');
    sessionStorage.removeItem('editEmployeePayrollAreaId');
    sessionStorage.removeItem('editEmployeeMasterPayrollAreaId');
    sessionStorage.removeItem('editEmployeeGroupCompanyId');
    sessionStorage.removeItem('editEmployeeMasterEmployeeExitInfoId');
    sessionStorage.removeItem('birthDate');
    this.employeelandingPageService.sharedData = [];
  }
  clearLocalStorage() {

    localStorage.removeItem('employeeMasterId')
    localStorage.removeItem('fullName')
    localStorage.removeItem('adEmp')
    localStorage.removeItem('birthDate')
    localStorage.removeItem('reportingToPayrollAreaCode')
    localStorage.removeItem('companyId')
    localStorage.removeItem('establishmentMasterId');
    localStorage.removeItem('joiningDate');
    localStorage.removeItem('noticePeriodMonthModel');
    localStorage.removeItem('noticePeriodMonthModel');
    localStorage.removeItem('editEmployeeJoiningDate');

    localStorage.removeItem('employementJoiningInfoId');
    localStorage.removeItem('editEmployeeGroupCompanyId');

    localStorage.removeItem('payrollAreaId');
    localStorage.removeItem('jobInformationCompanyName');
    localStorage.removeItem('jobInformationPayrollAreaCode');
    localStorage.removeItem('editEmployeeEmployeeCode');
    localStorage.removeItem('editEmployeePayrollAreaCode');
    this.employeelandingPageService.sharedData = [];
  }
  routingLink() {
    const natureToOrderId = {
      'Admin Landing Page': 1,
      'Personal Information': 2,
      'Employment Information': 3,
      'Payroll Area Information': 4,
      'Job Information': 5,
      'Compliance Information': 6,
      'Contact Information': 7,
      'Bank Information': 8,
      'Identity Information': 9,
      'Previous Employment Information': 10,
      'Family Information': 11,
      'Education & Skill Information': 12,
      'Document': 13,
    };

    this.routingLinkSequence = [];
    this.routingLinkSequence = JSON.parse(sessionStorage.getItem('%lml'));
    this.routingLinkSequence = this.routingLinkSequence.filter(x => x.menuName.toLowerCase() === 'Employee Master'.toLowerCase());
    this.routingLinkSequence[0].childObj.forEach((element) => {
      const headNatureLower = element.childMenuName;
      const orderId = natureToOrderId[headNatureLower] || 14; //if not found then by default 14
      element.orderId = orderId;
    });
    this.routingLinkSequence[0].childObj.sort((a, b) => a.orderId - b.orderId);
  }
}
