import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class PayrollInputsService {
  public apiUrl = environment.baseUrl8084;
  public apiUrlEmployeeDetails = environment.baseUrl8082;
  apiUrl8086 = environment.baseUrl8086;
  apiUrl83 = environment.baseUrl8083;
  api80 = environment.baseUrl8080;
  public employeeList = [];
  companySessionData: any;

  globalCompanyMasterIdList: any;
  constructor(private http: HttpClient, private authService: AuthService) { }

  getFinancialMasterSummary():Observable<any> {

    return this.http.get<any>(this.apiUrl + 'NonRecurringTransactionGroup');
  }

  getFinancialMasterSchedule():Observable<any> {

    let params = new FormData();
    params.append('nonRecurringTransactionGroupId', '3');
    return this.http.post<any>(this.apiUrl + 'NonRecurringTransactionSchedule/NonRecurringTransactionScheduleEMP', params);
  }

  getFinancialMasterTransaction():Observable<any> {
    let params = new FormData();
    params.append('nonRecurringTransactionScheduleId', '11');
    params.append('nonRecurringTransactionGroupId', '3');
    console.log(params);
    return this.http.post<any>(this.apiUrl + 'NonRecurringTransactionScheduleRemarkHistory/NonRecurringTransactionScheduleRemarkHistorybyScheduleId', params);
  }

  postLeavePage(data):Observable<any> {
    return this.http.post<any>(this.apiUrl + 'leave-master', data);
  }

  public getAllEmployeeDetails():Observable<any> {
    // return this.http.get(this.apiUrlEmployeeDetails + 'employee-master')
    //   .pipe(map((res: any) => {
    //     return res;
    //   }));
    // this.companySessionData = this.authService.getComapnyFromSession()
    // this.globalCompanyMasterIdList = this.companySessionData.globalCompanyMasterId
    let company = this.authService.deValue(sessionStorage.getItem('globalCompanyList'));
    let commaSepratedList;
    company = company.split(',').length == 1 ? JSON.parse(company) : company;
    company.split(',').forEach((x, index) => {
      if (index == 0) {
        commaSepratedList = x
      } else {
        commaSepratedList = x + ',' + commaSepratedList
      }
    }
    )
    let data = {
      // "globalCompanyMasterIdList": this.globalCompanyMasterIdList,
      "globalCompanyMasterIdList": commaSepratedList,
      // "pageNo": this.page,
      // "recordCount": this.size
    }
    return this.http.post<any>(this.apiUrlEmployeeDetails + 'EmployeeMasterLandingPage/getLandingPageSummary',data);
  }
  // ...................Pagination..................................
  getEmployeeDetailswithPagination(empSearch): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    return this.http.get<any>(this.apiUrlEmployeeDetails+'EmployeeSearchNew/searchEmp/'+empSearch, { headers: headers })
  }
 /*** Get Single report list with auth */
 getSingleReportsData(data):Observable<any>{
  return this.http.post<any>(this.api80 + `rolePrivilegeReportMatrix/getReportByRoleName`, data)
}

  /** get Report Category data */
  getReportCategoryData(): Observable<any> {
    return this.http.get<any>(this.apiUrl83 + `report-category-master/details`)
  }
  getPayrollWiseEmployeeList(payrollArea): Observable<any> {
    return this.http.get<any>(this.apiUrlEmployeeDetails + 'payroll-information/payrollAreaWiseEmployee/' + payrollArea);
  }

  getEmployeeListArray() {
    return this.employeeList;
  }

  setEmployeeListArray(emp) {
    this.employeeList = emp;
  }

  getEmployeeList(): Observable<any> {
    return this.http.get<any>(this.apiUrlEmployeeDetails + 'employee-master/employee-info');
  }

  getEmployeeListByGroupId(data): Observable<any> {
    //return this.http.get<any>(this.apiUrlEmployeeDetails + 'EmployeeMasterLandingPage/getAllDetails');
    return this.http.post<any>(this.apiUrlEmployeeDetails + 'EmployeeMasterLandingPage/getLandingPageSummary', data);
    //  return this.http.post<any>(this.apiUrlEmployeeDetails + 'EmployeeMasterLandingPage/getLandingPageSummaryMultipleParameter', data);
  }

  getEmployeeListByGroupIdTotalRecordCount(data): Observable<any> {
    return this.http.post<any>(this.apiUrlEmployeeDetails + 'EmployeeMasterLandingPage/getLandingPageSummary1', data);
  }


  getPayrollList(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'payrollArea-details');
    //return this.http.get<any>('https://dev.deliziahr.com:8084/hrms/v1/payrollArea-details');
  }

  getAWSuppPayrollList(businessCycleId): Observable<any> {
    return this.http.get<any>(this.apiUrl8086 + 'business-cycle/getpayrollareaAndCompanyName/' + businessCycleId)
  }

  getAWSuppHeadList(businessCycleId): Observable<any> {
    return this.http.get<any>(this.apiUrl8086 + 'head-creation/getHeadList/' + businessCycleId)
  }

  /** Reports */
  getSalaryRegister(data): Observable<any> {
    // return this.http.get<any>(environment.baseUrl8094 + 'salaryregister')
    return this.http.post<any>(environment.baseUrl8094 + 'salaryregister', data)
  }
  saveinterestaccretion(documentFile: any, data: any) {
    let formData = new FormData();
    for (let file of documentFile) {
      formData.append('attachedFile', file);
    }
    formData.append('annualAccretionRequestDto', JSON.stringify(data));
    // return this.http.post(environment.baseUrl8083 + 'annualAccretion/add',formData)
    //   .pipe(map((res: any) => {
    //       return res;
    //     }));

        return this.http.post<any>(environment.baseUrl8083 + 'annualAccretion/add',formData)
  }
  updateinterestaccretion(documentFile: any, data: any) {
    let formData = new FormData();
    for (let file of documentFile) {
      formData.append('attachedFile', file);
    }
    formData.append('annualAccretionRequestDto', JSON.stringify(data));
    // return this.http.put(environment.baseUrl8083 + 'userguide-details/intaccDocumentUpload',formData)
    //   .pipe(map((res: any) => {
    //       return res;
    //     }));

    return this.http.put<any>(environment.baseUrl8083 + 'annualAccretion/update',formData)
  }
  getAllSummery(id):Observable<any> {
    return this.http
      .get<any>(environment.baseUrl8083 + 'annualAccretion/getAll/'+ id)
    
  }
  getAlljoiningdate(id):Observable<any> {
    return this.http
      .get<any>(environment.baseUrl8083 + 'annualAccretion/joiningDate/'+ id)
    
  }
  deleteHelpDocumenthelpgroup(data) {
    return this.http.put(environment.baseUrl8083 + 'userguide-details/deleteById/' + data, '') 
  }

}
