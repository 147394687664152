import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class PayrollServiceService {
  public apiUrl84 = environment.baseUrl8084;
  public apiUrl93 = environment.baseUrl8093;
  public apiUrl83 = environment.baseUrl8083;
  public apiUrl82 = environment.baseUrl8082;
  public apiUrl95 = environment.baseUrl8095;
  public apiUrl92 = environment.baseUrl8092;

  constructor(private _HTTP: HttpClient) { }
  /** Get All Payroll area details */
  getPayrollAreaDetails(): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl84 + 'payrollArea-details');
  }
  /** Get Buisness cycle details */
  getBussinessCycleDetails(): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl84 + 'businessCycleDefinition/GETAllCycle')
  }
  /** Get Company Details */
  getCompanyDetails(): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl84 + 'groupCompany/')
  }
  /** Get PHG Details */
  getPhgDetails(): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl84 + 'headGroup')
  }
  /**Get Net SDM Details */
  getSdmDetails(): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl83 + 'source-derived-matrix/summary/1')
  }
  /** Get LMS Details */
  getLmsMasterDetails(): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl93 + 'LMSAreaMaster/get');
  }
  /** Get Currancy  */
  getCurrencyList(): Observable<any> {
    return this._HTTP.get(this.apiUrl82 + 'currency-information/symbol')
  }
  /** Get Language */
  getLanguagesList(): Observable<any> {
    return this._HTTP.get(this.apiUrl82 + 'language-information/name')
  }
  /** Save payroll area details */
  savePayrollArea(data): Observable<any> {
    return this._HTTP.post<any>(this.apiUrl84 + 'payrollArea-details', data)
  }
  /** Update payroll area details */
  updatePayrollArea(data, id): Observable<any> {
    return this._HTTP.put<any>(this.apiUrl84 + 'payrollArea-details/' + id, data)
  }

   /** Update payroll area details */
   getPayrollAreaHistory(data): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl84 + 'payrollArea-details?payrollAreaCode='+data)
  }
  /** Get SDM master wise derived list */
  getAllDerivedSDM(sdmMasterId): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl83 + 'source-derived-matrix/derived-master/' + sdmMasterId)
  }
  /** Get Expense Reimbursement Arae Details */
  getExpenseReimbursementAreaDetails(): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl95 + 'expenseReimbrusement-Area-Master/details');
  }
  /** Get Expense Reimbursement Arae Details */
  getJVGroupDetails(): Observable<any> {
    return this._HTTP.get<any>(this.apiUrl84 + 'JvConfiguration/getSummaryJvConfiguration');
  }
  /** Get Income tax template  Details */
  getIncomeTaxTemplateDetails() {
   // return this._HTTP.get<any>(this.apiUrl84 + 'InvestmentSectionWiseSetting/summaryGroup');
   return this._HTTP.get<any>(this.apiUrl84 + 'SettingDeatils/GetAllGroupLevel');
  }
   /** Get Payment tracking  Details */
   getPaymentTrackingGroupDetails() {
    return this._HTTP.get<any>(this.apiUrl92 + 'PaymentTrackingGroup/getAllPaymentTrackingGroup');
  }
}
