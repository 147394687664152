import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class DateCompareService {

  constructor(private httpClient:HttpClient) { }

  dateCompare(comparType:string,fromDate:string,toDate:string):boolean{
    switch(comparType){
      case '>':
        return fromDate>toDate?true:false;
      case  '<':
        return fromDate<toDate?true:false;
      case '>=':
        return fromDate>=toDate?true:false; 
      case '<=':
        return fromDate<=toDate?true:false;     
    }
  }
}
