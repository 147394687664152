import { HostListener, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class RestrictInspectService {
  
    
  constructor() {

    document.addEventListener('contextmenu', function (e) {
        // console.log(e)
        // console.log(window.location)
        // console.log(window.location.host)
        // || window.location.host == 'localhost:4200'
        if (window.location.host == 'www.deliziahr.com' || window.location.host == 'uat.deliziahr.com' ) {
          e.preventDefault();
        }
      });

   }

   onClick(){
    document.addEventListener('contextmenu', function (e) {
        if (window.location.host == 'www.deliziahr.com' || window.location.host == 'uat.deliziahr.com') {
          e.preventDefault();
        }
      });
   }
  
   @HostListener('document:keydown', ['$event'])
   handleKeyboardEvent(e: KeyboardEvent) {
     if (window.location.host == 'www.deliziahr.com' || window.location.host == 'uat.deliziahr.com') {
       if (e.key === 'F12') {
         return false;
       }
       if (e.ctrlKey && e.shiftKey && e.key === "I") {
         return false;
       }
       if (e.ctrlKey && e.shiftKey && e.key === "C") {
         return false;
       }
       if (e.ctrlKey && e.shiftKey && e.key === "J") {
         return false;
       }
       if (e.ctrlKey && e.shiftKey && e.key === "H") {
        return false;
      }
       if (e.ctrlKey && e.key == "U") {
         return false;
       }
     }
     return true;
   }
}