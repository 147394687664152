<div id="header_top" class="header_top">
  <div class="container">
      <div class="hleft">
          <a href="javascript:void(0)" class="nav-link user_btn" [routerLink]="['/dashboard']"><img class="avatar" src="../assets/images/Paysquare_logo.png" alt="" data-toggle="tooltip" data-placement="right" title="Delizia HR" /></a>
          <div class="dropdown">
          </div>
      </div>
      <div class="hright">
          <div class="dropdown">
              <a href="javascript:void(0)" class="nav-link icon settingbar" (click)="toggleSettingMenu()"><i
                    class="fa fa-gear fa-spin" data-toggle="tooltip" data-placement="right"
                    title="Settings"></i></a>
              <a href="javascript:void(0)" class="nav-link user_btn" (click)="toggleUserMenu()"><img class="avatar" src="../assets/images/abbott_logo.png" alt="" data-toggle="tooltip" data-placement="right" title="Company Info" /></a>
              <a href="javascript:void(0)" (click)="toggleMenu()" class="nav-link icon menu_toggle"><i
                    class="fa  fa-align-left"></i></a>
          </div>
      </div>
  </div>
</div>
<div id="rightsidebar" class="right_sidebar">
  <a href="javascript:void(0)" (click)="toggleSettingMenu()" class="p-3 settingbar float-right"><i
        class="fa fa-close"></i></a>
  <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item"><a class="nav-link" data-toggle="tab" href="javascript:void(0)" (click)="onTab('1')" [ngClass]="{'active': chatTab}" aria-expanded="true">Settings</a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="tab" (click)="onTab('2')" [ngClass]="{'active': contactTab}" aria-expanded="false">Activity</a>
      </li>
  </ul>
  <div class="tab-content">
      <div role="tabpanel" class="tab-pane vivify fadeIn active" [ngClass]="{'active': chatTab}" id="Settings" aria-expanded="true">
          <div class="mb-4">
              <h6 class="font-14 font-weight-bold text-muted">Font Style</h6>
              <div class="custom-controls-stacked font_setting">
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" (click)="onFontStyle(1)" name="font"
                        value="font-opensans" [checked]="fontSelect == 'font-opensans' ? 'checked': null">
                    <span class="custom-control-label">Open Sans Font</span>
                </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" (click)="onFontStyle(2)" name="font"
                        value="font-montserrat" [checked]="fontSelect=='font-montserrat' ? 'checked': null">
                    <span class="custom-control-label">Montserrat Google Font</span>
                </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" (click)="onFontStyle(3)" name="font"
                        value="font-roboto" [checked]="fontSelect == 'font-roboto' ? 'checked': null">
                    <span class="custom-control-label">Robot Google Font</span>
                </label>
              </div>
          </div>
          <div class="mb-4">
              <h6 class="font-14 font-weight-bold text-muted">Dropdown Menu Icon</h6>
              <div class="custom-controls-stacked arrow_option">
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="marrow" value="arrow-a">
                    <span class="custom-control-label">A</span>
                </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="marrow" value="arrow-b">
                    <span class="custom-control-label">B</span>
                </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="marrow" value="arrow-c" checked="">
                    <span class="custom-control-label">C</span>
                </label>
              </div>
              <h6 class="font-14 font-weight-bold mt-4 text-muted">SubMenu List Icon</h6>
              <div class="custom-controls-stacked list_option">
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" (click)="onSubMenuIcon(1)"
                        [checked]="menuIconSelect=='list-a' ? 'checked': null" name="listicon" value="list-a"
                        checked="">
                    <span class="custom-control-label">A</span>
                </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" (click)="onSubMenuIcon(2)"
                        [checked]="menuIconSelect=='list-b' ? 'checked': null" name="listicon" value="list-b">
                    <span class="custom-control-label">B</span>
                </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" (click)="onSubMenuIcon(3)"
                        [checked]="menuIconSelect=='list-c' ? 'checked': null" name="listicon" value="list-c">
                    <span class="custom-control-label">C</span>
                </label>
              </div>
          </div>
          <div>
              <h6 class="font-14 font-weight-bold mt-4 text-muted">General Settings</h6>
              <ul class="setting-list list-unstyled mt-1 setting_switch">
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Night Mode</span>
                        <input type="checkbox" (click)="onGeneralSetting(1,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-darkmode" [checked]="toggle1 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Fix Navbar top</span>
                        <input type="checkbox" (click)="onGeneralSetting(2,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-fixnavbar" [checked]="toggle2 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Header Dark</span>
                        <input type="checkbox" (click)="onGeneralSetting(3,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-pageheader" [checked]="toggle3 ? 'checked' : null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Min Sidebar Dark {{toggle4}}</span>
                        <input type="checkbox" (click)="onGeneralSetting(4,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-min_sidebar" [checked]="toggle4 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Sidebar Dark</span>
                        <input type="checkbox" (click)="onGeneralSetting(5,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-sidebar" [checked]="toggle5 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Icon Color</span>
                        <input type="checkbox" (click)="onGeneralSetting(6,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-iconcolor" [checked]="toggle6 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Gradient Color</span>
                        <input type="checkbox" (click)="onGeneralSetting(7,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-gradient" [checked]="toggle7 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Box Shadow</span>
                        <input type="checkbox" (click)="onGeneralSetting(8,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-boxshadow" [checked]="toggle8 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">RTL Support</span>
                        <input type="checkbox" (click)="onGeneralSetting(9,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-rtl" [checked]="toggle9 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
                  <li>
                      <label class="custom-switch">
                        <span class="custom-switch-description">Box Layout</span>
                        <input type="checkbox" (click)="onGeneralSetting(10,$event)" name="custom-switch-checkbox"
                            class="custom-switch-input btn-boxlayout" [checked]="toggle10 ? 'checked': null">
                        <span class="custom-switch-indicator"></span>
                    </label>
                  </li>
              </ul>
          </div>
          <hr>
          <div class="form-group">
              <label class="d-block">Storage <span class="float-right">77%</span></label>
              <div class="progress progress-sm">
                  <div class="progress-bar wid77" role="progressbar" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <button type="button" class="btn btn-primary btn-block mt-3">Upgrade Storage</button>
          </div>
      </div>
      <div role="tabpanel" class="tab-pane vivify fadeIn" [ngClass]="{'active': contactTab}" id="activity" aria-expanded="false">
          <ul class="new_timeline mt-3">
              <li>
                  <div class="bullet pink"></div>
                  <div class="time">11:00am</div>
                  <div class="desc">
                      <h3>Attendance</h3>
                      <h4>Computer Class</h4>
                  </div>
              </li>
              <li>
                  <div class="bullet pink"></div>
                  <div class="time">11:30am</div>
                  <div class="desc">
                      <h3>Added an interest</h3>
                      <h4>“Volunteer Activities”</h4>
                  </div>
              </li>
              <li>
                  <div class="bullet green"></div>
                  <div class="time">12:00pm</div>
                  <div class="desc">
                      <h3>Developer Team</h3>
                      <h4>Hangouts</h4>
                      <ul class="list-unstyled team-info margin-0 p-t-5">
                          <li><img src="../assets/images/xs/avatar1.jpg" alt="Avatar"></li>
                          <li><img src="../assets/images/xs/avatar2.jpg" alt="Avatar"></li>
                          <li><img src="../assets/images/xs/avatar3.jpg" alt="Avatar"></li>
                          <li><img src="../assets/images/xs/avatar4.jpg" alt="Avatar"></li>
                      </ul>
                  </div>
              </li>
              <li>
                  <div class="bullet green"></div>
                  <div class="time">2:00pm</div>
                  <div class="desc">
                      <h3>Responded to need</h3>
                      <a href="javascript:void(0)">“In-Kind Opportunity”</a>
                  </div>
              </li>
              <li>
                  <div class="bullet orange"></div>
                  <div class="time">1:30pm</div>
                  <div class="desc">
                      <h3>Lunch Break</h3>
                  </div>
              </li>
              <li>
                  <div class="bullet green"></div>
                  <div class="time">2:38pm</div>
                  <div class="desc">
                      <h3>Finish</h3>
                      <h4>Go to Home</h4>
                  </div>
              </li>
          </ul>
      </div>
  </div>
</div>
<div class="theme_div">
  <div class="card">
      <div class="card-body">
          <ul class="list-group list-unstyled">
              <li class="list-group-item mb-2">
                  <p>Default Theme</p>
                  <a href="../main/index.html"><img src="../assets/images/themes/default.png" class="img-fluid" /></a>
              </li>
              <li class="list-group-item mb-2">
                  <p>Night Mode Theme</p>
                  <a href="../dark/index.html"><img src="../assets/images/themes/dark.png" class="img-fluid" /></a>
              </li>
              <li class="list-group-item mb-2">
                  <p>RTL Version</p>
                  <a href="../rtl/index.html"><img src="../assets/images/themes/rtl.png" class="img-fluid" /></a>
              </li>
              <li class="list-group-item mb-2">
                  <p>Theme Version2</p>
                  <a href="../theme2/index.html"><img src="../assets/images/themes/theme2.png" class="img-fluid" /></a>
              </li>
              <li class="list-group-item mb-2">
                  <p>Theme Version3</p>
                  <a href="../theme3/index.html"><img src="../assets/images/themes/theme3.png" class="img-fluid" /></a>
              </li>
              <li class="list-group-item mb-2">
                  <p>Theme Version4</p>
                  <a href="../theme4/index.html"><img src="../assets/images/themes/theme4.png" class="img-fluid" /></a>
              </li>
              <li class="list-group-item mb-2">
                  <p>Horizontal Version</p>
                  <a href="../horizontal/index.html"><img src="../assets/images/themes/horizontal.png" class="img-fluid" /></a>
              </li>
          </ul>
      </div>
  </div>
</div>
<div class="user_div">
  <h5 class="brand-name mb-4">Company info <a href="javascript:void(0)" (click)="toggleUserMenu()" class="user_btn"><i
            class="icon-logout"></i></a>
  </h5>
  <div class="card">
      <div class="card-body">
          <div class="media">
              <img class="avatar avatar-xl mr-3" src="../assets/images/abbott_logo.png" alt="avatar">
              <div class="media-body">
                  <h5 class="m-0">Abbott India Limited</h5>
                  <br>
                  <p class="text-muted mb-1">Corporate Office 16th Floor, Godrej BKC, Bandra-Kurla Complex, Bandra (East), Mumbai – 400 051 India
                  </p>
                  <ul class="social-links list-inline mb-0 mt-2">
                      <li class="list-inline-item"><a href="javascript:void(0)" title="" data-toggle="tooltip" data-original-title="Facebook"><i class="fa fa-facebook"></i></a></li>
                      <li class="list-inline-item"><a href="javascript:void(0)" title="" data-toggle="tooltip" data-original-title="Twitter"><i class="fa fa-twitter"></i></a></li>
                      <li class="list-inline-item"><a href="javascript:void(0)" title="" data-toggle="tooltip" data-original-title="1234567890"><i class="fa fa-phone"></i></a></li>
                      <li class="list-inline-item"><a href="javascript:void(0)" title="" data-toggle="tooltip" data-original-title="@skypename"><i class="fa fa-skype"></i></a></li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
<div id="left-sidebar" class="sidebar ">
  <h5 class="brand-name">Delizia-HR
  </h5>
  <nav id="left-sidebar-nav" class="sidebar-nav">
      <ul class="metismenu">
          <li class="g_heading">Directories</li>
          <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
              <a [routerLink]="['/admin-approval/admindashboard']" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" class="has-arrow arrow-c" aria-expanded="true"><i
                    class="icon-rocket"></i><span>Dashboard</span></a>
          </li>
          <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
              <a [routerLink]="['/admin-approval/approvals']" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" class="has-arrow arrow-c" aria-expanded="true"><i
                  class="icon-credit-card"></i><span>Approvals</span></a>
          </li>
          <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
              <a [routerLink]="['/uploadexcel/uploadexcelhome']" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" class="has-arrow arrow-c" aria-expanded="true"><i
                class="icon-credit-card"></i><span>Upload Excel</span></a>
          </li>
          <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
              <a [routerLink]="['/employeelist/employeemasterlist']" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" class="has-arrow arrow-c" aria-expanded="true"><i
              class="icon-users"></i><span>Employee List</span></a>
          </li>
      </ul>
  </nav>
</div>