<!-- <app-childmenu></app-childmenu> -->
<div class="ng-star-inserted">
    <div class="ml-5 section-body mt-3" *ngIf="isadhoc || issupplimentary">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div>
                        <div>
                            <div class="row">
                                <div class="col-md-2 mr-4 ng-star-inserted" *ngIf="isArrear">
                                    <div class="stats-box sales" (click)="navigateToFinancialMaster()">
                                        <i class="fa-coins fa-solid font-14 border-radius-css"></i><br>
                                        <div class="statistics-header text-center mt-1 cursor-pointer">Financial Master
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 mr-4 ng-star-inserted">
                                    <div class="stats-box sales" (click)="navigateToNRAmt()">
                                        <i class="fa-circle-dollar-to-slot fa-solid font-14 border-radius-css"></i><br>
                                        <div class="statistics-header text-center mt-1 cursor-pointer">NRA </div>
                                    </div>
                                </div>
                                <div class="col-md-2 mr-4 ng-star-inserted">
                                    <div class="stats-box sales" (click)="navigateToNRQty()">
                                        <i class="fa-clone fa-solid font-14 border-radius-css"></i><br>
                                        <div class="statistics-header text-center mt-1 cursor-pointer">NRQ </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-body mt-3">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <br>
                <div class="row " *ngIf="users">
                    <div class="col-md-12">
                        <div class="table-responsive Custom_Table">
                            <!-- <p-table [value]="users" [rows]="size"  #dt1
                                [rowsPerPageOptions]="[10,25,50,100,200]"
                                [totalRecords]="totalRecords"
                                [paginator]="true"
                                [lazy]="true" 
                                (onLazyLoad)="paginate($event)"
                                [showCurrentPageReport]=true
                                [currentPageReportTemplate]="'{currentPage} of {totalPages}'"
                                [globalFilterFields]="['employeeCode','fullName','payrollAreaCode','companyName','establishment','type','grade','department','designation']"> -->
                                <!-- (onPage)="paginate($event)" -->
                                <p-table [value]="users" [rows]="10" [paginator]="true" #dt1
                                [showCurrentPageReport]=true
                                [currentPageReportTemplate]="'{currentPage} of {totalPages}'"
                                [rowsPerPageOptions]="[5,10,25,50,100,200]"
                                [globalFilterFields]="['employeeCode','fullName','payrollAreaCode','companyName','establishment','type','grade','department','designation','joiningDate','lastWorkingDate']">
                                <ng-template pTemplate="caption">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="col-md-6 float-right">
                                                <div class="d-flex">
                                                    <span class="p-input-icon-left p-ml-auto">
                                                        <i class="pi pi-search search1-css"></i>
                                                        <input pInputText type="text"
                                                            (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                                            placeholder="Search"/>
                                                    </span>
                                                    <i (click)="SummaryexportAsXLSX()" class="cursor-css"  
                                                        class="fa fa-file-excel-o excel-icon-css ml-2 mt-1 mr-1"></i>
                                                    <select class="form-control custom-select width-css"
                                                        (change)="filterApply($event.target.value)">
                                                        <option value=''>Select Filter</option>
                                                        <option value='{{data.filterMasterId}}'
                                                            *ngFor="let data of allFilterSummary">{{data.name}}
                                                        </option>
                                                    </select>
                                                    <i (click)="showFilterUI()" class="cursor-css"   
                                                        class="fa fa-filter text-purple fa-2x"></i>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label class="custom-control custom-checkbox checkbox-center">
                                                    <input type="checkbox"
                                                        (click)="employeeListCopyPaste($event.target,pasteEmployee)"
                                                        name="example-inline-radios" class="custom-control-input" />
                                                    <span class="custom-control-label color-css">Employee
                                                        List</span>
                                                </label>
                                            </div>
                                            <div class="col-md-4"></div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th rowspan="2">
                                            <span *ngIf="payrollListLength == 1">
                                                <input type="checkbox"
                                                (change)="getAllSelectedEmployee($event.target)">
                                            </span>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Emp. <br>Code
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="employeeCode"
                                                        pSortableColumn="employeeCode"></p-sortIcon>
                                                    <p-columnFilter type="text" field="employeeCode" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Emp. <br>Name
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="fullName"
                                                        pSortableColumn="fullName"></p-sortIcon>
                                                    <p-columnFilter type="text" field="fullName" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Joining <br>Date
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="joiningDate"
                                                        pSortableColumn="joiningDate"></p-sortIcon>
                                                    <p-columnFilter type="date" field="joiningDate" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Leaving <br>Date
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="lastWorkingDate"
                                                        pSortableColumn="lastWorkingDate"></p-sortIcon>
                                                    <p-columnFilter type="date" field="lastWorkingDate" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Payroll <br>Area
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="payrollAreaCode"
                                                        pSortableColumn="payrollAreaCode"></p-sortIcon>
                                                    <p-columnFilter type="text" field="payrollAreaCode" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Company <br>Name
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="companyName"
                                                        pSortableColumn="companyName"></p-sortIcon>
                                                    <p-columnFilter type="text" field="companyName" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <!-- <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Emp. Type
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="employeeType"
                                                        pSortableColumn="employeeType"></p-sortIcon>
                                                    <p-columnFilter type="text" field="employeeType" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th> -->
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Establishment
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="establishment"
                                                        pSortableColumn="establishment"></p-sortIcon>
                                                    <p-columnFilter type="text" field="establishment" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Department
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="department"
                                                        pSortableColumn="department"></p-sortIcon>
                                                    <p-columnFilter type="text" field="department" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Grade
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="grade" pSortableColumn="grade"></p-sortIcon>
                                                    <p-columnFilter type="text" field="grade" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th rowspan="2">
                                            <div class="p-d-flex p-jc-between p-ai-center">
                                                Designation
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="designation1"
                                                        pSortableColumn="designation1"></p-sortIcon>
                                                    <p-columnFilter type="text" field="designation1" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-user1 let-rowIndex="rowIndex">
                                    <tr class="p-selectable-row">
                                        <td class="width-css1">
                                            <input type="checkbox"
                                                (change)="getSelectedEmployee(user1,$event.target,rowIndex)"
                                                [checked]="user1.active">
                                        </td>
                                        <td class="bg-color-light_gray">
                                            <label class="form-lable mb-0">{{user1.employeeCode}}</label>
                                        </td>
                                        <td class="bg-color-light_gray"><label
                                                class="form-lable mb-0">{{user1.fullName}}</label>
                                        </td>
                                        <td class="bg-color-light_gray"><label
                                            class="form-lable mb-0">{{user1.joiningDate | date:'dd-MMM-yyyy'}}</label>
                                        </td>
                                        <td class="bg-color-light_gray"><label
                                            class="form-lable mb-0">{{user1.lastWorkingDate | date:'dd-MMM-yyyy'}}</label>
                                        </td>
                                        <td class="bg-color-light_gray">
                                            <label class="form-lable mb-0">{{user1.payrollAreaCode}} </label>
                                        </td>
                                        <td class="bg-color-light_gray">
                                            <label class="form-lable mb-0"> {{user1.companyName}}</label>
                                        </td>
                                        <!-- <td class="bg-color-light_gray">
                                            <label class="form-lable mb-0">{{user1.employeeType}} </label>
                                        </td> -->
                                        <td class="bg-color-light_gray">
                                            <label class="form-lable mb-0">{{user1.establishment}}</label>
                                        </td>
                                        <td class="bg-color-light_gray">
                                            <label class="form-lable mb-0">{{user1.department}}</label>
                                        </td>
                                        <td class="bg-color-light_gray">
                                            <label class="form-lable mb-0">{{user1.grade}}</label>
                                        </td>
                                        <td class="bg-color-light_gray">
                                            <label class="form-lable mb-0">{{user1.designation1}}</label>
                                        </td>
                                    </tr>
                                </ng-template>

                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="15" class="font-14 font500 text-center text-red"><span>No
                                                Data
                                                Available</span></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-filters *ngIf="display" (filterEvent)="filterEventHander($event)" (AllFiltersEvent)="getAllFilterSummary()"></app-filters>
<ng-template #template>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel">Add-Query</h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="custom-controls-stacked">
                                <span class="d-flex"> <label class="form-label mr-55">On
                                        Behalf of Employee's</label>
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="BehalfOfEmployee"
                                            name="querydata1" (click)="onBehalf('yes')">
                                        <span class="custom-control-label">Yes</span>
                                    </label>
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="BehalfOfEmployee"
                                            name="querydata1" (click)="onBehalf('no')">
                                        <span class="custom-control-label">No</span>
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-25">
                            <div class="custom-controls-stacked">
                                <span class="d-flex"> <label class="form-label mr-15">Same
                                        Content For All Employee</label>
                                    <label class="custom-control custom-radio custom-control-inline ">
                                        <input type="radio" class="custom-control-input"
                                            [disabled]='sameContentViewFlag' id="sameContent" name="querydata2"
                                            (click)="sameContent('yes')">
                                        <span class="custom-control-label">Yes</span>
                                    </label>
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input"
                                            [disabled]='sameContentViewFlag' id="sameContent" name="querydata2"
                                            (click)="sameContent('no')">
                                        <span class="custom-control-label">No</span>
                                    </label></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" tooltip="Proceed" (click)="modalRef.hide();addQueryGeneration() "
            [routerLink]="['/admin-query-generation']"
            [disabled]="onBehalfValue == '' || sameContentValue == '' &&  selectedEmpLength > 1"
            [ngClass]="{'disabled-btn-bg-green' :onBehalfValue == '' || sameContentValue == '' &&  selectedEmpLength > 1 }"
            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left float-right"><b><i
                    class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></b> Proceed</button>
        <button type="button" tooltip="Cancel" (click)="modalRef.hide()" data-dismiss="modal" aria-label="Close"
            class="custom_button custom_btn bg-btn-red btn-labeled btn-labeled-left"><b><i class="fa fa-times"></i></b>
            Cancel</button>
    </div>
</ng-template>
<ng-template #pasteEmployee>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel">Employee List</h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row  ">
                    <div class="col-md-12">
                        <textarea (paste)="employeeListPasteData($event)" rows="3" cols="60"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Proceed</button>
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>