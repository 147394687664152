import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']

})
export class ChangepasswordComponent implements OnInit {
  public modalRef: BsModalRef;
  captcha: any;
  policy: any;
  form: FormGroup;
  result: any;
  userData: any;
  newPass: any;
  confirmPass: any;
  captchaAnswer: any;
  regex: any;
  equation: any;
  resultTotal: any = '';
  validate: any;
  confirmPassVAlidate: boolean;
  reusltValidate: boolean;
  isPasswordValid: boolean = false;
  isShowNewPassword: boolean = false;
  isShowConfirmPassword: boolean = false;
  constructor(private modalService: BsModalService,
    private service: AuthService,
    private formBuilder: FormBuilder,
    private alertService: AlertServiceService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      newPass: new FormControl('', Validators.required),
      confirmPass: new FormControl('', Validators.required),
      captchaAnswer: new FormControl(''),
      equation: new FormControl(''),
      resultTotal: new FormControl('', Validators.required)
    });
  }
  value1: string;
  value2: string = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
  value3: string;
  value4: string;

  ngOnInit(): void {
    this.getNewCaptch();
    this.getPasswordPolicy();
    this.userData = this.service.getprivileges();
  }
  pwpolicy(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );

  }
  //To get New Captcha 
  getNewCaptch(): any {
    this.service.getCaptcha().subscribe(res => {
      this.captcha = res.data.results[0];
      this.form.get('equation').setValue(this.captcha.equation);
      this.result = this.captcha.result
    })
  }
  getPasswordPolicy(): any {
    this.service.getPassPolicy().subscribe(res => {
      this.policy = res.data.results[0];
      this.regex = this.policy.regex;
      this.regex = JSON.stringify(this.policy.regex)
      if (this.regex.charAt(0) === '"' && this.regex.charAt(this.regex.length - 1) === '"')
        this.regex = this.regex.substr(1, this.regex.length - 2)
    })
  }
  changePass(evt) {
    if (evt == "Strong") {
      this.validate = true;
    } else {
      this.confirmPassVAlidate = false;
    }
  }
  changeConfirmPass(evt) {
    if (evt == "Strong" && (this.form.get('newPass').value == this.form.get('confirmPass').value)) {
      this.confirmPassVAlidate = true;
    } else {
      this.confirmPassVAlidate = false;
    }
  }
  save() {
    if (this.form.get('resultTotal').value != this.captcha.result) {
      this.form.get('equation').reset();
      this.form.get('resultTotal').reset();
      this.alertService.sweetalertError('Please Enter Correct Answer')
      this.getNewCaptch();
      return
    } else {
      console.log(this.form.getRawValue());
      let data = {
        newPassword: this.form.get('confirmPass').value,
        userId: this.userData.sub,
        capcha:this.captcha.equation,
        solution:this.result
      }
      this.service.changePassword(data).subscribe(res => {
        console.log('changePassword Result', res);
        this.alertService.sweetalertMasterSuccess('Password Updated Successfully', '')
        // this.service.getLogout().subscribe((res: any) => {
          this.service.logout();
        // }, (error: any) => {
        //   this.alertService.sweetalertError(error["error"]["status"]["messsage"]);
        // })
        this.reset();
        this.getNewCaptch();
      })
    }
  }
  reset() {
    this.form.reset();
    let element: HTMLElement = document.getElementById('p') as HTMLElement
    this.getNewCaptch();
  }

  changeClass(evt, e) {
    console.log(e)
    console.log(this.form.get('newPass'))
    console.log()
    if (evt.target.className == 'ng-tns-c422-2 ng-star-inserted pi pi-eye-slash') {
      console.log('hide')
    }
  }

  onClickNewPassEye() {
    this.isShowNewPassword = !this.isShowNewPassword;
  }
  onClickConfirmPassEye() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  passwordValidation(val, evt) {
    debugger
    let value
    if (evt.type != 'keyup') {
      value = val.concat(evt.key)
    } else {
      value = val
    }
    if (evt.key != 'Enter') {
      if (value?.length <= this.policy.maxLength) {
        let specialCharsArr = [];
        specialCharsArr = this.policy.allowedSetOfSpecialChars.split(',')
        console.log('specialCharsArr', specialCharsArr)
        let i = 0
        let character: any = ''
        let numericCount = 0;
        let upperCaseCount = 0;
        let lowerCaseCount = 0;
        let specialCharCount = 0;
        while (i < value?.length) {
          character = value.charAt(i);
          if (!isNaN(character * 1)) {
            numericCount++;
          } else {
            let ind = specialCharsArr.findIndex(e => e == character)
            if (ind != -1) {
              specialCharCount++
            } else {
              if (character == character.toUpperCase()) {
                upperCaseCount++;
              }
              if (character == character.toLowerCase()) {
                lowerCaseCount++;
              }
            }
          }
          i++;
        }
        let repCount = 1
        let j = 0
        if (this.policy.repeatCharsCount > 0) {
          while (j < value?.length) {
            character = value.charAt(j);
            if (j > 0) {
              if (value.charAt(j) == value.charAt(j - 1)) {
                repCount++;
              } else {
                repCount = 1
              }
              if (repCount > this.policy.repeatCharsCount) {
                j = value.length
              }
            }
            j++;
          }
        } else {
          this.policy.repeatCharsCount = 0
        }
        if (numericCount >= this.policy.noOfNumericReq && upperCaseCount >= this.policy.noOfalphUpperCaseReq && lowerCaseCount >= this.policy.noOfalphalowerCaseReq
          && specialCharCount >= this.policy.specialCharactersRequiredCount && value?.length >= this.policy.minLength && value?.length <= this.policy.maxLength && repCount <= this.policy.repeatCharsCount) {
          this.isPasswordValid = true
        } else {
          this.isPasswordValid = false
        }
      } else {
        evt.preventDefault();
      }
    }
  }

  confirmPasswordValidation(val, evt) {
    debugger
    let value
    if (evt.type != 'keyup') {
      value = val.concat(evt.key)
    } else {
      value = val
    }
    if (evt.key != 'Enter') {
      if (value?.length <= this.policy.maxLength) {
      } else {
        evt.preventDefault();
      }
    }
  }
}

