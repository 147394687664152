import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FiltersRoutingModule } from './filters-routing.module';
import { FiltersComponent } from './filters/filters.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNGModule } from 'src/app/app.primeNG.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FiltersComponent],
  imports: [
    CommonModule,
    FiltersRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    PrimeNGModule,
    SidebarModule,
    ScrollPanelModule,
    NgMultiSelectDropDownModule.forRoot(),
    MultiSelectModule,
    TooltipModule,
    CarouselModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    FiltersComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class FiltersModule { }