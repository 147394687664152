import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BnNgIdleService } from 'bn-ng-idle';
import { debounceTime, filter } from 'rxjs/operators';
import { AuthService } from './modules/auth/auth.service';
import { AlertServiceService } from './core/services/alert-service.service';
import { RestrictInspectService } from './core/services/restrictInspect.service';
import { ThemeService } from './core/services/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit ,OnDestroy{
  public title = 'Delizia-HR';
  public greenClass: any;
  public orageClass: boolean;
  public blushClass: boolean;
  public cyanClass = true;
  public timberClass: boolean;
  public blueClass: boolean;
  public amethystClass: boolean;
  public selectedLanguage: any;
  delziahrLogoShow:boolean = true;
  private readonly JWT_TOKEN = '%qycutr';
  private navigationSubscription:Subscription;
  public locales = [
    { label: 'English', value: 'en' },
    { label: 'French', value: 'fr' },
    { label: 'Hindi', value: 'hi' },
  ];
  public locale = this.locales[0].value;
  isFull: any = false;
  rout: any;
  breadcrumbs: any;
  user: any;
  distinctRoles: Array<any> = [];
  isAlreadyCheckEmpLoginByAdmin: boolean = false;
  isShowChildMenuHeader: boolean = false;
  isLogged:boolean = false;
  isApiCall:boolean = false;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService,
    private translocoService: TranslocoService,
    private bnIdle: BnNgIdleService,
    private alertService: AlertServiceService,
    private restrictInspect: RestrictInspectService,
    private ThemeService:ThemeService
  ) {
    this.selectedLanguage = localStorage.getItem('selectedLanguage');
    // generate a regex from the locales we support
    if (this.selectedLanguage) {
      const supportedRegex = new RegExp('^' + this.locales.map((l) => l.value.substring(0, 2)).join('|^'));
      // check if the user's preferred language is supported and if so, use it.
      if (this.selectedLanguage.match(supportedRegex)) {
        this.updateLocale(this.selectedLanguage);
      }
    }
    this.bnIdle.startWatching(2).subscribe((res) => {
      if (res) {
      }
    })
    this.restrictInspect.onClick()
  }


  //   ngDoCheck(): void {


  // //console.log('rout','breadcrumbs' )

  //   }
  // ngAfterContentChecked(): void {
  //   //console.log(this.router.config)
  // }

  public ngOnInit(): void {
    this.navigationSubscription = this.router.events.pipe(
      filter(event=>event instanceof NavigationStart),debounceTime(300)
      ).subscribe((event:any)=>{
        const {restoredState,navigationTrigger} = event;
        if(this.isloggedIn()){
          if ((restoredState && navigationTrigger === 'popstate') || (navigationTrigger === 'imperative' && restoredState === null)) {
            const urlPath:string = event.url;
            // || urlPath === '/userGroupSelectPage'
            if (urlPath === '/login' || urlPath ==='/' || urlPath === '/dashboard' ) {
              this.router.navigate(['/dashboard'],{replaceUrl:true});
              history.pushState(null, '', '/dashboard');
              history.replaceState(null, '', location.href);
            }
          }
        }
      })
    // if(this.authService.isLoggedIn()){
      // this.getThemeSetting();
    // }
    if (this.router.getCurrentNavigation() === null) {
      this.isAlreadyCheckEmpLoginByAdmin = false;
      if (!this.authService.isLoggedIn()) {
        sessionStorage.setItem('issso', JSON.stringify(false))
        sessionStorage.setItem('isemploginbyadmin', JSON.stringify(false))
        if (window.location.pathname == '/Set-Password') {
          this.router.navigate(['/Set-Password']);
        } else if (window.location.pathname == '/privacy-policy') {
          this.router.navigate(['/privacy-policy']);
        } else if (window.location.pathname.split('/')[1] == 'sso-login') {
          sessionStorage.setItem('issso', JSON.stringify(true))
          let token: any = window.location.pathname.split('/')[2];
          //  token = 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI4MSIsIlVzZXJEZXRhaWxzIjp7Imdyb3VwTmFtZSI6IlBheXNxdWFyZURlZmF1bHQiLCJjb21wYW55SWQiOjEsImNvbXBhbnlOYW1lIjoiUGF5c3F1YXJlIiwiZW1haWxJZCI6InVtZXNoLnVnYWxlQHBheXNxdWFyZS5jb20iLCJjb3VudHJ5TmFtZSI6IkluZGlhIiwidXNlck5hbWUiOiJVbWVzaFUiLCJlbXBsb3llZU1hc3RlcklkIjozNDUyLCJlbXBsb3llZUNvZGUiOm51bGx9LCJpc3MiOiJodHRwOi8vcGF5c3F1YXJlLmNvbSIsImV4cCI6MTY4ODIwMjAxNSwiaWF0IjoxNjg4MTE1NjE1LCJqdGkiOiI2ZGM0NzYwOC05NGQxLTQ1MzctOWM5My05MDk5Y2U4NWI1NGEifQ.WFR7r82END4royKsACsN7sTkHcjWQ3RFKBR7SIFeE8-a8W8kNoRk28Edx20lq6ANKHz_dMzAiMEtBVd1I9yWwTHa0wZ5WSrYzOwLN7FMVcT0NsN2_teBwgUTMFKAefBimtx42cndk6yY7SxYG2YHZlSl7ImjPHMTRv7pS4G88hda4Ig27OmC-DddmgPY-0T_29vbr2BcsPYa5wetYBgquvsBXv1GaKSi9LG74HlWSRZ7Y2gELhwh7xt2974NvLqLNLdifI1bN4255hnVGLy2sJ5xs4a_d7GtZWp9w-ALxKOZ3PZdU7hb55uMR550RvdlfrBTlQ2ztKLBE_MB46u4og'
          // this.activatedRoute.queryParams.subscribe((param:any)=>{
          //   token = param
          // })
          // let id = this.activatedRoute.snapshot.paramMap.get('token')
          this.authService.doLoginUser(token);
          let t: any = this.authService.getprivileges();
          sessionStorage.setItem('%qr', this.authService.enObject(t.UserDetails))
          if (new Date(t.iat * 1000 + 15000) > new Date()) {
            this.authService.getSSOLoginAndRole()
          } else {
            this.alertService.sweetalertError('Your session is expired please login again !!');
            this.authService.logout();
          }

          // this.user = this.authService.getprivileges();
          // let obj = {
          //   "ipAddress": null
          // }
          // this.authService.getAssignedUserRoles(obj).subscribe(res => {
          //   this.distinctRoles = res.data.results[0];
          //   if (this.distinctRoles.length == 0) {
          //     // this.isLoader = false
          //     // this.otpDiv = false
          //     this.alertService.sweetalertError('Access Denied !');
          //     this.authService.logout();
          //   } else {
          //     this.showAssignedUserRoles()
          //   }
          // }, (error: any) => {
          //   // this.isLoader = false;
          // })

        } else if (window.location.pathname.split('/')[1] == 'emplogin') {
          this.isAlreadyCheckEmpLoginByAdmin = true;
          sessionStorage.setItem('isemploginbyadmin', JSON.stringify(true))
          sessionStorage.setItem('issso', JSON.stringify(false))
          let token: any = window.location.pathname.split('/')[2];
          this.authService.doLoginUser(token);
          this.user = this.authService.getprivileges();
          sessionStorage.setItem('%qr', this.authService.enObject(this.user.UserDetails))
          this.authService.getSSOLoginAndRole()
        }
        else {
          this.router.navigate(['login']);
        }
      }
      // else {
      //   this.router.navigate( ['/dashboard'] );
      // }
      if (!this.isAlreadyCheckEmpLoginByAdmin) {
        if (window.location.pathname.split('/')[1] == 'emplogin') {
          this.authService.logout();
          sessionStorage.setItem('isemploginbyadmin', JSON.stringify(true))
          sessionStorage.setItem('issso', JSON.stringify(false))
          let token: any = window.location.pathname.split('/')[2];
          this.authService.doLoginUser(token);
          this.user = this.authService.getprivileges();
          sessionStorage.setItem('%qr', this.authService.enObject(this.user.UserDetails))
          this.authService.getSSOLoginAndRole()
        }
      }
    }
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('offcanvas-active');
    body.classList.add('font-montserrat');
    sessionStorage.setItem('MinSideClass', '');
    sessionStorage.setItem('HeaderClass', 'top_dark');

    sessionStorage.setItem('MenuIcon', 'list-a');
    sessionStorage.setItem('Toggle', '');
    sessionStorage.setItem('Toggle2', '');
    sessionStorage.setItem('Toggle3', 'true');
    sessionStorage.setItem('Toggle4', '');
    sessionStorage.setItem('Toggle5', '');
    sessionStorage.setItem('Toggle6', '');
    sessionStorage.setItem('Toggle7', '');
    sessionStorage.setItem('Toggle8', '');
    sessionStorage.setItem('Toggle9', '');
    sessionStorage.setItem('Toggle10', '');

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    )
      .subscribe(() => {

        let rt = this.getChild(this.activatedRoute);

        rt.data.subscribe((data) => {
          this.titleService.setTitle(data.title);
        });
      });
    setTimeout(() => {

      document.getElementsByClassName('page-loader-wrapper')[0].classList.add('HideDiv');

    }, 1000);

    this.authService.expandBody.subscribe(res => {
      if (this.isFull) {
        this.isFull = false;
      }
      else {
        this.isFull = true;
      }
    })

    this.isShowChildMenuHeader = JSON.parse(sessionStorage.getItem('%chm'))
    if (this.isShowChildMenuHeader) {
      this.authService.showChildMenu.next()
    }
    this.authService.showChildMenuHeader.subscribe((res: any) => {
      this.isShowChildMenuHeader = JSON.parse(sessionStorage.getItem('%chm'))
      if (this.isShowChildMenuHeader) {
        this.authService.showChildMenu.next()
      }
    })

  }

  ngOnDestroy(): void {
    this.isApiCall = false;
    this.navigationSubscription.unsubscribe();
  }
  getThemeSetting() {

    // this.renderer.setStyle(document.documentElement,'--blue-color', 'red');
    this.ThemeService.getThemeColo().subscribe(success => {
      this.isApiCall = true;
      const data = success.data.results[0]
      console.log(data[0]);
      const { breadcrumbColor, breadcrumbTextColor, cardsColor, cardsTextColor, deliziaHRLogo, leftMenuColor,
        leftMenuTextColor, tabColor, tabTextColor,tabBorderColor,cardIconColor,cardIconBackgrounColor,cardBorderColor } = data[0];
      // document.documentElement.style.setProperty('--background-color', 'green');
      document.documentElement.style.setProperty('--blue-color', breadcrumbColor);
      // document.documentElement.style.setProperty('--secondary-color', 'red');
      document.documentElement.style.setProperty('--card-color', cardsColor);
      document.documentElement.style.setProperty('--card-text-color', cardsTextColor);
      document.documentElement.style.setProperty('--breadcrum-text-color', breadcrumbTextColor);
      document.documentElement.style.setProperty('--left-menu-background-color', leftMenuColor);
      document.documentElement.style.setProperty('--left-menu-text-color', leftMenuTextColor);
      document.documentElement.style.setProperty('--tab-background-color', tabColor);
      document.documentElement.style.setProperty('--tab-text-color', tabTextColor);
      document.documentElement.style.setProperty('--breadcrum-background-color', breadcrumbColor);
      document.documentElement.style.setProperty('--tab-border-color', `5px solid ${tabBorderColor}`);
      document.documentElement.style.setProperty('--stats-menu-icon-css', cardIconColor);
      document.documentElement.style.setProperty('--stats-box-left-border', `2px solid ${cardBorderColor}`);
      document.documentElement.style.setProperty('--stats-box-bottom-border', `2px solid ${cardBorderColor}`);
      this.delziahrLogoShow = deliziaHRLogo === 'Enable'?true:false;
    })

  }


  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  toggleThemeSetting() {
    const className = document.getElementsByClassName('themesetting')[0];
    className.classList.toggle('open');
  }

  closeMenu() {
    document.getElementsByClassName('right_sidebar')[0].classList.remove('open');
    document.getElementsByClassName('user_div')[0].classList.remove('open');
    document.getElementsByClassName('overlay')[0].classList.remove('open');
  }

  // change locale/language at runtime
  updateLocale(locale) {
    localStorage.setItem("selectedLanguage", locale);

    if (this.locales.some(l => l.value === locale)) {
      this.locale = locale;
    }
    const lang = locale.substring(0, 2);
    this.translocoService.setActiveLang(lang);
  }

  isAuthorized(): boolean {
    let k = JSON.parse(sessionStorage.getItem('in'));
    if (!k) {
      const body = document.getElementsByClassName('offcanvas-active');
      // if (body.classList.contains('offcanvas-active')) {

      //   const box = document.getElementsByClassName('offcanvas-active');
      //   if (box != null) {
      //     box.style.left = '-35px';
      //     box.style.width = '228px'
      //   }

      // else {    
      //     box.style.left = '0px';
      //     box.style.width = '0px'
      //   }
      // }
    }
    return k
  }

  isloggedIn() {
    if (window.location.pathname == '/forgot-password' || window.location.pathname == '/Set-Password') {
      return false;
    }
    if(sessionStorage.getItem(this.JWT_TOKEN)){
      this.isLogged = true;
      if(this.isLogged && !this.isApiCall){
      //  await 
      this.isApiCall = true;
      this.getThemeSetting();
      }
      return sessionStorage.getItem(this.JWT_TOKEN) 
    }
   
  }

  showAssignedUserRoles() {
    let globalRolePresent = this.distinctRoles.find(x => x.type == 'Global')
    let adminRolePresent = this.distinctRoles.find(x => x.type == 'Admin')
    sessionStorage.setItem('selectedCountry', '')
    sessionStorage.setItem('selectedGroup', '')
    sessionStorage.setItem('selectedRole', '')
    sessionStorage.setItem('selectedCompany', '')
    let empData = this.distinctRoles.find(x => x.type == 'Employee')
    if (empData != undefined) {
      sessionStorage.setItem('empComp', this.authService.enValue(JSON.stringify(empData.xtenantId)))
    }
    if (this.distinctRoles.length == 1) {
      // sessionStorage.setItem('%qr', JSON.stringify(this.distinctRoles[0]))
      sessionStorage.setItem('%qr', this.authService.enObject(this.distinctRoles[0]))
      // sessionStorage.setItem('%qrCopy', JSON.stringify(this.distinctRoles[0]));
      sessionStorage.setItem('%qrCopy', this.authService.enObject(this.distinctRoles[0]))
      sessionStorage.setItem('%rid', this.authService.enValue(JSON.stringify(this.distinctRoles[0].userRoleId)))
      localStorage.setItem('employeeMasterId', this.user.UserDetails.employeeMasterId);
      this.authService.shareData.next();
      this.router.navigate(['/employee-master/employee-dashboard']);
    }
    else if (globalRolePresent) {
      if (globalRolePresent.globalCompanyMasterIdList.split(',').length > 1) {
        this.authService.shareRoleName.next(globalRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.authService.enValue(globalRolePresent.roleName))
        // sessionStorage.setItem('%qr', JSON.stringify(globalRolePresent));
        sessionStorage.setItem('%qr', this.authService.enObject(globalRolePresent))
        // sessionStorage.setItem('%qrCopy', JSON.stringify(globalRolePresent));
        sessionStorage.setItem('%qrCopy', this.authService.enObject(globalRolePresent))
        sessionStorage.setItem('%rid', this.authService.enValue(JSON.stringify(globalRolePresent.userRoleId)))
        this.router.navigate(['/userGroupSelectPage']);
      } else {
        // sessionStorage.setItem('%qr', JSON.stringify(globalRolePresent));
        sessionStorage.setItem('%qr', this.authService.enObject(globalRolePresent))
        // sessionStorage.setItem('%qrCopy', JSON.stringify(globalRolePresent));
        sessionStorage.setItem('%qrCopy', this.authService.enObject(globalRolePresent))
        sessionStorage.setItem('globalCompanyList', this.authService.enValue(JSON.stringify(globalRolePresent.globalCompanyMasterIdList)));
        this.authService.shareData.next();
        //changes for single company
        let tempRole = this.authService.deObject(sessionStorage.getItem('%qr'));
        tempRole.groupName = globalRolePresent.xtenantId
        tempRole.globalCompanyMasterId = JSON.parse(tempRole.globalCompanyMasterIdList)
        // sessionStorage.setItem('%qr', JSON.stringify(tempRole))
        sessionStorage.setItem('%qr', this.authService.enObject(tempRole))
        this.authService.shareCompName.next(globalRolePresent.companyName)
        this.authService.shareRoleName.next(globalRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.authService.enValue(globalRolePresent.roleName))
        this.router.navigate(['Dashboard']);
      }
    } else if (adminRolePresent) {
      sessionStorage.setItem('%rid', this.authService.enValue(JSON.stringify(adminRolePresent.userRoleId)))
      // sessionStorage.setItem('%qr', JSON.stringify(adminRolePresent));
      sessionStorage.setItem('%qr', this.authService.enObject(adminRolePresent))
      // sessionStorage.setItem('%qrCopy', JSON.stringify(adminRolePresent));
      sessionStorage.setItem('%qrCopy', this.authService.enObject(adminRolePresent))
      if (adminRolePresent.globalCompanyMasterIdList.split(',').length > 1) {
        this.authService.shareRoleName.next(adminRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.authService.enValue(adminRolePresent.roleName))
        this.router.navigate(['/userGroupSelectPage']);
      } else {
        // sessionStorage.setItem('%qr', JSON.stringify(adminRolePresent));
        sessionStorage.setItem('%qr', this.authService.enObject(adminRolePresent))
        // sessionStorage.setItem('%qrCopy', JSON.stringify(adminRolePresent));
        sessionStorage.setItem('%qrCopy', this.authService.enObject(adminRolePresent))
        sessionStorage.setItem('globalCompanyList', this.authService.enValue(JSON.stringify(adminRolePresent.globalCompanyMasterIdList)));
        this.authService.shareData.next();
        //changes for single company
        let tempRole = this.authService.deObject(sessionStorage.getItem('%qr'));
        tempRole.groupName = adminRolePresent.xtenantId
        // sessionStorage.setItem('%qr', JSON.stringify(tempRole))
        sessionStorage.setItem('%qr', this.authService.enObject(tempRole))
        this.authService.shareCompName.next(adminRolePresent.companyName)
        this.authService.shareRoleName.next(adminRolePresent.roleName)
        sessionStorage.setItem('selectedRole', this.authService.enValue(adminRolePresent.roleName))
        this.router.navigate(['Dashboard']);
      }
    }
    // this.getDataRoleWise();

    this.alertService.sweetalertMasterSuccess('Login Successful', '');
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (window.location.host == 'www.deliziahr.com' || window.location.host == 'uat.deliziahr.com') {
      if (e.key === 'F12') {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.key === "I") {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.key === "C") {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.key === "J") {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.key === "H") {
        return false;
      }
      if (e.ctrlKey && e.key == "U") {
        return false;
      }
    }
    return true;
  }
}