<p-sidebar [(visible)]="display" position="right" [dismissible]="true" [modal]="true" [showCloseIcon]="false"
    [style]="{height:'800px',width: '74em'}">

    <!-- <button type="button" pripple="" class="p-sidebar-close p-link ng-tns-c51-4 p-ripple ng-star-inserted"><span
            class="p-sidebar-close-icon pi pi-times ng-tns-c51-4"></span><span class="p-ink sidebar-css"></span>
    </button> -->

    <p-scrollPanel appendTo="body" [style]="{width: '100%', height: '100vh'}">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-md-10">
                        <h3 class="font-14 text-purple font-bold">Filter</h3>
                    </div>
                    <div class="col-md-2">
                        <div class="float-right">
                            <b><i class="fa fa-times" (click)="display=false"></i></b>
                        </div>
                    </div>
                </div>

                <!-- Name and Copy from -->
                <div class="row mt-10">
                    <div class="col-md-6">
                        <label class="form-label">Name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Filter Name" [(ngModel)]="filterName"
                                [ngModelOptions]="{standalone: true}" [class.filterinvalid]="filterName == ''"
                                [disabled]="viewFilterFlag">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- <label class="form-label">Copy From </label>
                        <select class="form-control" (change)="copyFrom($event.target.value)">
                            <option value=''>Select </option>
                            <option value='{{data.filterMasterId}}' *ngFor="let data of allFilterSummary">{{data.name}}
                            </option>
                        </select> -->
                    </div>
                </div>

                <div class="col-md-12 mt-25">
                    <div id="page">
                        
                        <form [formGroup]="employeeFilterForm">
                            <div>
                                <input class="toggle-box" id="identifier-1" type="checkbox" value=true />
                                <label for="identifier-1" class="color-purple">By Employee Details</label>
                                <div class="">
                                    <div class="tabview-contents">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label class="form-label">Employee Set </label>
                                                <select class="form-control" formControlName="employeeSet">
                                                    <option value=''>Select </option>
                                                    <option value='{{data.employeeSetMasterId}}'
                                                        *ngFor="let data of employeeSetData;">{{data.employeeSetName}}
                                                    </option>
                                                </select>

                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label class="custom-control custom-checkbox checkbox-center">
                                                    <input type="checkbox" (click)="employeeListCopyPaste($event.target,pasteEmployee)"
                                                        name="example-inline-radios" class="custom-control-input" [disabled]="viewFilterFlag"/>
                                                    <span class="custom-control-label color-black">
                                                        Employee List</span>
                                                </label>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Gender </label>
                                                <select class="form-control" formControlName="gender">
                                                    <option value=''>Select </option>
                                                    <option value='Male'>Male </option>
                                                    <option value='Female'>Female </option>
                                                    <option value='Transgender'>Transgender </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label class="form-label">Service Age (Months)</label>
                                                <input class="form-age inputb" placeholder="From" [(ngModel)]="serviceFromAge"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="viewFilterFlag"/>
                                                -
                                                <input class="form-age inputb" placeholder="To" [(ngModel)]="serviceToAge"
                                                    [ngModelOptions]="{standalone: true}"  [disabled]="viewFilterFlag"/>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Employee Age (Years) </label>
                                                <input class="form-age inputb" placeholder="From" [(ngModel)]="empFromAge"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="viewFilterFlag"/>
                                                -
                                                <input class="form-age inputb" placeholder="To" [(ngModel)]="empToAge"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="viewFilterFlag"/>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Status </label>
                                                <select class="form-control" formControlName="status">
                                                    <option value=''>Select </option>
                                                    <option value='Confirmed'>Confirmed </option>
                                                    <option value='Probation'>Probation </option>
                                                    <option value='Permanent'>Permanent </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label class="form-label">Language </label>
                                                <!-- <select class="form-control" formControlName="languageId">
                                                    <option value=''>Select </option>
                                                    <option 
                                                        *ngFor="let data of employeeLanguageData"> {{data}}
                                                    </option> -->
                                                    <!-- {{data.language}} -->
                                                    <!-- value='{{data.employeeLanguageinfoId}}' -->
                                                <!-- </select> -->

                                                <p-multiSelect resetFilterOnHide="true" defaultLabel="Select"
                                                    formControlName="languageId"
                                                    [options]="employeeLanguageData" optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Skill</label>
                                                <!-- <select class="form-control" formControlName="skillId">
                                                    <option value=''>Select </option>
                                                    <option
                                                        *ngFor="let data of employeeSkillData"> {{data}}
                                                    </option> -->
                                                    <!-- {{data.skillName}} -->
                                                    <!-- value='{{data.employeeSkillInfoId}}' -->
                                                <!-- </select> -->
                                                <p-multiSelect resetFilterOnHide="true" defaultLabel="Select"
                                                    formControlName="skillId"
                                                    [options]="employeeSkillData" optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">Education </label>
                                                <!-- <select class="form-control" formControlName="education">
                                                    <option value=''>Select </option>
                                                    <option
                                                        *ngFor="let data of employeeEducationData"> {{data}}
                                                    </option> -->
                                                    <!-- {{data.education}} -->
                                                    <!-- value='{{data.employeeEducationID}}' -->
                                                <!-- </select> -->

                                                <p-multiSelect resetFilterOnHide="true" defaultLabel="Select"
                                                    formControlName="education"
                                                    [options]="employeeEducationData" optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form [formGroup]="jobDetailsFilterForm">
                            <div>
                                <input class="toggle-box" id="identifier-2" type="checkbox" value=true />
                                <label for="identifier-2" class="color-purple mt-25">By Job Details</label>
                                <div class="">
                                    <div class="tabview-contents">
                                        <h3 class="font-14 text-purple font-bold">Organization Details</h3>
                                        <div class="row mt-5">
                                            <div class="col-md-3">
                                                <label class="form-label">Company Name </label>
                                                <p-multiSelect [(ngModel)]="companyMasterId"
                                                    [ngModelOptions]="{standalone: true}" resetFilterOnHide="true"
                                                    defaultLabel="Select" [required]="true" [disabled]="viewFilterFlag"
                                                    (onChange)="getJobMasterInformation($event)" [options]="companyData"
                                                    optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                        <div class="row mt-5">
                                            <div class="col-md-3 mt-5">
                                                <label class="form-label">Payroll Area </label>
                                                <p-multiSelect [(ngModel)]="payrollAreaCode" 
                                                    [ngModelOptions]="{standalone: true}" resetFilterOnHide="true"
                                                    defaultLabel="Select" [required]="true" [disabled]="viewFilterFlag"
                                                    (onChange)="onSelectPayrollArea($event)" [options]="getAllPayrollareacodeBycompanyids"
                                                     optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                            <div class="col-md-3 mt-5">
                                                <label class="form-label">Establishment </label>
                                                <p-multiSelect resetFilterOnHide="true" defaultLabel="Select"
                                                    (onChange)="onSelectEstablishment($event)"
                                                    [ngModelOptions]="{standalone: true}" [required]="true"
                                                    [(ngModel)]="establishmentMasterId" [disabled]="viewFilterFlag"
                                                    [options]="getEstablishmentDetails" optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                            <div class="col-md-4 mt-5"
                                                *ngFor="let jobmasters of jobMasterDropDown ;let i = index">
                                                <label class="form-label">{{jobmasters.jobMasterType}} </label>

                                                <p-multiSelect resetFilterOnHide="true" defaultLabel="Select"
                                                    (onChange)="onSelectJobMasterData($event,jobmasters)"
                                                    [(ngModel)]="jobmasters.selectedValue" [disabled]="viewFilterFlag"
                                                    [ngModelOptions]="{standalone: true}" [required]="true"
                                                    [options]="jobmasters.dropdownData" optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form [formGroup]="dateFilterForm">
                            <div>
                                <input class="toggle-box" id="identifier-3" type="checkbox" value=true />
                                <label for="identifier-3" class="color-purple mt-25">By Date</label>
                                <div class="">
                                    <div class="tabview-contents">
                                        <div class="row mt-4">
                                            <div class="col-md-4">
                                                <label class="form-label">Period Selection </label>
                                                <select class="form-control" [(ngModel)]="cycleSelectionOption"
                                                [ngModelOptions]="{standalone: true}"
                                                    (change)="getcycleSelect($event.target.value)" [disabled]="viewFilterFlag">
                                                    <option value=''>Select </option>
                                                    <option>Custom Defined</option>
                                                    <option>Current Cycle </option>
                                                    <option>Current Week </option>
                                                    <option>Current Month </option>
                                                    <option>Current Quarter </option>
                                                    <option>Current Business Year</option>
                                                    <option>Current Calender Year</option>
                                                    <option>Cycle Range</option>
                                                    <option>Last 30 Days</option>
                                                    <option>Last 7 Days</option>
                                                    <option>Today</option>
                                                    <option>No. of Previous Cycle </option>
                                                    <option>No. of Previous Weeks </option>
                                                    <option>No. of Previous Months </option>
                                                    <option>No. of Previous Quarters </option>
                                                    <option>No. of Previous Business Years</option>
                                                    <!-- <option>No. of Previous Calender Years</option> -->
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-4" *ngIf="noOfCalculationFlag == true 
                                                && cycleSelectionOption == 'No. of Previous Cycle'">
                                                <label class="form-label">No. of Previous Cycle</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="Value"
                                                    formControlName="noOfPreviousCycle"
                                                        (focusout)="getNoOfCalculation($event.target.value)" [disabled]="viewFilterFlag">
                                                </div>
                                            </div>
                                            <div class="col-md-4" *ngIf="noOfCalculationFlag == true 
                                                && cycleSelectionOption == 'No. of Previous Business Years'">
                                                <label class="form-label">No. of Previous Business Years</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="Value"
                                                    formControlName="noOfPreviousBusinessYear"
                                                        (focusout)="getNoOfCalculation($event.target.value)" [disabled]="viewFilterFlag">
                                                </div>
                                            </div>
                                            <div class="col-md-4" *ngIf="noOfCalculationFlag == true 
                                                && cycleSelectionOption == 'No. of Previous Months'">
                                                <label class="form-label">No. of Previous Months</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="Value"
                                                    formControlName="noOfPreviousMonths"
                                                        (focusout)="getNoOfCalculation($event.target.value)" [disabled]="viewFilterFlag">
                                                </div>
                                            </div>
                                            <div class="col-md-4" *ngIf="noOfCalculationFlag == true 
                                                && cycleSelectionOption == 'No. of Previous Quarters'">
                                                <label class="form-label">No. of Previous Quarters</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="Value"
                                                    formControlName="noOfPreviousQuarters"
                                                        (focusout)="getNoOfCalculation($event.target.value)" [disabled]="viewFilterFlag">
                                                </div>
                                            </div>
                                            <div class="col-md-4" *ngIf="noOfCalculationFlag == true 
                                                && cycleSelectionOption == 'No. of Previous Weeks'">
                                                <label class="form-label">No. of Previous Weeks</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" placeholder="Value"
                                                    formControlName="noOfPreviousWeeks"
                                                        (focusout)="getNoOfCalculation($event.target.value)" [disabled]="viewFilterFlag">
                                                </div>
                                            </div>
                                            <div class="col-md-4" *ngIf="showCycleDefinitionFlag == true">
                                                <label class="form-label">Cycle Definition</label>
                                                <select class="form-control" formControlName="cycleDefinitionId"
                                                    defaultLabel="Select"  [disabled]="viewFilterFlag"
                                                    (change)="getCycleNameById($event.target.value)">
                                                    <option value=''>Select</option>
                                                    <option *ngFor="let cycle of cycleDefifitionList"
                                                        [value]="cycle.value">
                                                        {{cycle.label}} </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4" *ngIf="showCylces == true">
                                                <label class="form-label">Cycle</label>
                                                <select class="form-control"
                                                    (change)="onSelectCycleName($event.target.value)"
                                                    formControlName="periodDefinition">
                                                    <option value='0'>Select</option>
                                                    <option *ngFor="let cycleList of cycleNameList"
                                                        value="{{cycleList.id}}">
                                                        {{cycleList.cycleDisplayName}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4" *ngIf="showFromAndToCycles == true">
                                                <label class="form-label">From Cycle</label>
                                                <select class="form-control" formControlName="fromCycle"
                                                    (change)="onFromSelectCycleName($event.target.value)">
                                                    <option value='0'>Select</option>
                                                    <option *ngFor="let cycleList of cycleNameList"
                                                        value="{{cycleList.id}}">
                                                        {{cycleList.cycleDisplayName}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4" *ngIf="showFromAndToCycles == true">
                                                <label class="form-label">To Cycle</label>
                                                <select class="form-control" formControlName="toCycle"
                                                    (change)="onToSelectCycleName($event.target.value)">
                                                    <option value='0'>Select</option>
                                                    <option *ngFor="let cycleList of cycleNameList"
                                                        value="{{cycleList.id}}">
                                                        {{cycleList.cycleDisplayName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mt-4" *ngIf="noOfCalculationFlag == false && cycleSelectionOption != 'Cycle Range'">
                                            <div class="col-md-4">
                                                <label class="form-label">From Date</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="fa fa-calendar"></i></span>
                                                    </div>
                                                    <input data-provide="datepicker" data-date-autoclose="true"
                                                        formControlName="fromDate" [disabled]="!showCycleDefinitionFlag || showCylces || noOfCalculationFlag"
                                                        [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }"
                                                        class="form-control" placeholder="Date" bsDatepicker>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label">To Date</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="fa fa-calendar"></i></span>
                                                    </div>
                                                    <input data-provide="datepicker" data-date-autoclose="true"
                                                        [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }" [disabled]="!showCycleDefinitionFlag || showCylces || noOfCalculationFlag"
                                                        formControlName="endDate" class="form-control"
                                                        placeholder="Date" bsDatepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- save and reset button start here -->
                <div class="row mt-50">
                    <div class="col-12">
                        <div class="float-right mb-15">
                            <button type="button" *ngIf="!viewFilterFlag && !editFilterFlag"
                                class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple ml-2"
                                tooltip="Save" (click)="saveAllFilters('Save')"
                                [ngClass]="{'disabled-btn-bg-green' : filterName == '' || employeeFilterForm.invalid || jobDetailsFilterForm.invalid || dateFilterForm.invalid}"
                                [disabled]="filterName == '' || employeeFilterForm.invalid || jobDetailsFilterForm.invalid || dateFilterForm.invalid">
                                <b><i class="fa fa-floppy-o"></i></b> Save
                            </button>
                            <button type="button" *ngIf="!viewFilterFlag && editFilterFlag"
                                class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple ml-2"
                                tooltip="Save" (click)="updateAllFilters()"
                                [ngClass]="{'disabled-btn-bg-green' : filterName == '' || employeeFilterForm.invalid || jobDetailsFilterForm.invalid || dateFilterForm.invalid}"
                                [disabled]="filterName == '' || employeeFilterForm.invalid || jobDetailsFilterForm.invalid || dateFilterForm.invalid">
                                <b><i class="fa fa-floppy-o"></i></b> Update
                            </button>
                            <!-- [ngClass]="{'disabled-btn-bg-green' : filterName == '' || employeeFilterForm.invalid || jobDetailsFilterForm.invalid || dateFilterForm.invalid}"
                                [disabled]="filterName == '' || employeeFilterForm.invalid || jobDetailsFilterForm.invalid || dateFilterForm.invalid" -->
                            <button type="button" *ngIf="!viewFilterFlag"
                                class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple ml-2"
                                tooltip="Apply" (click)="saveAllFilters('Apply')">
                                <b><i class="fa fa-check"></i></b> Apply
                            </button>
                            <button type="button" *ngIf="!viewFilterFlag "
                                class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left ml-10"
                                tooltip="Reset" (click)="resetForms()">
                                <b><i class="fa fa-refresh" aria-hidden="true"></i></b> Reset
                            </button>
                            <button type="button" tooltip="Close" (click)="resetForms()"
                                *ngIf="viewFilterFlag && !editFilterFlag"
                                class="custom_button custom_btn bg-btn-red btn-labeled btn-labeled-left">
                                <b><i class="fa fa-times"></i></b> Cancel
                            </button>
                        </div>
                    </div>
                </div>

                <!-- summary table start here -->
                <div class="row mt-25 mb-5">
                    <div class="col-md-12">
                        <h3 class="font-14 text-purple font-bold">Summary</h3>
                        <div class="table-responsive Custom_Table">

                            <p-table #dt2 [value]="allFilterSummary" [paginator]="true" [rows]="10"
                                totalRecords={{allFilterSummary?.length}} [showCurrentPageReport]="true"
                                [rowsPerPageOptions]="[10,25,50,100,200]"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['name']">

                                <ng-template pTemplate="caption">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="float-right">
                                                <div class="d-flex">
                                                    <span class="p-input-icon-left p-ml-auto">
                                                        <i class="pi pi-search search-css"></i>
                                                        <input pInputText type="text"
                                                            (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                                            placeholder="Search " />
                                                    </span>
                                                    <i
                                                        class="fa fa-file-excel-o excel-icon-css" (click)="exportExcel()"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="text-center">
                                            Sr. No.
                                        </th>
                                        <th class="text-center">
                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                Filter Name
                                                <div class="Sort_Filter_Position">
                                                    <p-sortIcon field="name" pSortableColumn="name">
                                                    </p-sortIcon>
                                                    <p-columnFilter type="text" field="name" display="menu"
                                                        class="p-ml-auto">
                                                    </p-columnFilter>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </ng-template>

                                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                                    <tr class="p-selectable-row">
                                        <td class="text-center">
                                            {{rowIndex + 1}}
                                        </td>
                                        <td class="text-center">
                                            {{data.name}}
                                        </td>
                                        <td class="text-center">
                                            <i class="fa fa-edit text-dark-green mr-2" tooltip="Edit"
                                                (click)="editFilter(data)"></i>
                                            <i class="text-blue fa fa-eye mr-2" tooltip="View"
                                                (click)="viewFilter(data)"></i>
                                            <i class="text-red fa fa-trash mr-2" tooltip="Delete"
                                                (click)="deletepopup(deleteTemp,data)"></i>
                                        </td>
                                    </tr>
                                </ng-template>

                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="15" class="font-14 font500 text-center text-red">
                                            <span>No Data
                                                Available</span>
                                        </td>
                                    </tr>
                                </ng-template>

                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</p-sidebar>

<ng-template #deleteTemp>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel">Confirmation</h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body mb-0 pb-0 pt-0">
        <div class="card mb-0">
            <div class="card-body">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <label class="form-label"> <i class="fa fa-question-circle font-16 text-purple"></i>
                            &nbsp;&nbsp;
                            Are you sure you want to delete query?
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deleteFilterData();modalRef.hide()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>

<ng-template #pasteEmployee>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel">Employee List</h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row  ">
                    <div class="col-md-12">
                        <textarea (paste)="employeeListPasteData($event)" rows="3" cols="60"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Proceed</button>
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>
