import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../auth/auth.service';
import { LeftMenuServiceService } from '../left-menu-service.service';
import { HelpDocumentService } from '../../../common-shared/help-document/help-document.service';

@Component({
  selector: 'app-childmenu',
  templateUrl: './childmenu.component.html',
  styleUrls: ['./childmenu.component.scss']
})
export class ChildmenuComponent implements OnInit,OnDestroy {
  childMenuData: any;
  grandchildData: any;
  role: any;
  leftMenudata: any;
  childData: any;
  grandChild: boolean=false;
  grandChildDataList: any;
  modalRef: BsModalRef;
  shareChildSubject: any;
  needToLoadAgain: boolean;
  isLoader:boolean = false;
  isShowLandingPage: boolean = false;
  isShowPayrollList:boolean = false;
  isShowLMSFormInputList:boolean = false;
  type:String = '';
  isShowUserGuide:boolean = false;
  showHelpDoc: boolean = false;
  constructor(private service:LeftMenuServiceService,private router:Router,private authService:AuthService,private modalService: BsModalService,
    private commonPopup:HelpDocumentService) {
    
  }

  ngOnInit(): void {
    sessionStorage.setItem('%chm','false');
    this.authService.showChildMenuHeader.next(false)
    let chd = JSON.parse(sessionStorage.getItem('chd'));
    this.grandChild =  chd
    this.isShowLandingPage = false;
    this.isShowPayrollList = false
    this.isShowLMSFormInputList = false;
    let empData = this.authService.deObject(sessionStorage.getItem('%qr'))
    if(sessionStorage.getItem('%qrch') == 'Employee Master' && empData.type != 'Employee'){
      this.isShowLandingPage = true
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Inputs'){
      this.isShowPayrollList = true;
    }else if(sessionStorage.getItem('%qrch') == 'LMS Input' && empData.type != 'Employee'){
      this.isShowLMSFormInputList = true
    }

    if(sessionStorage.getItem('%qrch') == 'Employee Master'){
      this.isShowUserGuide = false;
      // this.isShowUserGuide = true;
      // this.type = 'Employee Master'
    }else if(sessionStorage.getItem('%qrch') == 'Remibursement'){
      this.isShowUserGuide = true;
      this.type = 'Remibursement'
    }else if(sessionStorage.getItem('%qrch') == 'CRM'){
      this.isShowUserGuide = true;
      this.type = 'CRM'
    }else if(sessionStorage.getItem('%qrch') == 'Query'){
      this.isShowUserGuide = true;
      this.type = 'Query'
    }else if(sessionStorage.getItem('%qrch') == 'Master'){
      this.isShowUserGuide = true;
      this.type = 'master'
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Applications'){
      this.isShowUserGuide = true;
      this.type = 'payrollApplications'
    }else if(sessionStorage.getItem('%qrch') == 'Setting'){
      this.isShowUserGuide = true;
      this.type = 'Setting'
    }else if(sessionStorage.getItem('%qrch') == 'Reports'){
      this.isShowUserGuide = true;
      this.type = 'Report'
    }else if(sessionStorage.getItem('%qrch') == 'Approval'){
      this.isShowUserGuide = true;
      this.type = 'Approval'
    }else if(sessionStorage.getItem('%qrch') == 'Expense Reimbursement'){
      this.isShowUserGuide = true;
      this.type = 'Expense Reimbursement'
    }else if(sessionStorage.getItem('%qrch') == 'General Claim'){
      this.isShowUserGuide = true;
      this.type = 'General Claim'
    }else if(sessionStorage.getItem('%qrch') == 'LMS'){
      this.isShowUserGuide = true;
      this.type = 'LMS'
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Inputs'){
      this.isShowUserGuide = true;
      this.type = 'Payroll'
    }else if(sessionStorage.getItem('%qrch') == 'ESOP'){
      this.isShowUserGuide = true;
      this.type = 'ESOP'
    }
    else{
      this.isShowUserGuide = false;
    }
   
    this.authService.childDataForBreadcrumb.subscribe((res)=>{
      if(res.length > 0){
        if(this.grandChild==true){
          this.isLoader = true
              // this. authService. getChildMenuByParent().subscribe(res=>{
                this.isLoader = false
                this.childMenuData = res
                // this.authService.childDataForBreadcrumb.value(this.childMenuData)
                this.grandChild=true
                let findData  = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch'));
                this.onClickChildManu(findData)
              // }, (error: any) => {
              //   this.isLoader = false
              // })
          this.isShowLandingPage = false;
            this.isShowPayrollList = false;
            this.isShowLMSFormInputList = false;
        }else{
          this.isLoader = true
        // this. authService. .subscribe(res=>{
          this.isLoader = false
          this.childMenuData = res
          // this.authService.childDataForBreadcrumb.value(this.childMenuData)
          this.grandChild=false
        // }, (error: any) => {
        //   this.isLoader = false
        // })
      }
      }else{
        if(this.grandChild==true){
          this.isLoader = true
              this. authService. getChildMenuByParent().subscribe(res=>{
                this.isLoader = false
                this.childMenuData = res.data.results[0] 
                this.authService.childDataForBreadcrumb.next(this.childMenuData)
                this.grandChild=true
                let findData  = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch'));
                this.onClickChildManu(findData)
              }, (error: any) => {
                this.isLoader = false
              })
          this.isShowLandingPage = false;
            this.isShowPayrollList = false;
            this.isShowLMSFormInputList = false;
        }else{
          this.isLoader = true
        this. authService. getChildMenuByParent().subscribe(res=>{
          this.isLoader = false
          this.childMenuData = res.data.results[0] 
          this.authService.childDataForBreadcrumb.next(this.childMenuData)
          this.grandChild=false
        }, (error: any) => {
          this.isLoader = false
        })
      }
      }
    })

  //   if(this.grandChild==true){
  //     this.isLoader = true
  //         this. authService. getChildMenuByParent().subscribe(res=>{
  //           this.isLoader = false
  //           this.childMenuData = res.data.results[0] 
  //           this.authService.childDataForBreadcrumb.value(this.childMenuData)
  //           this.grandChild=true
  //           let findData  = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch'));
  //           this.onClickChildManu(findData)
  //         }, (error: any) => {
  //           this.isLoader = false
  //         })
  //     this.isShowLandingPage = false;
  //       this.isShowPayrollList = false;
  //       this.isShowLMSFormInputList = false;
  //   }else{
  //     this.isLoader = true
  //   this. authService. getChildMenuByParent().subscribe(res=>{
  //     this.isLoader = false
  //     this.childMenuData = res.data.results[0] 
  //     this.authService.childDataForBreadcrumb.value(this.childMenuData)
  //     this.grandChild=false
  //   }, (error: any) => {
  //     this.isLoader = false
  //   })
  // }
    this.authService.shareDataChild.subscribe(
      (data) => {   
        this.grandChild=false;
        let chd = JSON.parse(sessionStorage.getItem('chd'));
    this.grandChild =  chd
          if(this.grandChild == false){
          this.isLoader = true
          this. authService. getChildMenuByParent().subscribe(res=>{
            this.isLoader = false
            this.childMenuData = res.data.results[0] 
            this.authService.childDataForBreadcrumb.next(this.childMenuData)
            this.grandChild=false
          }, (error: any) => {
            this.isLoader = false
          })
        // }
        this.isShowLandingPage = false;
        this.isShowPayrollList = false;
        this.isShowLMSFormInputList = false;
        let empData = this.authService.deObject(sessionStorage.getItem('%qr'))
    if(sessionStorage.getItem('%qrch') == 'Employee Master' && empData.type != 'Employee'){
      this.isShowLandingPage = true
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Inputs'){
      this.isShowPayrollList = true;
    }else if(sessionStorage.getItem('%qrch') == 'LMS Input' && empData.type != 'Employee'){
      this.isShowLMSFormInputList = true
    }
    if(sessionStorage.getItem('%qrch') == 'Employee Master'){
      this.isShowUserGuide = false;
      // this.isShowUserGuide = true;
      // this.type = 'Employee Master'
    }else if(sessionStorage.getItem('%qrch') == 'Remibursement'){
      this.isShowUserGuide = true;
      this.type = 'Remibursement'
    }else if(sessionStorage.getItem('%qrch') == 'CRM'){
      this.isShowUserGuide = true;
      this.type = 'CRM'
    }else if(sessionStorage.getItem('%qrch') == 'Query'){
      this.isShowUserGuide = true;
      this.type = 'Query'
    }else if(sessionStorage.getItem('%qrch') == 'Master'){
      this.isShowUserGuide = true;
      this.type = 'master'
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Applications'){
      this.isShowUserGuide = true;
      this.type = 'payrollApplications'
    }else if(sessionStorage.getItem('%qrch') == 'Setting'){
      this.isShowUserGuide = true;
      this.type = 'Setting'
    }else if(sessionStorage.getItem('%qrch') == 'Reports'){
      this.isShowUserGuide = true;
      this.type = 'Report'
    }else if(sessionStorage.getItem('%qrch') == 'Approval'){
      this.isShowUserGuide = true;
      this.type = 'Approval'
    }else if(sessionStorage.getItem('%qrch') == 'Expense Reimbursement'){
      this.isShowUserGuide = true;
      this.type = 'Expense Reimbursement'
    }else if(sessionStorage.getItem('%qrch') == 'General Claim'){
      this.isShowUserGuide = true;
      this.type = 'General Claim'
    }else if(sessionStorage.getItem('%qrch') == 'LMS'){
      this.isShowUserGuide = true;
      this.type = 'LMS'
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Inputs'){
      this.isShowUserGuide = true;
      this.type = 'Payroll'
    }else if(sessionStorage.getItem('%qrch') == 'ESOP'){
      this.isShowUserGuide = true;
      this.type = 'ESOP'
    }
    else{
      this.isShowUserGuide = false;
    }
  }else{
    this.isLoader = true
          this. authService. getChildMenuByParent().subscribe(res=>{
            this.isLoader = false
            this.childMenuData = res.data.results[0] 
            this.authService.childDataForBreadcrumb.next(this.childMenuData)
            this.grandChild=true
            let findData  = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch'));
            this.onClickChildManu(findData)
          }, (error: any) => {
            this.isLoader = false
          })
      this.isShowLandingPage = false;
        this.isShowPayrollList = false;
        this.isShowLMSFormInputList = false;
  }
  // if(this.authService.shareDataChild.observers.length > 1){
  //   this.authService.shareDataChild.observers.splice(1,1)
  // }
  this.getData();
      });

      this.authService.callBreadcrumb.subscribe(
        (data) => {   
          this.grandChild=false;
          let chd = JSON.parse(sessionStorage.getItem('chd'));
      this.grandChild =  chd
            if(this.grandChild == false){
            this.isLoader = true
            this. authService. childDataForBreadcrumb.subscribe(res=>{
              this.isLoader = false
              this.childMenuData = res; 
              // this.authService.childDataForBreadcrumb.value(this.childMenuData)
              this.grandChild=false
            }, (error: any) => {
              this.isLoader = false
            })
          // }
          this.isShowLandingPage = false;
          this.isShowPayrollList = false;
          this.isShowLMSFormInputList = false;
          let empData = this.authService.deObject(sessionStorage.getItem('%qr'))
      if(sessionStorage.getItem('%qrch') == 'Employee Master' && empData.type != 'Employee'){
        this.isShowLandingPage = true
      }else if(sessionStorage.getItem('%qrch') == 'Payroll Inputs'){
        this.isShowPayrollList = true;
      }else if(sessionStorage.getItem('%qrch') == 'LMS Input' && empData.type != 'Employee'){
        this.isShowLMSFormInputList = true
      }
      if(sessionStorage.getItem('%qrch') == 'Employee Master'){
        this.isShowUserGuide = false;
        // this.isShowUserGuide = true;
        // this.type = 'Employee Master'
      }else if(sessionStorage.getItem('%qrch') == 'Remibursement'){
        this.isShowUserGuide = true;
        this.type = 'Remibursement'
      }else if(sessionStorage.getItem('%qrch') == 'CRM'){
        this.isShowUserGuide = true;
        this.type = 'CRM'
      }else if(sessionStorage.getItem('%qrch') == 'Query'){
        this.isShowUserGuide = true;
        this.type = 'Query'
      }else if(sessionStorage.getItem('%qrch') == 'Master'){
        this.isShowUserGuide = true;
        this.type = 'master'
      }else if(sessionStorage.getItem('%qrch') == 'Payroll Applications'){
        this.isShowUserGuide = true;
        this.type = 'payrollApplications'
      }else if(sessionStorage.getItem('%qrch') == 'Setting'){
        this.isShowUserGuide = true;
        this.type = 'Setting'
      }else if(sessionStorage.getItem('%qrch') == 'Reports'){
        this.isShowUserGuide = true;
        this.type = 'Report'
      }else if(sessionStorage.getItem('%qrch') == 'Approval'){
        this.isShowUserGuide = true;
        this.type = 'Approval'
      }else if(sessionStorage.getItem('%qrch') == 'Expense Reimbursement'){
        this.isShowUserGuide = true;
        this.type = 'Expense Reimbursement'
      }else if(sessionStorage.getItem('%qrch') == 'General Claim'){
        this.isShowUserGuide = true;
        this.type = 'General Claim'
      }else if(sessionStorage.getItem('%qrch') == 'LMS'){
        this.isShowUserGuide = true;
        this.type = 'LMS'
      }else if(sessionStorage.getItem('%qrch') == 'Payroll Inputs'){
        this.isShowUserGuide = true;
        this.type = 'Payroll'
      }else if(sessionStorage.getItem('%qrch') == 'ESOP'){
        this.isShowUserGuide = true;
        this.type = 'ESOP'
      }
      else{
        this.isShowUserGuide = false;
      }
    }else{
      this.isLoader = true
            this. authService. childDataForBreadcrumb.subscribe(res=>{
              this.isLoader = false
              this.childMenuData = res;
              // this.authService.childDataForBreadcrumb.value(this.childMenuData)
              this.grandChild=true
              let findData  = this.childMenuData.find(e=> e.childMenuName == sessionStorage.getItem('%qrgch'));
              this.onClickChildManu(findData)
            }, (error: any) => {
              this.isLoader = false
            })
        this.isShowLandingPage = false;
          this.isShowPayrollList = false;
          this.isShowLMSFormInputList = false;
    }
    // if(this.authService.shareDataChild.observers.length > 1){
    //   this.authService.shareDataChild.observers.splice(1,1)
    // }
    this.getData();
        });
    
  this.service.reverseShareGrandChildFlag.subscribe(x=>{
    let flag = x;
    sessionStorage.setItem('chd',JSON.stringify(x));
      if(x==true){
      this.grandChild = true;
   }else{
    this.isShowLandingPage = false;
    this.isShowPayrollList = false;
    let empData = this.authService.deObject(sessionStorage.getItem('%qr'))
    if(sessionStorage.getItem('%qrch') == 'Employee Master' && empData.type != 'Employee'){
      this.isShowLandingPage = true
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Inputs'){
      this.isShowPayrollList = true;
    }
    if(sessionStorage.getItem('%qrch') == 'Employee Master'){
      this.isShowUserGuide = false;
      // this.isShowUserGuide = true;
      // this.type = 'Employee Master'
    }else if(sessionStorage.getItem('%qrch') == 'Remibursement'){
      this.isShowUserGuide = true;
      this.type = 'Remibursement'
    }else if(sessionStorage.getItem('%qrch') == 'CRM'){
      this.isShowUserGuide = true;
      this.type = 'CRM'
    }else if(sessionStorage.getItem('%qrch') == 'Query'){
      this.isShowUserGuide = true;
      this.type = 'Query'
    }else if(sessionStorage.getItem('%qrch') == 'Master'){
      this.isShowUserGuide = true;
      this.type = 'master'
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Applications'){
      this.isShowUserGuide = true;
      this.type = 'payrollApplications'
    }else if(sessionStorage.getItem('%qrch') == 'Setting'){
      this.isShowUserGuide = true;
      this.type = 'Setting'
    }else if(sessionStorage.getItem('%qrch') == 'Reports'){
      this.isShowUserGuide = true;
      this.type = 'Report'
    }else if(sessionStorage.getItem('%qrch') == 'Approval'){
      this.isShowUserGuide = true;
      this.type = 'Approval'
    }else if(sessionStorage.getItem('%qrch') == 'Expense Reimbursement'){
      this.isShowUserGuide = true;
      this.type = 'Expense Reimbursement'
    }else if(sessionStorage.getItem('%qrch') == 'General Claim'){
      this.isShowUserGuide = true;
      this.type = 'General Claim'
    }else if(sessionStorage.getItem('%qrch') == 'LMS'){
      this.isShowUserGuide = true;
      this.type = 'LMS'
    }else if(sessionStorage.getItem('%qrch') == 'Payroll Inputs'){
      this.isShowUserGuide = true;
      this.type = 'Payroll'
    }else if(sessionStorage.getItem('%qrch') == 'ESOP'){
      this.isShowUserGuide = true;
      this.type = 'ESOP'
    }
    else{
      this.isShowUserGuide = false;
    }
   }
  })
  this.getData()
  }
  async getData() {
    let abc =this.authService.deObject(sessionStorage.getItem('%qr'));
    let categoryValue = sessionStorage.getItem('%qrch');
    let data = {
      "groupCompanyID": abc.globalCompanyMasterId,
      "category": getCategoryValue(categoryValue),
      "tableName":  getCategoryValue(categoryValue),
      "tableId": 0
    }
    function getCategoryValue(category) {
      if (category === 'Payroll Inputs') {
          return 'Payroll';
      }else if(category === 'Payroll Applications'){
        return 'payrollApplications';
      }else if(category === 'Master'){
        return 'master';
      } else {
          // Add more conditions or default value if needed
          return category;
      }
    }
    await this.commonPopup.getHelpDocument(data).toPromise().then(success => {
      this.showHelpDoc = success.data.results[0].length > 0 ? true : false;
    }).catch(error => {
      this.showHelpDoc = false;
    })
  }
  ngOnDestroy(){
    // this.authService.shareDataChild.unsubscribe();
    if(this.authService.shareDataChild.observers.length > 1){
      this.authService.shareDataChild.observers.splice(0,1)
    }
  }

  getDataFromLocalFile(roleId,company){
    this.role =this.authService.deObject(sessionStorage.getItem('%qr'));
     this.authService.getleftMenuData(this.role.userRoleId,this.role.globalCompanyMasterId).subscribe(res=>{  
       this.leftMenudata = res.data.results;
       this.childMenuData = this.leftMenudata.filter(x=>x.menuName==sessionStorage.getItem('%qrch'))[0].childObj;      
     })
   }

  onClickChildManu(event){
 sessionStorage.setItem('%qrgch',event.childMenuName)
 sessionStorage.setItem('chd','true');
 this.service.shareChildSubject.next(event);
 this.grandChild = true;  
    this.grandchildData = this.childMenuData.find(x=>x==event).grandChildList;
    if(this.grandchildData.length>0){
        this.grandChildDataList = this.grandchildData       
          this.grandChild = true;      
    }else{
      let selectedChildMenu = this.childMenuData.find(x=>x==event)  
      this.router.navigate([selectedChildMenu.childRouterLink]);
      let empData = this.authService.deObject(sessionStorage.getItem('%qr'))
      if(sessionStorage.getItem('%qrch') == 'Employee Master'){
        sessionStorage.setItem('%chm','true');
        this.authService.showChildMenuHeader.next(true)
      }else{
        sessionStorage.setItem('%chm','false');
        this.authService.showChildMenuHeader.next(false)
      }
    }
  }

  onClickGrandChildManu(child){
    this.service.shareChildSubject.next(child);
      this.router.navigate([child.routerLinkGrandChild]);
  }

  infoPopup(template1: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template1,
      Object.assign({}, { class: 'gray modal-sm'})
    );
  }

  openPopup(document: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      document
      , Object.assign({}, { class: "gray modal-md" })
    )
    this.commonPopup.openPopup();
  }


}
