<div>
    <div class="section-body mt-3">
        <div class="container-fluid">
            <form [formGroup]="form">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-5 center myLogin">
                                <p-panel>
                                    <ng-template pTemplate="header">
                                        <h3 class="head">Change Password</h3>
                                    </ng-template>
                                    <div class="form-group">
                                        <label class="form-label">Enter New Password <i
                                                class="fa fa-info-circle ml-3 mt-3"
                                                (click)="pwpolicy(template)"></i></label>
                                        <p-password name="newPass" (keyup)="changePass($event.currentTarget.innerText)"
                                            formControlName="newPass" [toggleMask]="true" onCopy="return false"
                                            onpaste="return false"
                                            strongRegex="^((?![_]).)(?=.{5,15}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[@,#,$])((?![_]).)*$"
                                            weakLabel="Weak" goodLabel="Good" strongLabel="Strong" value="#{newPass}"
                                            [ngClass]="{'is-invalid' : form.get('newPass').status == 'INVALID'}"></p-password>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Confirm New Password</label>
                                        <p-password
                                            strongRegex="^((?![_]).)(?=.{5,15}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[@,#,$])((?![_]).)*$"
                                            #e (keyup)="changeConfirmPass($event.currentTarget.innerText)"
                                            (click)="changeClass($event)" onCopy="return false" onpaste="return false"
                                            formControlName="confirmPass" [toggleMask]="true"
                                            [ngClass]="{'is-invalid' : form.get('confirmPass').status == 'INVALID'} ">
                                        </p-password>
                                        <div *ngIf="error">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-7">
                                            <label class="form-label">Captcha</label>
                                            <input type="text" class="form-control" name="example-disabled-input"
                                                placeholder="Disabled.." formControlName="equation" readonly="">
                                        </div>
                                        <div class="col-md-1 mt-30">
                                            =
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-label">Answer</label>
                                            <input type="text" class="form-control" name="example-disabled-input"
                                                autocomplete="off" placeholder="Answer" formControlName="resultTotal">
                                        </div>
                                    </div>
                                    <ng-template pTemplate="footer" class="fot">
                                        <div class="row fot">
                                            <div class="col-md-12">
                                                <button type="button" tooltip="Save"
                                                    class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2"
                                                    tabindex="0" ng-reflect-tooltip="Button" (click)="save()"
                                                    [disabled]="!validate || !confirmPassVAlidate"
                                                    [ngClass]="{'disabled-btn-bg-green' : !validate || !confirmPassVAlidate , 'btn-bg-green': validate && confirmPassVAlidate }"><b
                                                        _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616=""
                                                            class="fa fa-floppy-o"></i></b> Save </button>
                                                <button _ngcontent-wxs-c616="" type="button" tooltip="Reset"
                                                    class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left mr-2 ng-star-inserted  "
                                                    tabindex="0" ng-reflect-tooltip="Button" (click)="reset()"
                                                    ng-reflect-router-link="/loan/rescheduleRequest"><b
                                                        _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616=""
                                                            class="fa fa-refresh "></i></b> Reset </button>
                                                <button _ngcontent-wxs-c616="" type="button" tooltip="Close"
                                                    class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted  "
                                                    tabindex="0" ng-reflect-tooltip="Button"
                                                    ng-reflect-router-link="/loan/rescheduleRequest"><b
                                                        _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616=""
                                                            class="fa fa-times "></i></b> Close </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-panel>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template #template>
                <div class="modal-header foot">
                    <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16 font700">Password
                            Policy</span></h5>
                    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body  tab">
                            <div class="tabview-contents">
                                <div class="row mt-15">
                                    <div class="col-md-12">
                                        <ol>
                                            <li> Password should have minimum length of {{policy.minLength}} characters
                                                & maximum length
                                                of
                                                {{policy.maxLength}} characters.</li>
                                            <li>Password should be alphanumeric having minimum one
                                                uppercase (A-Z), one lowercase (a-z), one numeral (0-9) and one special
                                                character
                                                ( {{policy.allowedSetOfSpecialChars}} ).</li>
                                            <li>Not to use string of {{policy.repeatCharsCount}} or more consecutive
                                                character in
                                                password (aaa).</li>
                                            <li>Not to use Firstname and Lastname in the password.</li>
                                            <li>Password shall expire on {{policy.expiryInDays}} day from date of
                                                password change.</li>
                                            <li>Three unsuccessful login attempts would lock the account,
                                                which can be unlocked by Admin only.</li>
                                            <li>In case you forget the password or your password has expired click on
                                                forgot password on the login page.
                                            </li>
                                            <li>Change password link sent through email shall become invalid after 7
                                                days.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer foot">
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Button" (click)="modalRef.hide()"
                        class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted  "
                        tabindex="0" ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        </b> OK
                    </button>

                </div>
            </ng-template>

            <ng-template #template3>
                <div class="modal-header foot">
                    <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16">Password
                            Expiry</span>
                    </h5>
                    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                        aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body">
                            <span class="span2">"Your password will expire on Sunday, 10th August 2021"</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer foot">
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                        ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-key"></i></b> Change Password
                    </button>
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                        class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted  "
                        tabindex="0" ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-times"></i></b> Close
                    </button>
                </div>

            </ng-template>

            <ng-template #template2>
                <div class="modal-header">
                    <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16">Session Time Out
                        </span>
                    </h5>
                    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                        aria-label="Close"><span aria-hidden="true" class="font-16 color-white">×</span></button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body">
                            <!-- Session Time out -->
                            <span class="span2"><i class="fa fa-history fa-2x text-red"></i> &nbsp; &nbsp;Your session
                                is going to expire after 1 minute. In order to continue, please click on
                                "Stay Logged In" or click on "Log Out" in case you are done !
                            </span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                        ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-sign-in "></i></b> STAY LOGGED
                        IN </button>
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                        ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-power-off"></i></b> Log Out
                    </button>
                    <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                        class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted mr-2 "
                        tabindex="0" ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                            _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-times"></i></b> Close
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
</div>