<div class="section-body mt-3">
    <div class="container-fluid">
        <form [formGroup]="form">
            <div class="card">
                <div class="card-body">
                    <div class="row mt-4">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <div class="card new-white-card">
                                <div class="text-center p-20 psw-bg">
                                    <div class="card-title text-center name-frgt-pass">Change Password</div>
                                </div>
                                <div class="card-body">
                                    <div class="input-group">
                                        <div class="col-md-12">
                                            <label class="form-label font-12">Enter New Password <i
                                                    class="fa fa-info-circle ml-3 mt-3"
                                                    (click)="pwpolicy(template)"></i></label>
                                        </div>
                                        <input type="password" class="form-control mr-2" name="password"
                                            formControlName="newPass" *ngIf="!isShowNewPassword"
                                            placeholder="Enter New Password"
                                            (keypress)="passwordValidation($event.target.value,$event)"
                                            (keyup)="passwordValidation($event.target.value,$event)"
                                            [ngClass]="{'is-invalid' : isPasswordValid == false}">
                                        <i class="fa fa-eye icon-css icon-css-new" (click)="onClickNewPassEye()"
                                            *ngIf="!isShowNewPassword"
                                            [ngClass]="isShowNewPassword == true ? 'fa fa-eye-slash':'fa fa-eye'"></i>

                                        <input type="test" class="form-control mr-2" name="password"
                                            formControlName="newPass" *ngIf="isShowNewPassword"
                                            placeholder="Enter New Password"
                                            (keypress)="passwordValidation($event.target.value,$event)"
                                            (keyup)="passwordValidation($event.target.value,$event)"
                                            [ngClass]="{'is-invalid' : isPasswordValid == false}">
                                        <i class="fa fa-eye icon-css icon-css-new" (click)="onClickNewPassEye()"
                                            *ngIf="isShowNewPassword"
                                            [ngClass]="isShowNewPassword == true ? 'fa fa-eye-slash':'fa fa-eye'"></i>
                                    </div>
                                    <div class="input-group" *ngIf="this.form.get('newPass').value?.length > 0">
                                        <div class="col-md-6">
                                            <label *ngIf="isPasswordValid == false"
                                                class="form-label font-12 text-red bg-gray">Weak Password</label>
                                            <label *ngIf="isPasswordValid == true"
                                                class="form-label font-12 text-green">Strong Password</label>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <div class="col-md-12 mt-4">
                                            <label class="form-label font-12">Confirm New Password</label>
                                        </div>
                                        <input type="password" class="form-control mr-2" name="password"
                                            formControlName="confirmPass" *ngIf="!isShowConfirmPassword"
                                            placeholder="Enter Confirm Password"
                                            (keypress)="confirmPasswordValidation($event.target.value,$event)"
                                            (keyup)="confirmPasswordValidation($event.target.value,$event)"
                                            [ngClass]="{'is-invalid' : this.form.get('confirmPass').value == null || this.form.get('confirmPass').value == '' || this.form.get('confirmPass').value != this.form.get('newPass').value }">
                                        <i class="fa fa-eye icon-css icon-css-new" (click)="onClickConfirmPassEye()"
                                            *ngIf="!isShowConfirmPassword"
                                            [ngClass]="isShowConfirmPassword == true ? 'fa fa-eye-slash':'fa fa-eye'"></i>

                                        <input type="test" class="form-control" name="password"
                                            formControlName="confirmPass" *ngIf="isShowConfirmPassword"
                                            placeholder="Enter Confirm Password"
                                            (keypress)="confirmPasswordValidation($event.target.value,$event)"
                                            (keyup)="confirmPasswordValidation($event.target.value,$event)"
                                            [ngClass]="{'is-invalid' : this.form.get('confirmPass').value == null || this.form.get('confirmPass').value == '' || this.form.get('confirmPass').value != this.form.get('newPass').value }">
                                        <i class="fa fa-eye icon-css icon-css-new" (click)="onClickConfirmPassEye()"
                                            *ngIf="isShowConfirmPassword"
                                            [ngClass]="isShowConfirmPassword == true ? 'fa fa-eye-slash':'fa fa-eye'"></i>
                                    </div>
                                    <div class="input-group" *ngIf="this.form.get('confirmPass').value?.length > 0">
                                        <div class="col-md-10">
                                            <label
                                                *ngIf="this.form.get('confirmPass').value != this.form.get('newPass').value"
                                                class="form-label font-12 text-red bg-gray">Enter
                                                correct confirm Password</label>
                                            <!-- <label *ngIf="isPasswordValid == true" class="form-label font-12 text-green" style="background-color:gray">Strong Password</label> -->
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <label class="form-label font-12">Captcha</label>
                                            <input type="text" class="form-control" name="example-disabled-input"
                                                placeholder="Disabled.." formControlName="equation" readonly="">
                                        </div>
                                        <div class="col-md-1 mt-30">
                                            =
                                        </div>
                                        <div class="col-md-5 font-12">
                                            <label class="form-label">Answer</label>
                                            <input type="text" class="form-control" name="example-disabled-input"
                                                autocomplete="off" placeholder="Answer" formControlName="resultTotal">
                                        </div>
                                    </div>
                                </div>
                                <div class="p-2 topborder">
                                    <div class="col-md-12">
                                        <div class="text-center">
                                            <button type="button" tooltip="Save"
                                                class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2"
                                                tabindex="0" tooltip="Button" (click)="save()"
                                                [disabled]="isPasswordValid == false || this.form.get('resultTotal').value == null || this.form.get('resultTotal').value == '' || this.form.get('confirmPass').value != this.form.get('newPass').value"
                                                [ngClass]="{'disabled-btn-bg-green' : isPasswordValid == false || this.form.get('resultTotal').value == null || this.form.get('resultTotal').value == '' || this.form.get('confirmPass').value != this.form.get('newPass').value , 'btn-bg-green': isPasswordValid == true && this.form.get('resultTotal').value != null && this.form.get('resultTotal').value != '' && this.form.get('confirmPass').value == this.form.get('newPass').value }"><b><i
                                                        class="fa fa-floppy-o"></i></b> Save </button>
                                            <button type="button" tooltip="Reset"
                                                class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left mr-2 ng-star-inserted  "
                                                tabindex="0" tooltip="Button" (click)="reset()"
                                                router-link="/loan/rescheduleRequest"><b><i
                                                        class="fa fa-refresh "></i></b>
                                                Reset </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                </div>
            </div>
        </form>
        <ng-template #template>
            <div class="modal-header foot">
                <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16 font700">Password
                        Policy</span></h5>
                <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                    aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body  tab">
                        <div class="tabview-contents">

                            <div class="row mt-15">
                                <div class="col-md-12">
                                    <ol>
                                        <li> Password should have minimum length of {{policy.minLength}} characters
                                            & maximum length
                                            of
                                            {{policy.maxLength}} characters.</li>
                                        <li>Password should be alphanumeric having minimum
                                            {{policy.noOfalphUpperCaseReq}}
                                            uppercase (A-Z), {{policy.noOfalphalowerCaseReq}} lowercase (a-z),
                                            {{policy.noOfNumericReq}} numeral (0-9)
                                            and {{policy.specialCharactersRequiredCount}} special character
                                            ( {{policy.allowedSetOfSpecialChars}} ).</li>
                                        <li>Not to use string of {{policy.repeatCharsCount}} or more consecutive
                                            character in
                                            password (aaa).</li>
                                        <!-- <li>Not to use Firstname and Lastname in the password.</li> -->
                                        <li>Not to use <span *ngIf="policy.isFirstNameAllowed == true">Firstname</span>
                                            <span
                                                *ngIf="policy.isFirstNameAllowed == true && policy.isLastNameAllowed == true">
                                                and </span> <span
                                                *ngIf="policy.isLastNameAllowed == true">Lastname</span> in the
                                            password.
                                        </li>
                                        <li>Password shall expire on {{policy.expiryInDays}} day from date of
                                            password change.</li>
                                        <li>Three unsuccessful login attempts would lock the account,
                                            which can be unlocked by Admin only.</li>
                                        <li>In case you forget the password or your password has expired click on
                                            forgot password on the login page.
                                        </li>
                                        <li>Change password link sent through email shall become invalid after 7
                                            days.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer foot">
                <button _ngcontent-wxs-c616="" type="button" tooltip="Button" (click)="modalRef.hide()"
                    class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted  "
                    tabindex="0" ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                        _ngcontent-wxs-c616=""><i class="fa fa-thumbs-up" aria-hidden="true"></i>
                    </b> OK
                </button>

            </div>
        </ng-template>

        <ng-template #template3>
            <div class="modal-header foot">
                <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16">Password
                        Expiry</span>
                </h5>
                <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                    aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <span class="span2">"Your password will expire on Sunday, 10th August 2021"</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer foot">
                <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                    class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                    ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                        _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-key"></i></b> Change Password
                </button>
                <!-- <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Ok</button> -->
                <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                    class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted  "
                    tabindex="0" ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                        _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-times"></i></b> Close
                </button>
            </div>

        </ng-template>
        <ng-template #template2>
            <div class="modal-header">
                <h5 class="modal-title" id="UploadModalLabel"><span class="text-purple font-16">Session Time Out
                    </span>
                </h5>
                <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal"
                    aria-label="Close"><span aria-hidden="true" class="font-16 color-white">×</span></button>
            </div>
            <div class="modal-body">
                <div class="card">
                    <div class="card-body">
                        <!-- Session Time out -->

                        <span class="span2"><i class="fa fa-history fa-2x text-red"></i> &nbsp; &nbsp;Your session
                            is going to expire after 1 minute. In order to continue, please click on
                            "Stay Logged In" or click on "Log Out" in case you are done !

                        </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                    class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                    ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                        _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-sign-in "></i></b> STAY LOGGED
                    IN </button>

                <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                    class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2" tabindex="0"
                    ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                        _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-power-off"></i></b> Log Out
                </button>
                <button _ngcontent-wxs-c616="" type="button" tooltip="Button"
                    class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left  ng-star-inserted mr-2 "
                    tabindex="0" ng-reflect-tooltip="Button" ng-reflect-router-link="/loan/rescheduleRequest"><b
                        _ngcontent-wxs-c616=""><i _ngcontent-wxs-c616="" class="fa fa-times"></i></b> Close
                </button>
            </div>
        </ng-template>
    </div>
</div>