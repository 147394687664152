import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeftMenuServiceService } from '../../sharedlayout/left-menu-service.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-group-or-company-selection',
  templateUrl: './group-or-company-selection.component.html',
  styleUrls: ['./group-or-company-selection.component.scss']
})
export class GroupOrCompanySelectionComponent implements OnInit,OnDestroy {
  groupCompanyData: any;
  distinctCountries: any;
  distinctGroups: any;
  distinctCompanies: any;
  distinctRoles: Array<any>=[];
  companyName: any;
  role: any;
  group: any;
  country:any='';
  groupSelected:any='';
  selectedCountry: any;
  constructor( private service: AuthService,private router: Router,private leftMenuService:LeftMenuServiceService) { }
  ngOnInit(): void {
    this.service.showHideHome.next(false)
    this.role =JSON.parse(this.service.deValue(sessionStorage.getItem('%rid')));
    setTimeout(() => {
    this.service.checkUser(this.role).subscribe(res=>{
    
      this.groupCompanyData = res.data.results; 
      sessionStorage.setItem('%rd', JSON.stringify(this.groupCompanyData))
      this.service.groupCompanyData.next(this.groupCompanyData)
              this.distinctCountries = this.groupCompanyData.map(item => item.country).filter((value, index, self) => self.indexOf(value) === index)
              if(sessionStorage.getItem('selectedCountry') != '' && sessionStorage.getItem('selectedGroup') != ''){
                this.selectedCountry = this.service.deValue(sessionStorage.getItem('selectedCountry'))
                this.group = this.service.deValue(sessionStorage.getItem('selectedGroup'))
                this.onChangeCountry(this.selectedCountry)
                this.onChangeGroup(this.group)
              }
            },(err)=>{
              err.error.status.message
            })
     }, 1000);
  }

  ngOnDestroy(): void {
    this.service.showHideHome.next(true)
  }
  
  onChangeCountry(country){
    this.selectedCountry = country
    this.distinctGroups = this.groupCompanyData.filter(ele=>ele.country==country).map(item => item.companyGroupName).filter((value, index, self) => self.indexOf(value) === index)
    sessionStorage.setItem('selectedCountry',this.service.enValue(country))
  }
  onChangeGroup(group){
    this.group = group;
    this.distinctCompanies= this.groupCompanyData.filter(ele=>ele.companyGroupName==group).map(item => item.companyName).filter((value, index, self) => self.indexOf(value) === index)
    let company = this.groupCompanyData.filter(ele=>ele.companyGroupName==group).map(item => item.globalCompanyMasterId).toString();
    sessionStorage.setItem('globalCompanyList',this.service.enValue(company));
    this.service.shareGroups.next(this.distinctGroups);
    this.service.shareGroupName.next(this.group);
    this.service.shareCompanies.next(this.distinctCompanies);
    this.service.shareCompName.next(null)
    sessionStorage.setItem('selectedGroup',this.service.enValue(group))
    if(this.distinctCompanies.length == 1){
      this.companySelection(this.distinctCompanies[0])
      this.redictionTo()
    }else{
      this.leftMenuService.shareBreadcrumb.next([])
    }
  }
  companySelection(companyName){
    if(companyName != "" && companyName != null){
    this.companyName=companyName;
    this.service.shareCompName.next(companyName)
    sessionStorage.setItem('selectedCompany',this.service.enValue(companyName))
    let companyData =this.groupCompanyData.find(x=>x.companyName==this.companyName)
    sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(companyData.globalCompanyMasterId)));
    this.service.shareCompanies.next(this.distinctCompanies);
    }else{
      sessionStorage.setItem('selectedCompany','')
      this.onChangeGroup(this.group)
    }
  }

  redictionTo(){
   sessionStorage.setItem('bread',JSON.stringify([]));
   let breadData = [{"parentMenuName":"dashboard","path":"Dashboard"}]
   this.leftMenuService.shareBreadcrumb.next(breadData)
   sessionStorage.setItem('%qr',sessionStorage.getItem('%qrCopy'));
   if(this.companyName==null){
    let dataCompany = this.groupCompanyData.find(a=>a.companyGroupName== this.group)
    this.role =this.service.deObject(sessionStorage.getItem('%qr'));
    this.role.groupName=dataCompany.groupName;
    this.role.companyGroupMasterId=dataCompany.companyGroupMasterId
    // this.role.globalCompanyMasterId1=''
    this.role.globalCompanyMasterId=dataCompany.globalCompanyMasterId;
    this.role.globalCompanyMasterId1=dataCompany.globalCompanyMasterId;
    // sessionStorage.setItem('%qr',JSON.stringify(this.role))
    sessionStorage.setItem('%qr',this.service.enObject(this.role))
    this.service.shareData.next(this.role);
      this.router.navigate(['/dashboard']);
      sessionStorage.setItem('in','true');
      this.service.allowedPathVariables(this.role)
      localStorage.setItem('employeeMasterId',null);
   }else{
    let dataCompany = this.groupCompanyData.find(a=>a.companyName==this.companyName);
  
    this.role =this.service.deObject(sessionStorage.getItem('%qr'));
    this.role.groupName=dataCompany.groupName;
    this.role.companyGroupMasterId=dataCompany.companyGroupMasterId
    this.role.globalCompanyMasterId=dataCompany.globalCompanyMasterId;
    this.role.globalCompanyMasterId1=dataCompany.globalCompanyMasterId;
   // this.role = JSON.stringify(this.role);
    // sessionStorage.setItem('%qr',JSON.stringify(this.role))
      sessionStorage.setItem('%qr',this.service.enObject(this.role))
      this.service.shareData.next();
      this.service.allowedPathVariables(this.role)
        this.router.navigate(['/employee-master/employee-dashboard']);
      sessionStorage.setItem('in','true');
      localStorage.setItem('employeeMasterId',null);
   } 
  }
}
