import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SingleReportSharedService {

  constructor(private httpClient:HttpClient) { }

  getCompanyName(groupName, roleName) :Observable<any>{
    let param = new FormData;
    param.append('groupName', groupName);
    param.append('roleName', roleName)
    return this.httpClient.post<any>(`${environment.baseUrl8080}companyMaster/getGroupNames`, param);
  }

  getMemberShipData(complianceMasterId):Observable<any>{
    return this.httpClient.get<any>(`${environment.baseUrl8083}compliance-master/getEmpComplianceInfo/`+ complianceMasterId)
  }

  getBEPayrollFormReports(data):Observable<any> {

    return this.httpClient
      .post(`${environment.baseUrl8094}formsregister`, data, { responseType: 'arraybuffer' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error)
          return throwError(error); // Re-throw the error
        })
      );
  }

  getDate():Observable<any> {
    return this.httpClient.get<any>(`${environment.baseUrl8084}JvColumnDefinition/getDateAndMoths/Date`);
  }

  getBydateAndMonthFormatId(dateAndMonthFormatId):Observable<any> {
    return this.httpClient.get<any>(`${environment.baseUrl8084}JvColumnDefinition/getBydateAndMonthFormatId/${dateAndMonthFormatId}`);
  }

  // RoleRrivilegeService
  getEmployeeRoasterDetails(data):Observable<any>{
    return this.httpClient.post(`${environment.baseUrl8093}employeeRoasterDetails/dates`, data);
  }

  employeeRoleAssignmentUser(subId):Observable<any>{
    return this.httpClient.get<any>(`${environment.baseUrl8080}employeeRoleAssignment/user/${subId}`);
  }

  getApplicationMenusData():Observable<any>{
    return this.httpClient.get<any>(`${environment.baseUrl8080}applicationMenus/getApplicationMenus`) ;

  }

  getReimbursementHeadType(headGroupDefinationId):Observable<any> {
    return this.httpClient.get(`${environment.baseUrl8089}reimbursement-general-setting/phg/${headGroupDefinationId}`);

  }
  getReimbursementPayrollAssign(payrollId):Observable<any>{
    return this.httpClient.get(`${environment.baseUrl8082}payroll-information/payrollAssigned/${payrollId}`);

  }
  getReimbursementPayrollArea(payrollAreaId):Observable<any>{
    return this.httpClient.get(`${environment.baseUrl8084}payrollArea-details/${payrollAreaId}`);
  }
  
}
