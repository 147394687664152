<!-- Overlay For Sidebars


*ngIf="isAuthorized()"
 -->
<!-- <div class="overlay" (click)="closeMenu()"></div> -->

<!-- <div *ngIf="isloggedIn()" class="page">
<app-header></app-header>

</div>

<div *ngIf="isloggedIn()" class="page">
<app-leftmenu ></app-leftmenu>
</div >


<router-outlet></router-outlet>
<div class="page" >
    
<app-footer *ngIf="isloggedIn()"></app-footer>
</div> -->



<span appCheckUserTime>
<app-leftmenu *ngIf="isloggedIn()"></app-leftmenu>
<div class="page">
    <app-header *ngIf="isloggedIn()" [delziahrLogoShow]="delziahrLogoShow"></app-header>
    
    <app-breadcrumb *ngIf="isloggedIn()"></app-breadcrumb>
    <app-show-child-menu *ngIf="isShowChildMenuHeader && isloggedIn()"></app-show-child-menu>

    <!-- <ol class="breadcrumb" *ngIf="isloggedIn()">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-9">
                    <li><a> Dashboard</a></li>
                    <li><a> Investment </a></li>
                    <li><a> Section List</a></li>
                    <li><a> 80-C Summary</a></li>
                    <li><a> Summary</a></li>
                    <li><a>Master</a></li>
                     <li>{{rout}}</li>
                    <li class="active"><a href="/InvestmentGroup"
                            class="text-white"> Declaration &amp; Actual </a></li>
                </div>
                <div class="col-md-3 mt-1">
                    <div class="float-right">
                        <h1  class="page-title"
                            style="color: #000 !important; font-size: 12px !important; font-weight: 600;">Last Seen :
                            09-Sep-2022&nbsp;| 09:43:00AM
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </ol> -->
    
    <div [ngClass]="{'card-fullscreen': isFull}">
        <div class="section-height ">
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="section-body ">
        <app-footer *ngIf="isloggedIn()"></app-footer>
    </div>
</div>
</span>
