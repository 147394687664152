import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/modules/auth/auth.service';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit, OnDestroy {
  blobUrl: string;
  selectDocIndex: number = 0;
  documetLst: any;
  extraDetails: any;
  blobURI: SafeResourceUrl = '';
  password: string;
  remark: string;

  constructor(private sanitizer: DomSanitizer, private authService: AuthService) {
    debugger;
    this.documetLst = JSON.parse(this.authService.deValue(localStorage.getItem('documentDataView')));
    this.extraDetails = JSON.parse(this.authService.deValue(localStorage.getItem('infoDetails')));
  }

  ngOnInit(): void {
    this.showfirstDoc()
  }

  ngOnDestroy(): void {
    localStorage.removeItem('documentDataView');
  }

  showfirstDoc() {
    const url: string = this.documetLst[this.selectDocIndex].blobURI;
    this.password = this.documetLst[this.selectDocIndex].documentPassword;
    this.remark = this.documetLst[this.selectDocIndex].documentRemark;
    this.blobURI = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  prev() {
    this.selectDocIndex--;
    const url: string = this.documetLst[this.selectDocIndex].blobURI;
    this.password = this.documetLst[this.selectDocIndex].documentPassword;
    this.remark = this.documetLst[this.selectDocIndex].documentRemark;
    this.blobURI = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  next() {
    this.selectDocIndex++;
    const url: string = this.documetLst[this.selectDocIndex].blobURI;
    this.password = this.documetLst[this.selectDocIndex].documentPassword;
    this.remark = this.documetLst[this.selectDocIndex].documentRemark;
    this.blobURI = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  download() {

  }

}

