import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-changepassword',
  templateUrl: './forgot-changepassword.component.html',
  styleUrls: ['./forgot-changepassword.component.scss']
})
export class ForgotChangepasswordComponent implements OnInit {
  public modalRef: BsModalRef;
  captcha: any;
  policy: any;
  form: FormGroup;
  result: any;
  userData: any;
  newPass: any;
  confirmPass: any;
  captchaAnswer: any;
  regex: any;
  equation: any;
  resultTotal:any='';
  validate: any;
  confirmPassVAlidate: boolean;
  reusltValidate: boolean;
  modalService: any;
  error: boolean;
  constructor( private service: AuthService,
    private formBuilder:FormBuilder,
    private alertService: AlertServiceService,
    private router: Router) { 
      this.form =this.formBuilder.group({
        newPass: new FormControl('',Validators.required),
        confirmPass: new FormControl('',Validators.required),
        captchaAnswer:new FormControl(''),
        equation:new FormControl(''),
        resultTotal:new FormControl('',Validators.required)
       });
    }

  ngOnInit(): void {
    this.getNewCaptch();
    this.getPasswordPolicy();
    
  }

  pwpolicy(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
    this.getPasswordPolicy()
  }

  //To get New Captcha 
  getNewCaptch(): any {
    this.service.getCaptchaWithoutT().subscribe(res => {
      this.captcha = res.data.results[0];
      this.form.get('equation').setValue(this.captcha.equation);
      this.result = this.captcha.result
      })
  }

  getPasswordPolicy(): any {
    let data = localStorage.getItem('mobileOrMail')
    this.service.getPassPolicyWithout(data).subscribe(res => {
      this.policy = res.data.results[0];
      this.regex= this.policy.regex;
    })
  }

  changePass(evt){
   if(evt =="Strong"){
     this.validate=true;
    }else{
    this.confirmPassVAlidate=false;
  }
  }
  changeConfirmPass(evt){
    if(evt =="Strong" && (this.form.get('newPass').value == this.form.get('confirmPass').value)){
      this.confirmPassVAlidate=true;
    }else{
      this.confirmPassVAlidate=false;
    }
  }
 
  save() {
    if(this.form.get('confirmPass').value!=this.form.get('newPass').value){
      this.error=true
      return
    }else{
      this.error=false
    }

    if(this.form.get('resultTotal').value!=this.captcha.result){
      this.form.get('equation').reset();
      this.form.get('resultTotal').reset();
      this.alertService.sweetalertError('Please Enter Correct Answer')
      this.getNewCaptch();
      return
    }else{
    console.log(this.form.getRawValue());
    let data = {
      newPassword: this.form.get('confirmPass').value,
      userId: this.userData.sub,
      capcha:this.captcha.equation,
      solution:this.result
    }
    this.service.changePassword(data).subscribe(res => {
      console.log('changePassword Result', res);
      this.alertService.sweetalertMasterSuccess('Password Updated Successfully','')
    this.router.navigate(['/login'])
    })
   }
  }
  
  reset(){
    this.form.reset();
  }

  changeClass(evt){
  if(evt.target.className == 'ng-tns-c422-2 ng-star-inserted pi pi-eye-slash'){
  }
  }

}
